import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const Logs = MatxLoadable({
    loader: () =>
        import ("./AuditLog")
})

const EmailLog = MatxLoadable({
    loader: () =>
        import ("./EmailLog")
})
const LoggingLog = MatxLoadable({
    loader: () =>
        import ("./LoggingLog")
})


const Routes = [{
        path: "/logs",
        component: Logs,
        auth: authRoles.Logs
    },

    {
        path: "/email_logs",
        component: EmailLog,
        auth: authRoles.Logs
    },
    {
        path: "/login_logs",
        component: LoggingLog,
        auth: authRoles.Logs
    },

];

export default Routes;