import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const ViewMemberApproval = MatxLoadable({
    loader: () => import("./ViewMemberApproval")
  })

  const MemberApprovalSingleView = MatxLoadable({
    loader: () => import("./MemberApprovalSingleView")
  })
  
const MemberApprovalRoutes = [
  {
    path: "/viewmemberapproval",
    component: ViewMemberApproval,
     auth: authRoles.OGA_Membership,
  },
  {
    path: "/viewmembersingleview/:id",
    component: MemberApprovalSingleView,
    auth: authRoles.OGA_Membership,
  }
];

export default MemberApprovalRoutes;
