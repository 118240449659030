import React, { Component } from "react";
import {
    Card,
    Checkbox,
    FormControlLabel,
    Grid,
    Button,
    withStyles,
    CircularProgress,
    InputAdornment,
    Snackbar
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import EyeOn from '@material-ui/icons/Visibility';
import EyeOff from '@material-ui/icons/VisibilityOff';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { loginWithEmailAndPassword } from "../../redux/actions/LoginActions";
import moment from "moment";
const styles = theme => ({
    wrapper: {
        position: "relative"
    },

    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
});

class loginMain extends Component {
    state = {
        email: "",
        password: "",
        agreement: "",
        pasword_field_type: "password"
    };
    handleChange = event => {
        event.persist();
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    handleFormSubmit = event => {
        this.props.loginWithEmailAndPassword({ ...this.state });
    };

    handle_passwod_view() {
        if (this.state.pasword_field_type == "password") {
            this.setState({
                pasword_field_type: "text"
            })
        } else {
            this.setState({
                pasword_field_type: "password"
            })
        }
    }



    render() {
        let { email, password } = this.state;
        let { classes } = this.props;
        return (
            <div className="signup flex flex-center  w-100 h-100vh" style={{ backgroundColor: "#0E2342" }} >
                <div

                    style={{
                        marginTop: 100,
                        marginBottom: 100,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundImage: 'url("/assets/images/loginBackground.jpg")',
                        width: "100%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                >
                    <div style={{ height: "auto", backgroundColor: "white", borderRadius: 40, opacity: 0.9, width: 350 }}>
                        {/* <Alert style={{ display: this.props.login.success ? "none" : "flex" }}
                            className="mb-24" severity="error">
                            {this.props.login.errortitle}</Alert> */}

                        <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                            <Grid container
                                className="p-30"
                                style={{ height: "auto", backgroundColor: "#FAFAFA", borderRadius: 40, opacity: 1 }}
                                direction="column"
                                alignItems="center"
                                justify="center">
                                <Grid item sm={12} xs={12}>
                                    <img style={{ maxWidth: 100, marginTop: -100, borderRadius: 50 }} src="/assets/images/sigininlogo.png"></img>
                                </Grid>
                                <Grid item sm={12} xs={12} md={12} mt={10} style={{ color: "black", fontSize: 22, marginTop: 25 }}>
                                    <div>
                                        <div className="flex flex-center">
                                            {"Visakha Vidyalaya"}
                                        </div>
                                        <div className="flex flex-center">
                                            {" Old Girls' Association"}
                                        </div>
                                        <div className="flex flex-center">
                                            {"Membership Portal"}
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item sm={12} xs={12} md={12} style={{ marginTop: 15 }}>
                                    <TextValidator
                                        style={{ width: 280 }}
                                        size="small"
                                        className="mb-24 w-100"
                                        variant="outlined"
                                        label="User Name"
                                        onChange={this.handleChange}
                                        type="text"
                                        name="email"
                                        value={email}
                                        validators={["required"]}
                                        errorMessages={[
                                            "This field is required",
                                            "Enter a valid email address "
                                        ]}
                                    />
                                </Grid>
                                <Grid item sm={12} xs={12} md={12} >
                                    <TextValidator
                                        style={{ width: 280 }}
                                        size="small"
                                        className="w-100"
                                        label="Password"
                                        variant="outlined"
                                        onChange={this.handleChange}
                                        name="password"
                                        type={this.state.pasword_field_type}
                                        value={password}
                                        validators={["required"]}
                                        errorMessages={["This field is required"]}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        size="small"
                                                        disableRipple={true}
                                                        type="button"
                                                        onClick={() => this.handle_passwod_view()}
                                                    >
                                                        {this.state.pasword_field_type == "password"
                                                            ? <EyeOff />
                                                            : <EyeOn />
                                                        }

                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item sm={12} xs={12} md={12} style={{ marginLeft: 5, marginRight: 5, marginTop: 25 }} >
                                    <Button
                                        className="mb-10"
                                        variant="contained"
                                        style={{ width: 280 }}
                                        color="primary"
                                        disabled={this.props.login.loading}
                                        type="submit"
                                    >Sign In  </Button>
                                    {this.props.login.loading && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Grid>
                                <Grid justify="flex-end" item sm={12} xs={12} md={12} style={{ marginLeft: 5, marginRight: 5 }} >
                                    <Button
                                        className="text-primary"

                                        style={{ float: "right" }}
                                        onClick={() =>
                                            this.props.history.push("/session/forgot-password")
                                        }
                                    >Forgot password ?</Button>

                                    <Button
                                        variant="contained"
                                        startIcon={<ArrowBack />}
                                        onClick={() => this.props.history.push("/session/signin")

                                        } >Go back</Button>
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                    </div>
                </div>
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={!this.props.login.success}
                    autoHideDuration={2500}
                >
                    <Alert severity="error" variant="filled">
                        {this.props.login.errortitle}
                    </Alert>
                </Snackbar>
                <div style={{ position: 'fixed', bottom: 10, color: "white", right: '30px' }}>
                    <p style={{ textAlign: 'center' }}>© {moment().format("YYYY")} Visakha Vidyalaya Old Girls' Association. All rights reserved. Developed by Loons Lab.</p>
                </div>
            </div >
        );
    }
}

const mapStateToProps = state => ({
    loginWithEmailAndPassword: PropTypes.func.isRequired,
    login: state.login
});
export default withStyles(styles, { withTheme: true })(
    withRouter(
        connect(
            mapStateToProps,
            { loginWithEmailAndPassword }
        )(loginMain)
    )
);
