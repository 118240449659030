// import React, { Component, Fragment } from "react";
// import { withRouter } from "react-router-dom";
// import { connect } from "react-redux";
// import AppContext from "app/appContext";
// import jwtAuthService from "app/services/jwtAuthService";

// class AuthGuard extends Component {
//   constructor(props, context) {
//     super(props);
//     let { routes } = context;

//     this.state = {
//       authenticated: false,
//       routes
//     };
//   }

//   componentDidMount() {
//     if (!this.state.authenticated) {
//       this.redirectRoute(this.props);
//     }
//   }

//   componentDidUpdate() {

//     if (!this.state.authenticated) {
//       this.redirectRoute(this.props);
//     }
//   }

//   shouldComponentUpdate(nextProps, nextState) {
//     return nextState.authenticated !== this.state.authenticated;
//   }

//   static getDerivedStateFromProps(props, state) {
//     const { location, user } = props;
//     const { pathname } = location;
//     const matched = state.routes.find(r => r.path === pathname);

//     let authenticated = false;
//     if(matched!==undefined){
//     if (matched.auth !== undefined) {
//       if (user.roles !== undefined) {
//         const userpermission = user.roles.map(roles => roles).flat();
      
//         matched.auth.forEach(permission => {
//           if (userpermission.find(p => p === permission)) {
//             authenticated = true;
//           }
//         })

//       }
//     } else {
//       authenticated = false;
//     }
//   }
//     if (!(matched && matched.auth && matched.auth.length)) {
//       authenticated = true;
//     }

//     return {
//       authenticated
//     };
//   }

//   redirectRoute(props) {
//     const { location, history } = props;
//     const { pathname } = location;

//     history.push({
//       pathname: "/session/404",
//       state: { redirectUrl: pathname }
//     });
//   }

//   render() {
//     let { children } = this.props;
//     const { authenticated } = this.state;
//     return authenticated ? <Fragment>{children}</Fragment> : null;
//   }
// }

// AuthGuard.contextType = AppContext;

// const mapStateToProps = state => ({
//   user: state.user
// });

// export default withRouter(connect(mapStateToProps)(AuthGuard));


import React, { Fragment, useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppContext from "app/appContext";
import jwtAuthService from "app/services/jwtAuthService";

const redirectRoute = props => {
  const { location, history } = props;
  const { pathname } = location;

  history.push({
    pathname: "/",
    state: { redirectUrl: pathname }
  });
};



const getAuthStatus = (props, routes) => {
  const { location, user } = props;
  const { pathname } = location;
  const matched = routes.find(r => r.path === pathname);

  let authenticated = false;


  if (matched !== undefined) {
    if (matched.auth !== undefined) {
      if (user.roles !== undefined) {
        const userpermission = user.roles.map(roles => roles).flat();

        matched.auth.forEach(permission => {
          if (userpermission.find(p => p === permission)) {
            authenticated = true;
          }
        })

      }
    } else {
      authenticated = false;
    }
  }
  if (!(matched && matched.auth && matched.auth.length)) {
    authenticated = true;
  }


  return authenticated;
};

const AuthGuard = ({ children, ...props }) => {
  const { routes } = useContext(AppContext);

  let [authenticated, setAuthenticated] = useState(
    getAuthStatus(props, routes)
  );

  useEffect(() => {
    jwtAuthService.checkTokenValidation();
    if (!authenticated) {
      redirectRoute(props);
    }
    setAuthenticated(getAuthStatus(props, routes));
  }, [setAuthenticated, authenticated, routes, props]);

  return authenticated ? <Fragment>{children}</Fragment> : null;
};

const mapStateToProps = state => ({
  user: state.user
});

export default withRouter(connect(mapStateToProps)(AuthGuard));