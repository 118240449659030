import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const ViewExcoMasterData = MatxLoadable({
  loader: () => import("./ViewExcoMasterData")
})
const AddExcoMembers = MatxLoadable({
  loader: () => import("./AddExcoMembers")
})
const ExcoReportMain = MatxLoadable({
  loader: () => import("./ExcoReportMain")
})



const ExcoRoutes = [
  {
    path: "/exco-masterdata",
    component: ViewExcoMasterData,
    auth: authRoles.Ex_Co_Members,
  },
  {
    path: "/exco-member",
    component: AddExcoMembers,
    auth: authRoles.Ex_Co_Members,
  },
  {
    path: "/exco-report",
    component: ExcoReportMain,
    auth: authRoles.Ex_Co_Members,
  },
 
 
];

export default ExcoRoutes;
