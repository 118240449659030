import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const Member = MatxLoadable({
    loader: () =>
        import ("./Member")
})
const AllMemberUpdate = MatxLoadable({
    loader: () =>
        import ("./AllMemberUpdate")
})

const AllInformations = MatxLoadable({
    loader: () =>
        import ("./AllInformations")
})
const MemberSingleView = MatxLoadable({
    loader: () =>
        import ("./MemberSingleView")
})



const settings = {
    activeLayout: "layout1",
    layout1Settings: {
        topbar: {
            show: false
        },
        leftSidebar: {
            show: false,
            mode: "close"
        }
    },
    layout2Settings: {
        mode: "full",
        topbar: {
            show: false
        },
        navbar: { show: false }
    },
    secondarySidebar: { show: false },
    footer: { show: false }
};

const MemberRoutes = [
    {
        path: "/external-member-registration",
        component: Member,
        settings
    },
    {
        path: "/member-update",
        component: AllMemberUpdate,
        settings
    },
    {
        path: "/member_information",
        component: AllInformations,
        auth: authRoles.OGA_Membership,
    },
    {
        path: "/viewmember/:id",
        component: MemberSingleView,
        auth: authRoles.OGA_Membership,
    },

];

export default MemberRoutes;