import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const PasswordReset = MatxLoadable({
  loader: () => import("./PasswordReset")
})


const settings = {
  activeLayout: "layout1",
  layout1Settings: {
      topbar: {
          show: false
      },
      leftSidebar: {
          show: false,
          mode: "close"
      }
  },
  layout2Settings: {
      mode: "full",
      topbar: {
          show: false
      },
      navbar: { show: false }
  },
  secondarySidebar: { show: false },
  footer: { show: false }
};


const PasswordResetRoute = [
  {
    path: "/reset_password",
    component: PasswordReset,
    settings
  },
];

export default PasswordResetRoute;
