import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const ViewProposerSeconder = MatxLoadable({
    loader: () => import("./ViewProposerSeconder")
  })

  const MemberApprovalSingleView = MatxLoadable({
    loader: () => import("./MemberApprovalSingleView")
  })

const ProposerSeconderApprovalRoutes = [
  {
    path: "/viewproposerseconder",
    component: ViewProposerSeconder,
    auth: authRoles.all,
  },
  {
    path: "/viewproposemembersingleview/:id/:proposerid",
    component: MemberApprovalSingleView,
    auth: authRoles.all,
  }
];

export default ProposerSeconderApprovalRoutes;
