import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";


const AllView = MatxLoadable({
    loader: () =>
        import ("./All")
})


const MasterSettingsRoutes = [{
    path: "/membershipcard",
    component: AllView,
    auth: authRoles.OGA_Membership,
}];

export default MasterSettingsRoutes;