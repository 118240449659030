import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/styles";
import AllMember from "./AllMember";
import Box from '@material-ui/core/Box';
import moment from "moment";

class Member extends Component {
  state = {
   
  };

  render() {
    let { theme } = this.props;

    return (
      <Fragment>
        
        <Box id="mainDiv" className="pb-10 pt-30" px={{xs:1, sm:2, md:20, lg:20, xl:20}} style={{minHeight:786}}>
         <AllMember/>
        </Box>
        {/* <div id="mainDiv" className="pb-86 px-30 ">
         
        </div> */}
         <div style={{ position: 'absolute', bottom: 10, color: "white", right: '30px' }}>
                    <p style={{ textAlign: 'center' }}>© {moment().format("YYYY")} Visakha Vidyalaya Old Girls' Association. All rights reserved. Developed by Loons Lab.</p>
                </div>
      </Fragment>
    );
  }
}

export default withStyles({}, { withTheme: true })(Member);
