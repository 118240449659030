import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/styles";
import AllPayment from "./AllPayment";
import Box from '@material-ui/core/Box';
import moment from "moment";

class Payment extends Component {
  state = {

  };

  render() {
    let { theme } = this.props;

    return (
      <Fragment>
        <Box id="mainDiv" className="pb-10 pt-30" p={{ xs: 1, sm: 2, md: 5, lg: 10, xl: 20 }} style={{ minHeight: "100vh" }}>
          <AllPayment />
        </Box>
        {/* <div id="mainDiv" className="pb-86 px-30 ">
         
        </div> */}
         <div style={{ position: 'absolute', bottom: 10, color: "white", right: '30px' }}>
                    <p style={{ textAlign: 'center' }}>© {moment().format("YYYY")} Visakha Vidyalaya Old Girls' Association. All rights reserved. Developed by Loons Lab.</p>
                </div>
      </Fragment>
    );
  }
}

export default withStyles({}, { withTheme: true })(Payment);
