import React, { Component } from "react";
import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Button,
  withStyles,
  CircularProgress,
  InputAdornment
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import EyeOn from '@material-ui/icons/Visibility';
import EyeOff from '@material-ui/icons/VisibilityOff';
import moment from "moment";
import { loginWithEmailAndPassword } from "../../redux/actions/LoginActions";
import { indigo } from '@material-ui/core/colors';

const styles = theme => ({
  wrapper: {
    position: "relative"
  },

  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },

  multilineColor: {
    '&:hover fieldset': {
      borderColor: '#123F83 !important',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#123F83 !important',
    },
  }
});

const ColorButtonSignUp = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(indigo[700]),
    backgroundColor: indigo[700],
    '&:hover': {
      backgroundColor: indigo[900],
    },
  },
}))(Button);

class SignIn extends Component {
  state = {
    email: "",
    password: "",
    agreement: "",
    pasword_field_type: "password"
  };
  handleChange = event => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  handleFormSubmit = event => {
    this.props.loginWithEmailAndPassword({ ...this.state });
  };

  handle_passwod_view() {
    if (this.state.pasword_field_type == "password") {
      this.setState({
        pasword_field_type: "text"
      })
    } else {
      this.setState({
        pasword_field_type: "password"
      })
    }
  }

  render() {
    let { email, password } = this.state;
    let { classes } = this.props;
    return (
      <div className="signup flex flex-center w-50 h-100vh">
        <div className="p-8">
          <Card className="position-relative y-center" style={{ maxWidth: 500 }}>
            {/* <Card className="signup-card position-relative y-center"> */}
            <Grid container spacing={0}
              direction="column"
              alignItems="center"
              justify="center">
              <Grid item>
                <div className="p-32 flex flex-center flex-middle h-100">
                  <img src="/assets/images/mainImg.png" alt="" />
                </div>
              </Grid>
              <Grid item>
                <div className="p-36 h-100 bg-light-gray position-relative">
                  <Alert style={{ display: this.props.login.success ? "none" : "flex" }} className="mb-24" severity="error">{this.props.login.errortitle}</Alert>
                  <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                    <TextValidator
                      className="mb-24 w-100"
                      variant="outlined"
                      label="Username"
                      onChange={this.handleChange}
                      type="text"
                      name="email"
                      value={email}
                      validators={["required"]}
                      errorMessages={[
                        "This field is required",
                      ]}
                      InputProps={{
                        className: classes.multilineColor
                      }}
                      color="secondary"
                    />
                    <TextValidator
                      className="mb-16 w-100"
                      label="Password"
                      variant="outlined"
                      onChange={this.handleChange}
                      name="password"
                      type={this.state.pasword_field_type}
                      value={password}
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              disableRipple={true}
                              type="button"
                              onClick={() => this.handle_passwod_view()}
                            >
                              {this.state.pasword_field_type == "password"
                                ? <EyeOff />
                                : <EyeOn />
                              }

                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      InputProps={{
                        className: classes.multilineColor
                      }}
                      color="secondary"
                    />

                    <div className="flex flex-middle mb-8">
                      <div className={classes.wrapper}>
                        <ColorButtonSignUp
                          variant="contained"
                          // color="primary"
                          disabled={this.props.login.loading}
                          type="submit"
                        >
                          Sign in to Enter Dashboard
                        </ColorButtonSignUp>
                        {this.props.login.loading && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>

                    </div>
                    <Button
                      className="text-signuppage"
                      onClick={() =>
                        this.props.history.push("/session/forgot-password")
                      }
                    >
                      Forgot password?
                    </Button>
                  </ValidatorForm>
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
        <div style={{ position: 'fixed', bottom: 10, color: "white", right: '30px' }}>
                    <p style={{ textAlign: 'center' }}>© {moment().format("YYYY")} Visakha Vidyalaya Old Girls' Association. All rights reserved. Developed by Loons Lab.</p>
                </div>
      </div >
    );
  }
}

const mapStateToProps = state => ({
  loginWithEmailAndPassword: PropTypes.func.isRequired,
  login: state.login
});
export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      { loginWithEmailAndPassword }
    )(SignIn)
  )
);
