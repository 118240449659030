import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const Branch = MatxLoadable({
    loader: () =>
        import ("./New")
})

const Routes = [{
    path: "/datasetup/role",
    component: Branch,
    auth: authRoles.Membership_Roles
}];

export default Routes;