import React, { Component } from "react";
import { Button, Grid, Card } from "@material-ui/core";
import moment from "moment";

class loginMain extends Component {

    render() {
        return (
            <div className="signup flex flex-center  w-100 h-100vh" style={{ backgroundColor: "#0E2342" }} >
                <div

                    style={{
                        marginTop: 100,
                        marginBottom: 100,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundImage: 'url("/assets/images/loginBackground.jpg")',
                        width: "100%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                >
                    <div style={{ height: "auto", backgroundColor: "white", borderRadius: 40, opacity: 0.9, width: 350 }}>
                        <Grid container
                            className="p-30"
                            style={{ height: "auto", backgroundColor: "#FAFAFA", borderRadius: 40, opacity: 1 }}
                            direction="column"
                            alignItems="center"
                            justify="center">
                            <Grid item sm={12} xs={12}>
                                <img style={{ maxWidth: 100, marginTop: -100, borderRadius: 50 }} src="/assets/images/sigininlogo.png"></img>
                            </Grid>
                            <Grid item sm={12} xs={12} md={12} mt={10} style={{ color: "black", fontSize: 22, marginTop: 25 }}>
                                <div>
                                    <div className="flex flex-center">
                                        {"Visakha Vidyalaya"}
                                    </div>
                                    <div className="flex flex-center">
                                        {" Old Girls' Association"}
                                    </div>
                                    <div className="flex flex-center">
                                        {"Membership Portal"}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item sm={12} xs={12} md={12} style={{ marginTop: 15 }} >
                                <Button
                                    onClick={() => this.props.history.push("/login")}
                                    style={{ backgroundColor: "#07367E", border: "1px solid #FFCA0B", fontSize: 14, color: "#fff", fontSize: 14, width: 280, fontWeight: 600 }}
                                    variant="contained"
                                    disableElevation>
                                    OGA Member Log in
                                </Button>
                            </Grid>
                            {/* <Grid item sm={12} xs={12} md={12} style={{ color: "#fff", fontSize: 14, marginTop: 15 }}>
                                <div className="flex flex-center" style={{ fontSize: 12, color: "black", fontWeight: 500 }}>
                                    <hr
                                        style={{
                                            marginTop: 8,
                                            borderTop: 0,
                                            marginRight: 10,
                                            width: 50,
                                            color: "black",
                                            backgroundColor: "black",
                                            height: 1
                                        }}
                                    />
                                OR
                                <hr
                                        style={{
                                            marginTop: 8,
                                            borderTop: 0,
                                            marginLeft: 10,
                                            width: 50,
                                            color: "black",
                                            backgroundColor: "black",
                                            height: 1
                                        }}
                                    />
                                </div>
                            </Grid> */}
                            <Grid item sm={12} xs={12} md={12} style={{ marginTop: 15 }} >
                                <Button
                                    onClick={() => this.props.history.push("/external-member-registration")}
                                    style={{ backgroundColor: "#fff", border: "1px solid #FFCA0B", color: "#07367E", width: 280, fontWeight: 500 }}
                                    variant="contained"
                                    disableElevation>
                                    Register as an OGA member
                                </Button>
                            </Grid>
                            <Grid item sm={12} xs={12} md={12} style={{ marginTop: 15 }} >
                                <Button
                                    onClick={() => this.props.history.push("/external-payment")}
                                    style={{ backgroundColor: "#fff", border: "1px solid #FFCA0B", color: "#07367E", width: 280, fontWeight: 500 }}
                                    variant="contained"
                                    disableElevation>
                                    Make a payment
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <div style={{ position: 'fixed', bottom: 10, color: "white", right: '30px' }}>
                    <p style={{ textAlign: 'center' }}>© {moment().format("YYYY")} Visakha Vidyalaya Old Girls' Association. All rights reserved. Developed by Loons Lab.</p>
                </div>
            </div >
        );
    }
}



export default loginMain;
