import React, { Component } from "react";
import localStorageService from "./services/localStorageService";
import RoleFunctionsService from "./services/RoleFunctionsService";
import dashboardRoutes from "./views/dashboard/DashboardRoutes";

let name = "";
let navigation = [];

class navigations {


    // const array = localStorageService.getItem("auth_user").role;
    // for(var i=0; i< array.length;i++){
    //   if(array[i]!==="offline_access" && array[i]!= "uma_authorization"){
    //     name = localStorageService.getItem("auth_user").role[i];
    //   }

    // }


    getlogednUserRoles(dbroles) {
        navigation = [];
        var menu = [
            // {
            //     name: "dashboard",

            //     role: ['Developer', 'Super Admin', 'Secretary', 'Member', 'New Members Subcommittee Convener and Co-Convener', 'Exco Member', 'Treasurer and Assistant Treasurer'],
            //     navigation: {
            //         name: "Dashboard",
            //         path: "/dashboard/analytics",
            //         icon: "dashboard",

            //     },

            // },
            {
                name: "OGA_Membership",
                role: ['Developer', 'Super Admin', 'Secretary', 'Member', 'New Members Subcommittee Convener and Co-Convener', 'Exco Member', 'Treasurer and Assistant Treasurer'],
                navigation: {
                    name: "OGA Membership",
                    icon: "supervisor_account",
                    children: [{
                            name: "Member Information",
                            path: "/member_information",
                            //icon: "how_to_reg"
                        },
                        {
                            name: "Add New Applications",
                            path: "/internal_member",
                            //icon: "assignment_ind"
                        },
                        {
                            name: "Pending Approvals",
                            path: "/viewmemberapproval",
                            // icon: "assignment_turned_in"
                        },
                        {

                            name: "Membership Cards",
                            path: "/membershipcard",
                            // icon: "recent_actors"

                        },

                    ]
                },

            },
            // {
            //     name: "Proposer Seconder Approval",
            //     role: ['Developer', 'Super Admin', 'Secretary', 'Member', 'New Members Subcommittee Convener and Co-Convener', 'Exco Member', 'Treasurer and Assistant Treasurer'],
            //     navigation: {
            //         name: "Pro/Sec Approval",
            //         path: "/viewproposerseconder",
            //         icon: "how_to_reg"
            //     }
            // },
            {
                name: "Membership_Roles",
                role: ['Developer', 'Super Admin', 'Secretary', 'Member', 'New Members Subcommittee Convener and Co-Convener', 'Exco Member', 'Treasurer and Assistant Treasurer'],
                navigation: {
                    name: "Membership Roles",
                    icon: "school",
                    children: [{
                            name: "All Roles",
                            path: "/datasetup/userrole-management",
                            //icon: "import_export"
                        },
                        {
                            name: "Create Roles",
                            path: "/datasetup/role",
                            //icon: "import_export"
                        },




                    ]
                },

            },
            {
                name: "Ex_Co_Members",
                role: ['Developer', 'Super Admin', 'Secretary', 'Treasurer and Assistant Treasurer'],
                navigation: {
                    name: "Ex-Co Members",
                    icon: "local_library",
                    children: [{
                            name: "Ex-Co Master Data",
                            path: "/exco-masterdata",
                            //   icon: "account_balance"
                        },
                        {
                            name: "Add Ex-Co Members",
                            path: "/exco-member",
                            // icon: "account_box"
                        },
                        {
                            name: "Ex-Co Member Reports",
                            path: "/exco-report",
                            //  icon: "assessment"
                        },


                    ]
                }
            },
            {
                name: "Projects",
                role: ['Developer', 'Super Admin', 'Secretary', 'Member', 'New Members Subcommittee Convener and Co-Convener', 'Exco Member', 'Treasurer and Assistant Treasurer'],
                navigation: {
                    name: "Projects",
                    icon: "local_activity",
                    children: [{
                            name: "All Projects",
                            path: "/viewproject",
                            //    icon: "visibiltiy"
                        },

                        {
                            name: "Create Projects",
                            path: "/createproject",
                            //   icon: "how_to_reg"
                        },


                    ]
                },

            },

            {
                name: "Manage_Event",
                role: ['Developer', 'Super Admin', 'Secretary'],
                navigation: {
                    name: "Manage Events",
                    icon: "event",
                    path: "/datasetup/allnews",
                }


            },

            {
                name: "Make_a_Payment",
                role: ['Developer', 'Super Admin', 'Secretary', 'Member', 'New Members Subcommittee Convener and Co-Convener', 'Exco Member', 'Treasurer and Assistant Treasurer'],
                navigation: {
                    name: "Make a Payment",
                    path: "/payment",
                    icon: "payment"
                }
            },
            {
                name: "Payments_Reports",
                role: ['Developer', 'Super Admin', 'Secretary', 'Treasurer and Assistant Treasurer'],
                navigation: {
                    name: "Payments Reports",
                    icon: "account_balance",
                    children: [{
                            name: "All Payments Report",
                            path: "/all-payment-report",
                            //icon: "assessment"
                        },
                        {
                            name: "Project Payments Report",
                            path: "/donation-payment-report",
                            // icon: "assessment"
                        },
                        {
                            name: "Membership Fee Report",
                            path: "/member-payment-report",
                            //icon: "assessment"
                        },
                        {
                            name: "Duplicate Card Fee Report",
                            path: "/duplicatecard-payment-report",
                            //icon: "assessment"
                        },
                        {
                            name: "Failed Payment Report",
                            path: "/failed-payment-report",
                            //icon: "assessment"
                        }

                    ]
                },

            },
            {
                name: "Newsletters",
                role: ['Developer', 'Super Admin', 'Secretary', 'Member', 'New Members Subcommittee Convener and Co-Convener', 'Exco Member', 'Treasurer and Assistant Treasurer'],
                navigation: {
                    name: "Newsletters",
                    icon: "panorama",
                    children: [{
                            name: "All Newsletters",
                            path: "/view_newsletter"
                        },
                        {
                            name: "Send E-Newsletters",
                            path: "/new_newsletter"
                        },


                    ]
                },

            },
            {
                name: "Memebership_Communication",
                role: ['Developer', 'Super Admin', 'Secretary', 'Member', 'New Members Subcommittee Convener and Co-Convener', 'Exco Member', 'Treasurer and Assistant Treasurer'],
                navigation: {
                    name: "Communication",
                    icon: "forum",
                    children: [{
                            name: "All SMS",
                            path: "/view_sms"
                        },
                        {
                            name: "Create SMS",
                            path: "/new_sms"
                        },
                        {
                            name: "All Emails",
                            path: "/view_email"
                        },
                        {
                            name: "Create Emails",
                            path: "/new_email"
                        },


                    ]
                },

            },

            {
                name: "Payment_Setting",
                role: ['Developer', 'Super Admin', 'Secretary'],
                navigation: {
                    name: "Payment Settings",
                    icon: "settings",
                    children: [{
                            name: "Payment Fees",
                            path: "/viewmastersettings",
                            //  icon: "settings"
                        },
                        {
                            name: "Payment Types",
                            path: "/datasetup/other_payment_types",
                            //  icon: "receipt"
                        }
                    ]
                },


            },
            {
                name: "Email_Setting",
                role: ['Developer', 'Super Admin', 'Secretary'],
                navigation: {
                    name: "Email Settings",
                    icon: "mail_outline",
                    path: "/datasetup/emailtype",
                }


            },
            {
                name: "Message_Setting",
                role: ['Developer', 'Super Admin', 'Secretary'],
                navigation: {
                    name: "Message Settings",
                    icon: "sms_failed",
                    path: "/datasetup/message",
                }


            },

            {
                name: "Logs",
                role: ['Developer', 'Super Admin', 'Secretary'],
                navigation: {
                    name: "Logs",
                    icon: "file_copy",
                    children: [{
                            name: "Audit Log",
                            path: "/logs",
                            //  icon: "settings"
                        },
                        {
                            name: "Login Report",
                            path: "/login_logs",
                            //  icon: "receipt"
                        },
                        {
                            name: "Failed Email Log",
                            path: "/email_logs",
                            //  icon: "receipt"
                        }
                    ]
                },


            },

            // {
            //     name: "Manage_Event",
            //     role: ['Developer', 'Super Admin', 'Secretary'],
            //     navigation: {
            //         name: "Manage Events",
            //         icon: "event",
            //         path: "/datasetup/allnews",
            //     }


            // },

            // {
            //     name: "Notifications",
            //     role: ['Developer', 'Super Admin', 'Secretary', 'Member', 'New Members Subcommittee Convener and Co-Convener', 'Exco Member', 'Treasurer and Assistant Treasurer'],
            //     navigation: {
            //         name: "Notifications",
            //         path: "/notifications",
            //         icon: "notifications"
            //     }
            // }
        ]
        var roles = [];

        if (localStorageService.getItem("auth_user") !== null) {
            roles = localStorageService.getItem("auth_user").roles;
        }

        if (roles !== undefined) {

            const userpermission = roles.map(roles => roles).flat();

            menu.forEach(menuitem => {

                if (dbroles.find(dbr => dbr.name === menuitem.name)) {
                    menuitem.role = dbroles.filter(dbr => dbr.name === menuitem.name)[0].allroles;

                    var count = 0;
                    menuitem.role.forEach(menurole => {
                        if (userpermission.find(p => p === menurole)) {

                            if (count === 0) {
                                count++;
                                navigation.push(menuitem.navigation)
                            }

                        }
                    })
                }
            })
        }
    }

    getNavigation() {
        let permisions = localStorageService.getItem("auth_user_permisions")
        let roles;
        if (permisions) {
            permisions.forEach(element => {
                let name = element.name;
                let frole = [];
                element.role_functions.forEach(elements => {
                    frole.push(elements.roles.name)
                });
                element.allroles = frole;
            });
            roles = permisions;
            this.getlogednUserRoles(roles);
        }
        return navigation;
    }


}
export default new navigations();