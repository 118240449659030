import axios from 'axios';
import * as appconst from '../appconst';
import localStorageService from "./localStorageService";

class RoleFunctionsService {
    ls = window.localStorage

    create = async(data) => {
        const a = new Promise((resolve, reject) => {
            axios.post(appconst.CATEGORY, data).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    getAllfunctions = async(page) => {
       
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.FUNCTIONS+"/dropdown")
                .then(res => {
                    return resolve(res.data.Functions);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }



    update = async(data, id) => {
        const a = new Promise((resolve, reject) => {
            axios.put(appconst.CATEGORY + "/" + id, data).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    changeStatus = async(data, id) => {
        const a = new Promise((resolve, reject) => {
            axios.put(appconst.CATEGORY + "/chnageStatus/" + id, data).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }
}

export default new RoleFunctionsService();