import axios from 'axios';
import * as appconst from './../appconst';
import localStorageService from "./localStorageService";

class PaymentService {
    ls = window.localStorage

    createPayment = async(data) => { //Create new member
        const a = new Promise((resolve, reject) => {
            axios.post(appconst.PAYMENT, data).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error);
                    console.log("error", error);
                })
        });
        return await a;
    }

    createPaymentInternal = async(data) => { //Create new member
        const a = new Promise((resolve, reject) => {
            axios.post(appconst.PAYMENT, data).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error);
                })
        });
        return await a;
    }

    getAll = async(params, pagenumber) => {
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.PAYMENT + "?page=" + pagenumber, headers).then(res => {
                    return resolve(res.data.Payment);
                })
                .catch((error) => {
                    return resolve(error);
                })
        });
        return await a;
    }

    //   getMemberByOGA = async (ogaNo) => {//Get member by oga
    //     const a = new Promise((resolve, reject) => {
    //       axios.get(appconst.MEMBER + "/byEmail/email," + ogaNo)
    //         .then(res => {
    //           return resolve(res.data.Member);
    //         })
    //         .catch((error) => {
    //           console.log("error", error);
    //         })
    //     });
    //     return await a;
    //   }


    saveRecipt = async(data) => { //Create new member
        const a = new Promise((resolve, reject) => {
            axios.post(appconst.PAYMENT + "/save/receipt", data).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    sendPaymentFinance = async(data) => { //Create new member
        const headers = {
            headers: {
                'Access-Control-Allow-Origin': 'http://localhost:3000/',
                'Access-Control-Expose-Headers': '*'
            }
        };
        const a = new Promise((resolve, reject) => {
            axios.post(appconst.PAYMENT_OUTING, data,headers).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error);
                })
        });
        return await a;
    }

}
export default new PaymentService();