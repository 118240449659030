import React, { Component } from "react";
import {
  Card,
  Grid,
  Button,
  withStyles,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Snackbar
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";
import UserService from './../../services/UserService';
import Alert from '@material-ui/lab/Alert';
import moment from "moment";

import { resetPassword } from "../../redux/actions/LoginActions";

class ForgotPassword extends Component {
  state = {
    email: "",
    snakebar: false,
    message: "",
    severity: "success",
  };

  handleChange = event => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  // handleFormSubmit = event => {
  //   let para = this.state.email;
  //   UserService.getUserstoReset(para).then(res => {
  //     // this.setState({ message: "Email Sent", snakebar: true, severity: "success" })
  //     if (res.status == 200) {
  //               this.setState({ message: res.data.message, snakebar: true, severity: "success" })
  //               setTimeout(() => {
  //                   this.props.history.push('/session/signin');
  //               }, 2000);
  //           } else if (res.response.status == 422) {
  //               this.setState({ message: "Project Name or Code Already Exist", snakebar: true, severity: "error" })
  //           } else {
  //               this.setState({ message: "Can Not Update Project.Please Re try ", snakebar: true, severity: "error" })
  //           }
  //   })
  // };
  handleFormSubmit = event => {
    let para = this.state.email;
    UserService.getUserstoReset(para).then(res => {
      if (res.status == 200) {
        this.setState({ message: res.data.message, snakebar: true, severity: res.data.serverity })
        if (res.data.serverity == "success") {
          setTimeout(() => {
            this.props.history.push('/session/signin');
          }, 2000);
        }
      } else {
        this.setState({ message: "Email Send Error ", snakebar: true, severity: "error" })
      }
    })
      .catch((error) => {
        console.log("error", error);
      })
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ snakebar: false })

  };

  render() {
    let { email } = this.state;

    return (
      <div className="signup flex flex-center w-100 h-100vh">
        <div

          style={{
            marginTop: 100,
            marginBottom: 100,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage: 'url("/assets/images/loginBackground.jpg")',
            width: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div style={{ height: "auto", backgroundColor: "white", borderRadius: 40, opacity: 0.9, width: 350 }}>
            <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
              <Grid container
                className="p-30"
                style={{ height: "auto", backgroundColor: "#FAFAFA", borderRadius: 40, opacity: 1 }}
                direction="column"
                alignItems="center"
                justify="center">
                <Grid item sm={12} xs={12}>
                  <img style={{ maxWidth: 100, marginTop: -100, borderRadius: 50 }} src="/assets/images/sigininlogo.png"></img>
                </Grid>
                <Grid item sm={12} xs={12} md={12} mt={10} style={{ color: "black", fontSize: 22, marginTop: 25 }}>
                  <div>
                    <div className="flex flex-center">
                      {"Visakha Vidyalaya"}
                    </div>
                    <div className="flex flex-center">
                      {" Old Girls' Association"}
                    </div>
                    <div className="flex flex-center">
                      {"Membership Portal"}
                    </div>
                  </div>
                </Grid>
                <Grid item sm={12} xs={12} md={12} style={{ marginTop: 15 }}>
                  <TextValidator
                    style={{ width: 280 }}
                    size="small"
                    className="mb-24 w-100"
                    variant="outlined"
                    label="Email"
                    onChange={this.handleChange}
                    type="email"
                    name="email"
                    value={email}
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      "This field is required",
                      "Enter a valid email address "
                    ]}
                    autoComplete="off"
                  />
                </Grid>
                <div className="flex flex-middle">
                  <Button variant="contained" color="primary" type="submit">
                    Reset Password
                  </Button>
                  <span className="text-primary ml-16 mr-16">or</span>
                  <Button
                    variant="contained"
                    onClick={() =>
                      this.props.history.push("/login")
                    }
                  >
                    Sign in
                  </Button>
                </div>
              </Grid>
            </ValidatorForm>
          </div>
        </div>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.snakebar} autoHideDuration={2500} onClose={this.handleClose}>
          <Alert onClose={this.handleClose} severity={this.state.severity} variant="filled">
            {this.state.message}
          </Alert>
        </Snackbar>
        <div style={{ position: 'fixed', bottom: 10, color: "white", right: '30px' }}>
                    <p style={{ textAlign: 'center' }}>© {moment().format("YYYY")} Visakha Vidyalaya Old Girls' Association. All rights reserved. Developed by Loons Lab.</p>
                </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  resetPassword: PropTypes.func.isRequired,
  login: state.login
});
export default withRouter(
  connect(
    mapStateToProps,
    { resetPassword }
  )(ForgotPassword)
);
