import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const InternalMember = MatxLoadable({
  loader: () => import("./InternalMember")
})

const InternalMemberRoutes = [
  {
    path: "/internal_member",
    component: InternalMember,
    auth: authRoles.OGA_Membership,
  }
];

export default InternalMemberRoutes;
