import axios from 'axios';
import * as appconst from '../appconst';
import localStorageService from "./localStorageService";

class MemberRegistrationService {
    ls = window.localStorage

    createProject = async (data) => {//Create new member
        const a = new Promise((resolve, reject) => {
            axios.post(appconst.PROJECT, data).then(res => {
                return resolve(res);
            })
                .catch((error) => {
                    return resolve(error);
                    // console.log("error", error);
                })
        });
        return await a;
    }
    getallsubcomittee = async () => { 
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            }
        };

        const a = new Promise((resolve, reject) => {
            axios.get(appconst.SUBCOMMITTEE,headers)
                .then(res => {
                    return resolve(res.data.SubComitee);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    getallProject = async () => { 
       
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.PROJECT)
                .then(res => {
                    return resolve(res.data.Event);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }
    getallProjectForPayments = async () => { 
       

        const a = new Promise((resolve, reject) => {
            axios.get(appconst.PROJECT+'/forpaymets')
                .then(res => {
                    return resolve(res.data.Event);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    getallProjectForReport = async (data) => { 
       
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params:data

        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.PROJECT+'/forreport',headers)
                .then(res => {
                    return resolve(res.data.Event);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }


    

    getCurrentProject = async (params) => {
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.PROJECT + "/current", headers).then(res => {
                return resolve(res.data.Event);

            })
                .catch((error) => {
                    return resolve(error);
                    // console.log("error", error);
                })
        });
        return await a;
    }
    getCurrentProjectwithpagination = async (params, pagenumber) => { //Get All new cases
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.PROJECT + "/current?page=" + pagenumber, headers)
                .then(res => {

                    return resolve(res.data.Event);

                })
                .catch((error) => {
                    console.log("error", error);
                    return resolve(error.response.status);
                })
        });
        return await a;
    }
    getArchivedProject = async (params) => {
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.PROJECT + "/archived", headers).then(res => {
                return resolve(res.data.Event);

            })
                .catch((error) => {
                    return resolve(error);
                    // console.log("error", error);
                })
        });
        return await a;
    }
    getArchivedProjectwithpagination = async (params, pagenumber) => { //Get All new cases
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.PROJECT + "/archived?page=" + pagenumber, headers)
                .then(res => {

                    return resolve(res.data.Event);

                })
                .catch((error) => {
                    console.log("error", error);
                    return resolve(error.response.status);
                })
        });
        return await a;
    }
    getCurrentProjectSingle = async (params) => {
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.PROJECT + "/currentsingle", headers).then(res => {
                return resolve(res.data.Event);

            })
                .catch((error) => {
                    return resolve(error);
                    // console.log("error", error);
                })
        });
        return await a;
    }
    getCurrentProjectSinglewithpagination = async (params, pagenumber) => { //Get All new cases
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.PROJECT + "/currentsingle?page=" + pagenumber, headers)
                .then(res => {

                    return resolve(res.data.Event);

                })
                .catch((error) => {
                    console.log("error", error);
                    return resolve(error.response.status);
                })
        });
        return await a;
    }
    getArchivedProjectSingle = async (params) => {
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.PROJECT + "/archivedsingle", headers).then(res => {
                return resolve(res.data.Event);

            })
                .catch((error) => {
                    return resolve(error);
                    // console.log("error", error);
                })
        });
        return await a;
    }
    getArchivedProjectSinglewithpagination = async (params, pagenumber) => { //Get All new cases
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.PROJECT + "/archivedsingle?page=" + pagenumber, headers)
                .then(res => {

                    return resolve(res.data.Event);

                })
                .catch((error) => {
                    console.log("error", error);
                    return resolve(error.response.status);
                })
        });
        return await a;
    }

    changeProjectStatus = async(id,data) => { 
        const a = new Promise((resolve, reject) => {
            axios.put(appconst.PROJECT + "/status/" + id,data).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    updateProject = async(id,data) => { 
        
        const a = new Promise((resolve, reject) => {
            axios.put(appconst.PROJECT + "/all/" + id,data).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error);
                    console.log("error", error);
                })
        });
        return await a;
    }

    getProjectById = async (id) => { 
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.PROJECT+"/byid/"+id,headers)
                .then(res => {
                    return resolve(res.data.Event);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    getProjectPayments = async (id,data) => { 
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params: data
        };
      
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.PROJECTPAYMENTS+"/"+id,headers)
                .then(res => {
                    return resolve(res.data.Payment);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }
    getProjectPaymentswithPagination = async (id,pagenumber,data) => { 
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params: data
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.PROJECTPAYMENTS+"/"+id+"?page=" + pagenumber,headers)
                .then(res => {
                    return resolve(res.data.Payment);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }



}
export default new MemberRegistrationService();