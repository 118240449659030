import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles";

const Users = MatxLoadable({
    loader: () =>
        import ("./Users")
})


const UserProfile = MatxLoadable({
    loader: () =>
        import ("./UserProfile")
})

const UserRoleManagement = MatxLoadable({
    loader: () =>
        import ("./UserRoleManagement")
})
const UsersRoutes = [{
        path: "/datasetup/userprofile",
        component: UserProfile,
        auth: authRoles.all
    },
    {
        path: "/datasetup/userrole-management",
        component: UserRoleManagement,
        auth: authRoles.Membership_Roles
    }
];

export default UsersRoutes;