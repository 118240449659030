import axios from 'axios';
import * as appconst from '../appconst';
import localStorageService from "./localStorageService";

class MemberRegistrationService {
    ls = window.localStorage

  
    getAllMasterSettings = async () => { //Get All regional offices 

        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MASTERSETTINGS)
                .then(res => {
                    return resolve(res.data.MasterSettings);
                })
                .catch((error) => {
                    return resolve(error.response.status);
                    console.log("error", error);
                })
        });
        return await a;
    }

    getAllMasterSettingswithpagination = async (pagenumber) => { //Get All new cases
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            }
        };
        
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MASTERSETTINGS + "/?page=" + pagenumber,headers)
                .then(res => {

                    return resolve(res.data.MasterSettings);

                })
                .catch((error) => {
                    console.log("error", error);
                    return resolve(error.response.status);
                })
        });
        return await a;
    }
  
    getMasterSettingsByType = async (params) => {
         const token = await localStorageService.getItem("auth_user");
         const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MASTERSETTINGS + "/bytype", headers).then(res => {
                return resolve(res.data.MasterSettings);

            })
                .catch((error) => {
                    return resolve(error);
                    // console.log("error", error);
                })
        });
        return await a;
    }
  
    updateMASTERSETTINGS = async(id,data) => { 
        const a = new Promise((resolve, reject) => {
            axios.put(appconst.MASTERSETTINGS + "/" + id,data).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }




}
export default new MemberRegistrationService();