import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const Create = MatxLoadable({
    loader: () =>
        import ("./Create")
})

const List = MatxLoadable({
    loader: () =>
        import ("./View")
})

const Update = MatxLoadable({
    loader: () =>
        import ("./Update")
})

const Routes = [{
        path: "/datasetup/news",
        component: Create,
        auth: authRoles.Manage_Event
    },
    {

        path: "/datasetup/allnews",
        component: List,
        auth: authRoles.Manage_Event
    },
    {
        path: "/datasetup/update_news/:id",
        component: Update,
        auth: authRoles.Manage_Event
    }
];

export default Routes;