import React, { Component } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { SimpleCard } from "matx";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import SaveIcon from '@material-ui/icons/Save';
import MemberRegistrationsService from './../../services/MemberRegistrationsService';
import { resetIdCounter } from "downshift";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { FormGroup, Link } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import { indigo, grey, teal } from '@material-ui/core/colors';
import * as appVariables from './../../appVariables';
import Box from '@material-ui/core/Box';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, DatePicker } from "@material-ui/pickers";
import StepConnector from '@material-ui/core/StepConnector';
import Check from '@material-ui/icons/Check';
import clsx from 'clsx';
import MuiPhoneInput from 'material-ui-phone-number';
import MuiPhoneNumber from 'material-ui-phone-number';
import MomentUtils from "@date-io/moment";
import moment from 'moment';
import { withRouter } from "react-router-dom";
import EventIcon from '@material-ui/icons/Event';
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Tooltip } from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';

const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: '#FFD700',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#FFD700',
        },
    },
    line: {
        borderColor: '#FFD700',
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
    root: {
        color: 'transparent',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: '#FFD700',
    },
    circle: {
        width: 33,
        height: 33,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
        border: '2px solid #FFD700',
    },
    completed: {
        color: '#123F83',
        zIndex: 1,
        fontSize: 10,
        border: '1px solid #FFD700',
        borderRadius: '50%',
        width: 17,
        height: 17,
        padding: 7,
        fontWeight: "bolder",
        backgroundColor: '#FFD700',
    },
});

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
        </div>
    );
}

const styles = theme => ({

    cssLabel: {
        '&::before': {
            content: '*',
            color: 'red',
            position: 'absolute'
        },
    },

    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: `${theme.palette.primary.main} !important`,
        }
    },

    palette: {
        primary: {
            main: teal[500],
        },
        secondary: {
            main: indigo[500],
        },
    },

    cssFocused: {},

    notchedOutline: {
        borderWidth: '1px',
        borderColor: 'blue !important'
    },

});

const ColorButton1 = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(grey[300]),
        backgroundColor: grey[300],
        // borderWidth: 1,
        // borderColor: grey[500],
        // borderStyle: "solid",
        boxShadow: "none",
        '&:hover': {
            backgroundColor: grey[500],

        },
    },
}))(Button);

const ColorButton2 = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(teal[700]),
        backgroundColor: teal[700],
        '&:hover': {
            backgroundColor: teal[900],
        },
    },
}))(Button);

//   React.render(
//     <MuiPhoneNumber defaultCountry={'us'}/>,
//     document.getElementById('root')
//   );

class AllMember extends Component {
    constructor(props) {
        super(props)

        this.state = {
            waitingforcomplete: false,
            disableConfirm: false,
            instructions: true,
            leavingYrReq: false,
            proposer_error_msg: "",
            seconder_error_msg: "",
            emptySeconder: false,
            emptyProposer: false,
            checkAdmissionYear: false,
            checkNewsLetterPre: false,
            postalDataCheck: false,
            postalDataDisable: false,
            toConfirm: false,
            search_propser: "",
            search_seconder: "",
            successfullySubmitted: false,
            district: [],
            years: [],
            selectedrowid: '',
            categories: [],
            subcategories: [],
            years: [],
            grades: [],
            countries: [],
            uploadokLeavecertificate: true,
            uploadokprofile: true,
            activeStep: 0,
            steps: ['Select campaign settings', 'Create an ad group', 'Create an ad'],
            resCountryDisable: true,
            addressDisable: false,
            deleteconform: false,
            hideDistrict: false,
            slOrNonSl: false,
            hideNewsLetter: false,
            seconderConfirm: false,
            proposerConfirm: false,
            proposerSeconderConfirm: false,
            propserError: false,
            seconderError: false,
            sameAsSeconder: false,
            sameAsProposer: false,
            viewMobError: false,
            viewMobErrorLength: false,
            viewMobErrorLength2: false,
            snakebar: false,
            message: "",
            severity: "success",
            id: '',
            photo: null,
            leavingCertificate: null,
            formdata: { name: "", surname: "", full_name: "", name_on_card: "", nic_no: "", contact_no: "", other_contact_no: "", email: "", occupation: null, res_number_street: "", res_city: "", res_country: "Sri Lanka", number_street: "", city: "", country: "Sri Lanka", date_of_birth: null, years: "", district: "", res_district: "", admission_year: null, al_batch: null, leaving_year: null, admission_no: "", photo: "", photo_name: "", school_leaving_certificate: "", school_leaving_certificate_name: "", promotional_content: "No", newsletter_preference: null, newsletter_type: null, passport_no: "", left_school_before_al: "No", nationality: "Sri Lankan", admitted_grade: "", seconder_name: "" },
            seconderdata: {},
            proposerdata: {},
            categoryform: { name: '', rank: '' },
            subcategoryform: { category_id: '', name: '', rank: '' },
            yearsform: { name: '' },
            otheruploadprocess: 0,

            editcategoryform: { id: '', name: '', rank: '' },
            editsubcategoryform: { id: '', category_id: '', name: '', rank: '' },

            tab: 0,
            case_id: null,
            max_birthday: moment().format("YYYY-MM-DD"),
            agree: false,

        };

        //creates a reference for your element to use
        this.myDivToFocus = React.createRef()
    }

    scroll() {

        this.myDivToFocus.current.scrollIntoView({
            block: "start"
        })

    }

    componentDidMount() {
        let district = appVariables.district;
        let countries = appVariables.countries;
        let years = appVariables.years;
        let grades = appVariables.grades;
        this.setState({ district: district })
        this.setState({ years: years })
        this.setState({ grades: grades })
        this.setState({ countries: countries })
    }

    handleTabChange = (event, newValue) => {
        this.setState({ tab: newValue })
    };

    handleClose = (event, reason) => {//For handle alert visibility
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snakebar: false })
    };

    setdropdowndata(key, value) {// Set value of dropdown
        var data = this.state.formdata;
        if (value == null) {
            data[key] = "";
            this.setState({ formdata: data });
        } else {
            if (key == "res_district") {
                if (this.state.postalDataCheck) {
                    data['district'] = value.title;
                }
            }

            data[key] = value.title;
            this.setState({ formdata: data });
        }
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter' || e.key === "Tab") {
            this.setState({ value: e.target.value })
        }
    }

    toggleCountry = (event) => {
        var hideDistrict = false;
        var addressDisable = false;
        var resCountryDisable = true;
        var data = this.state.formdata;
        var postalDataCheck = false;
        var postalDataDisable = false;

        data['country'] = "";
        data['res_country'] = "";
        data['number_street'] = "";
        data['res_number_street'] = "";
        data['city'] = "";
        data['res_city'] = "";
        data['district'] = "";
        data['res_district'] = "";

        if (event.target.value != "srilanka") {
            hideDistrict = true;
            addressDisable = true;
            postalDataDisable = true;
            resCountryDisable = false;
        } else {
            data['country'] = "Sri Lanka";
            data['res_country'] = "Sri Lanka";
        }

        data['newsletter_preference'] = "No"
        data['newsletter_type'] = null;

        this.setState({ hideNewsLetter: false, formdata: data, hideDistrict: hideDistrict, addressDisable: addressDisable, resCountryDisable: resCountryDisable, postalDataCheck: postalDataCheck, postalDataDisable: postalDataDisable });
    }

    toggleNewsLetter = (event) => {
        var hideNewsLetter = false;
        if (event.target.checked) {
            hideNewsLetter = true;
        }
        this.setState({ hideNewsLetter: hideNewsLetter });
    }

    toggleNationality = (event) => {
        var slOrNonSl = false;
        var data = this.state.formdata;

        if (event.target.value != "Sri Lankan") {
            slOrNonSl = true;
        } else {
            slOrNonSl = false;
        }
        data['nationality'] = event.target.value;
        data['nic_no'] = null;
        data['passport_no'] = null;

        this.setState({ formdata: data, slOrNonSl: slOrNonSl });
    }


    copyAddressData = event => {
        var data = this.state.formdata;
        var addressDisable = false;
        var postalDataCheck = false;
        var postalDataDisable = false;

        if (event.target.checked) {
            data['number_street'] = data.res_number_street;
            data['city'] = data.res_city;
            data['district'] = data.res_district;
            data['country'] = data.res_country;
            // addressDisable = true;
            postalDataCheck = true;
            postalDataDisable = true;
        } else {
            data['number_street'] = "";
            data['city'] = "";
            data['district'] = "";
        }
        this.setState({ formdata: data, addressDisable: addressDisable, postalDataCheck: postalDataCheck, postalDataDisable: postalDataDisable });
    }

    handleContactNoCountryCodeChange(value) {
        var data = this.state.formdata;
        data['contact_no'] = value;
        let moberror = false;
        let moberrorlength2 = false;
        let moberrorlength = false;


        if (data.contact_no.length !== 12 && data.contact_no.startsWith("+94")) {

            moberrorlength = true
            this.setState({ viewMobError: moberror, viewMobErrorLength: moberrorlength, viewMobErrorLength2: moberrorlength2 });
        } else if (data.contact_no.length === 12 && data.contact_no.startsWith("+94")) {
            moberrorlength = false
            this.setState({ viewMobError: moberror, viewMobErrorLength: moberrorlength, viewMobErrorLength2: moberrorlength2 });

        }


        this.setState({
            formdata: data
        });
    }

    handleOtherContactNoCountryCodeChange(value) {
        var data = this.state.formdata;
        data['other_contact_no'] = value

        let moberror = false;
        let moberrorlength2 = false;
        let moberrorlength = false;

        if (data.other_contact_no.length !== 12 && data.other_contact_no.startsWith("+94")) {
            if (data.other_contact_no.length === 3) {

                moberrorlength2 = false
                this.setState({ viewMobError: moberror, viewMobErrorLength: moberrorlength, viewMobErrorLength2: moberrorlength2 });

            } else {
                moberrorlength2 = true
                this.setState({ viewMobError: moberror, viewMobErrorLength: moberrorlength, viewMobErrorLength2: moberrorlength2 });

            }
        } else if (data.other_contact_no.length === 12 && data.other_contact_no.startsWith("+94")) {

            moberrorlength2 = false
            this.setState({ viewMobError: moberror, viewMobErrorLength: moberrorlength, viewMobErrorLength2: moberrorlength2 });

        }

        this.setState({
            formdata: data
        });
    }

    handleUploadChange = event => {
        event.persist();
        var data = this.state.formdata;
        const max_size = appVariables.MAX_UPLOD_SIZE;
        if ([event.target.name] == "photo") {
            if (event.target.files[0] !== undefined) {
                if ((event.target.files[0].size / 1024 / 1024) > max_size) {
                    event.preventDefault();
                    data['photo_name'] = ""
                    data['photo'] = ""
                    this.setState({ formdata: data, uploadokprofile: true, message: "Cannot Upload the profile picture. File must be 5MB or lesser in size.", snakebar: true, severity: "error" })
                    return;
                } else if (event.target.files[0].type.split('/')[0] != "image") {
                    event.preventDefault();
                    data['photo_name'] = ""
                    data['photo'] = ""
                    this.setState({ formdata: data, uploadokprofile: true, message: "Cannot Upload. Please upload an image ", snakebar: true, severity: "error" })
                    return;
                } else {
                    data['photo'] = event.target.files[0] || event.dataTransfer.files[0];
                    this.setState({
                        photo: URL.createObjectURL(event.target.files[0]),
                        formdata: data,
                        uploadokprofile: false,
                    });
                }

            }
        } else if ([event.target.name] == "school_leaving_certificate") {
            if (event.target.files[0] !== undefined) {
                if ((event.target.files[0].size / 1024 / 1024) > max_size) {
                    event.preventDefault();
                    data['school_leaving_certificate_name'] = ""
                    data['school_leaving_certificate'] = ""
                    this.setState({ formdata: data, uploadokLeavecertificate: true, message: "Cannot Upload. File is too large.  Must be lesser than or equal to 5MB", snakebar: true, severity: "error" })
                    return;
                } else if (event.target.files[0].type.split('/')[0] != "image") {
                    event.preventDefault();
                    data['school_leaving_certificate_name'] = ""
                    data['school_leaving_certificate'] = ""
                    this.setState({ formdata: data, uploadokLeavecertificate: true, message: "Cannot Upload. Please upload an image", snakebar: true, severity: "error" })
                    return;
                } else {
                    data['school_leaving_certificate'] = event.target.files[0] || event.dataTransfer.files[0];
                    data['school_leaving_certificate_name'] = event.target.files[0].name

                    this.setState({
                        leavingCertificate: URL.createObjectURL(event.target.files[0]),
                        formdata: data,
                        uploadokLeavecertificate: false,
                    });
                }

            }
        }
    }

    getPropser = event => {
        let checkQry = false;
        var proposerNo = this.state.formdata.search_propser;
        var seconderNo = this.state.formdata.search_seconder;
        var sameAsSeconder = false;
        var proposerConfirm = false;
        var propserError = false;
        var emptyProposer = false;
        var proposerSeconderConfirm = false;
        var proposerErrorMsg = this.state.proposer_error_msg;

        // if (event.key === 'Enter' || event.key === "Tab") {

        if (proposerNo.length >= 4) {
            if (proposerNo == "" || proposerNo == undefined) {
                emptyProposer = true;
                this.setState({
                    emptyProposer: emptyProposer,
                    proposerConfirm: proposerConfirm,
                    propserError: propserError,
                    proposerSeconderConfirm: proposerSeconderConfirm
                })
            }
            if ((proposerNo == seconderNo) && (proposerNo != "" && seconderNo != "") && (proposerNo != undefined && seconderNo != undefined)) {
                sameAsSeconder = true;
                this.setState({
                    sameAsSeconder: sameAsSeconder,
                    emptyProposer: emptyProposer,
                    proposerConfirm: proposerConfirm,
                    propserError: propserError,
                    proposerSeconderConfirm: proposerSeconderConfirm
                })
            } else {
                if (event.key === 'Enter') {
                    event.preventDefault();
                }
                let params = proposerNo;
                MemberRegistrationsService.getMemberByOGAForProposerSeconder(params).then((data) => {
                    checkQry = true;
                    if (data.Member.length >= 1) {
                        proposerConfirm = true;
                        this.setState({ proposerdata: data.Member[0], proposerConfirm: proposerConfirm })
                    } else {
                        if (proposerNo != "" && proposerNo != undefined) {
                            proposerErrorMsg = data.message;
                            propserError = true;
                        }
                    }

                    this.setState({
                        sameAsSeconder: sameAsSeconder,
                        proposerConfirm: proposerConfirm,
                        propserError: propserError,
                        proposerSeconderConfirm: proposerSeconderConfirm,
                        emptyProposer: emptyProposer,
                        proposer_error_msg: proposerErrorMsg
                    })

                    if (this.state.proposerConfirm == true && this.state.seconderConfirm == true) {
                        this.setState({
                            proposerSeconderConfirm: true
                        })
                    }
                })
            }
        } else {
            if (proposerNo != "" && proposerNo != undefined) {
                proposerErrorMsg = "Invalid OGA number";
                propserError = true;
                this.setState({
                    sameAsSeconder: sameAsSeconder,
                    proposerConfirm: proposerConfirm,
                    propserError: propserError,
                    proposerSeconderConfirm: proposerSeconderConfirm,
                    emptyProposer: emptyProposer,
                    proposer_error_msg: proposerErrorMsg
                })
            }
        }
        // }
    }

    getPropserMobile = event => {
        let checkQry = false;
        var proposerNo = this.state.formdata.search_propser;
        var seconderNo = this.state.formdata.search_seconder;
        var sameAsSeconder = false;
        var proposerConfirm = false;
        var propserError = false;
        var emptyProposer = false;
        var proposerSeconderConfirm = false;
        var proposerErrorMsg = this.state.proposer_error_msg;

        if (proposerNo == "" || proposerNo == undefined) {
            emptyProposer = true;
            this.setState({
                emptyProposer: emptyProposer,
                proposerConfirm: proposerConfirm,
                propserError: propserError,
                proposerSeconderConfirm: proposerSeconderConfirm
            })
        }
        if ((proposerNo == seconderNo) && (proposerNo != "" && seconderNo != "") && (proposerNo != undefined && seconderNo != undefined)) {
            sameAsSeconder = true;
            this.setState({
                sameAsSeconder: sameAsSeconder,
                emptyProposer: emptyProposer,
                proposerConfirm: proposerConfirm,
                propserError: propserError,
                proposerSeconderConfirm: proposerSeconderConfirm
            })
        } else {
            if (event.key === 'Enter') {
                event.preventDefault();
            }
            let params = proposerNo;
            MemberRegistrationsService.getMemberByOGAForProposerSeconder(params).then((data) => {
                checkQry = true;
                if (data.Member.length >= 1) {
                    proposerConfirm = true;
                    this.setState({ proposerdata: data.Member[0], proposerConfirm: proposerConfirm })
                } else {
                    if (proposerNo != "" && proposerNo != undefined) {
                        proposerErrorMsg = data.message;
                        propserError = true;
                    }
                }

                this.setState({
                    sameAsSeconder: sameAsSeconder,
                    proposerConfirm: proposerConfirm,
                    propserError: propserError,
                    proposerSeconderConfirm: proposerSeconderConfirm,
                    emptyProposer: emptyProposer,
                    proposer_error_msg: proposerErrorMsg
                })

                if (this.state.proposerConfirm == true && this.state.seconderConfirm == true) {
                    this.setState({
                        proposerSeconderConfirm: true
                    })
                }
            })
        }
    }

    getSeconder = event => {
        let checkQry = false;
        var proposerNo = this.state.formdata.search_propser;
        var seconderNo = this.state.formdata.search_seconder;
        var sameAsProposer = false;
        var seconderConfirm = false;
        var seconderError = false;
        var emptySeconder = false;
        var proposerSeconderConfirm = false;
        var seconderErrorMsg = this.state.seconder_error_msg;
        if (seconderNo.length >= 4) {
            // if (event.key === 'Enter' || event.key === "Tab") {
            if (seconderNo == "" || seconderNo == undefined) {
                emptySeconder = true;
                this.setState({
                    emptySeconder: emptySeconder,
                    seconderConfirm: seconderConfirm,
                    seconderError: seconderError,
                    proposerSeconderConfirm: proposerSeconderConfirm
                })
            }
            if ((proposerNo == seconderNo) && (proposerNo != "" && seconderNo != "") && (proposerNo != undefined && seconderNo != undefined)) {
                sameAsProposer = true;
                this.setState({
                    sameAsProposer: sameAsProposer,
                    emptySeconder: emptySeconder,
                    seconderConfirm: seconderConfirm,
                    seconderError: seconderError,
                    proposerSeconderConfirm: proposerSeconderConfirm
                })
            } else {
                if (event.key === 'Enter') {
                    event.preventDefault();
                }
                let params = seconderNo;
                MemberRegistrationsService.getMemberByOGAForProposerSeconder(params).then((data) => {
                    checkQry = true;
                    if (data.Member.length >= 1) {
                        seconderConfirm = true;
                        this.setState({ seconderdata: data.Member[0], seconderConfirm: seconderConfirm })
                    } else {
                        if (seconderNo != "" && seconderNo != undefined) {
                            seconderErrorMsg = data.message;
                            seconderError = true;
                        }
                    }

                    this.setState({
                        sameAsProposer: sameAsProposer,
                        seconderConfirm: seconderConfirm,
                        seconderError: seconderError,
                        proposerSeconderConfirm: proposerSeconderConfirm,
                        emptySeconder: emptySeconder,
                        seconder_error_msg: seconderErrorMsg
                    })

                    if (this.state.proposerConfirm == true && this.state.seconderConfirm == true) {
                        this.setState({
                            proposerSeconderConfirm: true
                        })
                    }
                })
            }
        } else {
            if (seconderNo != "" && seconderNo != undefined) {
                seconderErrorMsg = "Invalid OGA number";
                seconderError = true;
                this.setState({
                    sameAsProposer: sameAsProposer,
                    seconderConfirm: seconderConfirm,
                    seconderError: seconderError,
                    proposerSeconderConfirm: proposerSeconderConfirm,
                    emptySeconder: emptySeconder,
                    seconder_error_msg: seconderErrorMsg
                })
            }
        }
        // }
    }

    getSeconderMobile = event => {
        let checkQry = false;
        var proposerNo = this.state.formdata.search_propser;
        var seconderNo = this.state.formdata.search_seconder;
        var sameAsProposer = false;
        var seconderConfirm = false;
        var seconderError = false;
        var emptySeconder = false;
        var proposerSeconderConfirm = false;
        var seconderErrorMsg = this.state.seconder_error_msg;

        if (seconderNo == "" || seconderNo == undefined) {
            emptySeconder = true;
            this.setState({
                emptySeconder: emptySeconder,
                seconderConfirm: seconderConfirm,
                seconderError: seconderError,
                proposerSeconderConfirm: proposerSeconderConfirm
            })
        }
        if ((proposerNo == seconderNo) && (proposerNo != "" && seconderNo != "") && (proposerNo != undefined && seconderNo != undefined)) {
            sameAsProposer = true;
            this.setState({
                sameAsProposer: sameAsProposer,
                emptySeconder: emptySeconder,
                seconderConfirm: seconderConfirm,
                seconderError: seconderError,
                proposerSeconderConfirm: proposerSeconderConfirm
            })
        } else {
            if (event.key === 'Enter') {
                event.preventDefault();
            }
            let params = seconderNo;
            MemberRegistrationsService.getMemberByOGAForProposerSeconder(params).then((data) => {
                checkQry = true;
                if (data.Member.length >= 1) {
                    seconderConfirm = true;
                    this.setState({ seconderdata: data.Member[0], seconderConfirm: seconderConfirm })
                } else {
                    if (seconderNo != "" && seconderNo != undefined) {
                        seconderErrorMsg = data.message;
                        seconderError = true;
                    }
                }

                this.setState({
                    sameAsProposer: sameAsProposer,
                    seconderConfirm: seconderConfirm,
                    seconderError: seconderError,
                    proposerSeconderConfirm: proposerSeconderConfirm,
                    emptySeconder: emptySeconder,
                    seconder_error_msg: seconderErrorMsg
                })

                if (this.state.proposerConfirm == true && this.state.seconderConfirm == true) {
                    this.setState({
                        proposerSeconderConfirm: true
                    })
                }
            })
        }
    }

    handleChange = event => {//For Set edited form data
        event.persist();
        var data = this.state.formdata;
        var checkNewsLetterPre = this.state.checkNewsLetterPre;

        if ([event.target.name] == "newsletter_type") {
            if (data['newsletter_preference'] == "Yes") {
                data['newsletter_type'] = event.target.value;
            } else {
                data['newsletter_type'] = null;
            }
        } else if ([event.target.name] == "left_school_before_al") {
            if (event.target.checked) {
                data['left_school_before_al'] = "Yes";
            } else {
                data['left_school_before_al'] = "No";
            }
        } else if ([event.target.name] == "promotional_content") {
            if (event.target.checked) {
                data['promotional_content'] = "Yes"
            } else {
                data['promotional_content'] = "No"
            }
        } else if ([event.target.name] == "newsletter_preference") {
            if (event.target.checked) {
                data['newsletter_preference'] = "Yes"
                checkNewsLetterPre = true;
                data['newsletter_type'] = "By Email";
            } else {
                data['newsletter_preference'] = "No"
                data['newsletter_type'] = null;
            }
            this.toggleNewsLetter(event);
        } else {
            data[event.target.name] = event.target.value;
        }

        if ([event.target.name] == "res_number_street") {
            if (this.state.postalDataCheck) {
                data['number_street'] = event.target.value;
            }
        }

        if ([event.target.name] == "res_city") {
            if (this.state.postalDataCheck) {
                data['city'] = event.target.value;
            }
        }

        if ([event.target.name] == "res_district") {
            if (this.state.postalDataCheck) {
                data['district'] = event.target.value;
            }
        }

        this.setState({
            formdata: data,
            checkNewsLetterPre: checkNewsLetterPre
        });
    };


    handleFormSubmit = () => {
        this.scroll();
        if (this.state.tab == 3 && this.state.toConfirm == false) {
            var data = this.state.formdata
            if (data.search_propser == data.search_seconder) {
                this.setState({ severity: "error", snakebar: true, message: "Proposer and Seconder cannot be the same person." });
            } else {
                let params = { 'search_proposer': data.search_propser, 'search_seconder': data.search_seconder }

                MemberRegistrationsService.checkMemberEligibiltiyForProposerandSeconder(params).then((res) => {
                    if (res.status == 200) {
                        this.setState({ proposerdata: res.data.Proposer, seconderdata: res.data.Seconder, toConfirm: true })
                    } else {
                        this.setState({ severity: "error", snakebar: true, message: res.response.data.message });
                    }
                })

            }
        } else {
            let moberror = false;
            let moberrorlength2 = false;
            let moberrorlength = false;
            let leavingYrReq = false;


            if (this.state.tab == 0) {
                var data = this.state.formdata
                let send_data = { id: data.id, nic_no: data.nic_no, email: data.email, admission_no: data.admission_no };



                MemberRegistrationsService.getMemberDataCheck(send_data).then((res) => {
                    if (res.status === 200) {
                        if (data.contact_no == "" || data.contact_no == null) {
                            moberror = true
                            this.setState({ viewMobError: moberror, viewMobErrorLength: moberrorlength, viewMobErrorLength2: moberrorlength2 });
                        } else if (data.contact_no.length !== 12 && data.contact_no.startsWith("+94")) {

                            moberrorlength = true
                            this.setState({ viewMobError: moberror, viewMobErrorLength: moberrorlength, viewMobErrorLength2: moberrorlength2 });
                        }

                        if ((data.other_contact_no != "" && data.other_contact_no != null) && (data.other_contact_no.length !== 12 && data.other_contact_no.startsWith("+94"))) {
                            if (data.other_contact_no.length === 3) {

                                moberrorlength2 = false
                                this.setState({ viewMobError: moberror, viewMobErrorLength: moberrorlength, viewMobErrorLength2: moberrorlength2 });

                            } else {
                                moberrorlength2 = true
                                this.setState({ viewMobError: moberror, viewMobErrorLength: moberrorlength, viewMobErrorLength2: moberrorlength2 });

                            }
                        }

                        if (moberror == false && moberrorlength2 == false && moberrorlength == false) {
                            this.handleTabChange('e', this.state.tab + 1);
                            this.setState({ toConfirm: false, viewMobError: moberror, viewMobErrorLength: moberrorlength, viewMobErrorLength2: moberrorlength2 });
                        }
                    } else if (res.response.status === 500) {
                        this.setState({ severity: "error", snakebar: true, message: res.response.data.Message });
                    }
                })


            } else if (this.state.tab == 1) {
                var data = this.state.formdata
                let send_data = { id: data.id, nic_no: data.nic_no, email: data.email, admission_no: data.admission_no };


                if (this.state.formdata.left_school_before_al == "No" && !this.state.formdata.al_batch) {
                    this.setState({ severity: "error", snakebar: true, message: "Please select the A/L Batch or Left School Before A/L option" });
                    return
                }
                MemberRegistrationsService.getMemberDataCheckAdmission(send_data).then((res) => {


                    if (res.status === 200) {
                        if (data.leaving_year == "" || data.leaving_year == null) {
                            leavingYrReq = true
                            this.setState({ leavingYrReq: leavingYrReq });
                        } else {
                            this.handleTabChange('e', this.state.tab + 1);
                            this.setState({ toConfirm: false, leavingYrReq: leavingYrReq });
                        }
                    } else if (res.response.status == 500) {
                        this.setState({ severity: "error", snakebar: true, message: res.response.data.Message });
                    }
                })
            } else {
                this.handleTabChange('e', this.state.tab + 1);
                this.setState({ toConfirm: false, viewMobError: moberror, viewMobErrorLength: moberrorlength, viewMobErrorLength2: moberrorlength2 });
            }
        }
    }

    setdatechange(key, value) {
        var data = this.state.formdata;
        var checkAdmissionYear = false;


        if (key == "date_of_birth") {
            if (value !== null) {
                data[key] = moment(value).format("YYYY-MM-DD");

            } else {
                data[key] = null;
            }


        } else {
            data[key] = moment(value).format("YYYY");
        }
        // if (key == "al_batch") {
        //     data['left_school_before_al'] = "";
        // }
        if (key == "admission_year") {
            checkAdmissionYear = true;
            this.setState({ checkAdmissionYear: checkAdmissionYear })
            data['leaving_year'] = null
        }

        this.setState({ formdata: data });
    }

    handleFormConfirmation = event => {
        // var data = this.state.formdata;
        var bodyFormData = new FormData();
        var successfullySubmitted = false;
        var disableConfirm = true;

        console.log(this.state.formdata)

        bodyFormData.append('name', this.state.formdata.name);
        bodyFormData.append('surname', this.state.formdata.surname);
        bodyFormData.append('full_name', this.state.formdata.full_name);
        bodyFormData.append('name_on_card', this.state.formdata.name_on_card);
        bodyFormData.append('nic_no', this.state.formdata.nic_no);
        bodyFormData.append('contact_no', this.state.formdata.contact_no);
        bodyFormData.append('other_contact_no', this.state.formdata.other_contact_no);
        bodyFormData.append('email', this.state.formdata.email);
        bodyFormData.append('occupation', this.state.formdata.occupation);
        bodyFormData.append('res_number_street', this.state.formdata.res_number_street);
        bodyFormData.append('res_city', this.state.formdata.res_city);
        bodyFormData.append('res_country', this.state.formdata.res_country);
        bodyFormData.append('number_street', this.state.formdata.number_street);
        bodyFormData.append('city', this.state.formdata.city);
        bodyFormData.append('country', this.state.formdata.country);
        bodyFormData.append('date_of_birth', this.state.formdata.date_of_birth);
        bodyFormData.append('district', this.state.formdata.district);
        bodyFormData.append('res_district', this.state.formdata.res_district);
        bodyFormData.append('admission_year', this.state.formdata.admission_year);
        bodyFormData.append('al_batch', this.state.formdata.al_batch);
        bodyFormData.append('leaving_year', this.state.formdata.leaving_year);
        bodyFormData.append('admission_no', this.state.formdata.admission_no);
        bodyFormData.append('photo', this.state.formdata.photo);
        bodyFormData.append('school_leaving_certificate', this.state.formdata.school_leaving_certificate);
        bodyFormData.append('promotional_content', this.state.formdata.promotional_content);
        bodyFormData.append('newsletter_preference', this.state.formdata.newsletter_preference);
        bodyFormData.append('newsletter_type', this.state.formdata.newsletter_type);
        bodyFormData.append('passport_no', this.state.formdata.passport_no);
        bodyFormData.append('left_school_before_al', this.state.formdata.left_school_before_al);
        bodyFormData.append('nationality', this.state.formdata.nationality);
        bodyFormData.append('admitted_grade', this.state.formdata.admitted_grade);
        bodyFormData.append('proposer_id', this.state.proposerdata.id);
        bodyFormData.append('seconder_id', this.state.seconderdata.id);
        this.setState({ waitingforcomplete: true });
        console.log(bodyFormData)
        //Upload files
        let config = {
            onUploadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.setState({ otheruploadprocess: percentCompleted, disableConfirm: disableConfirm })
            }
        }

        MemberRegistrationsService.createMember(bodyFormData, config).then(res => {
            if (res.status == 200) {
                disableConfirm = true;
                successfullySubmitted = true;
                this.setState({ waitingforcomplete: false, message: res.data.message, successfullySubmitted: successfullySubmitted, disableConfirm: disableConfirm })

            } else if (res.status == 422) {
                disableConfirm = false;
                this.setState({ waitingforcomplete: false, message: "Wrong data Set. Email,Nic,Admission No may be already taken", snakebar: true, severity: "error", disableConfirm: disableConfirm })
            } else {
                disableConfirm = false;
                this.setState({ waitingforcomplete: false, message: "Can not create member", snakebar: true, severity: "error", disableConfirm: disableConfirm })
            }
        })
            .catch((error) => {
                console.log("error", error);
            })
    }

    afterConfirmDialog() {
        // this.setState({snakebar: true, severity: "success"})
        setTimeout(() => {
            window.location.reload(false);
        }, 3000);
    }

    handleDialogClose() {//For handle edit dialog
        this.setState({ toConfirm: false })
    }

    showInstructions() {
        this.setState({ instructions: true })
    }

    handleInstuctionsClose() {//For handle edit dialog
        this.setState({ instructions: false })
    }

    goPreviousTab = () => {
        this.scroll();
        this.handleTabChange('e', this.state.tab - 1);
    }

    render() {
        const { classes } = this.props;
        return (
            <div ref={this.myDivToFocus} style={{ marginBottom: 50 }}>
                <div
                    style={{ color: "white", float: "right" }}
                >

                    <Button
                        style={{ color: "white" }}
                        onClick={() => this.props.history.push("/session/signin")}
                    >Back to Log In </Button>

                </div>
                <div>
                    <img
                        className="circle-44 mr-10"
                        src="/assets/images/logonew.png"
                        alt="user"
                        style={{ borderRadius: "50%", float: "left" }}
                    />
                    <div style={{ color: "white" }}>
                        <div>
                            Visakha Vidyalaya Old Girls' Association
                        </div>
                        <div>
                            Membership Portal
                        </div>
                    </div>

                    <Typography variant="h5" component="h5" gutterBottom>
                        VVOGA New Member Registration
                    </Typography>
                </div>
                <Box display={{ xs: 'none', sm: 'block' }}>
                    <Stepper alternativeLabel activeStep={this.state.tab} style={{ backgroundColor: 'transparent' }} connector={<QontoConnector />}>
                        <Step key={"key"} className="customStepper">
                            <StepLabel StepIconComponent={QontoStepIcon}><div><div>Step 1</div><div>Basic Details</div></div></StepLabel>
                        </Step>
                        <Step key={"key"} className="customStepper">
                            <StepLabel StepIconComponent={QontoStepIcon}><div><div>Step 2</div><div>School Details</div></div></StepLabel>
                        </Step>
                        <Step key={"key"} className="customStepper">
                            <StepLabel StepIconComponent={QontoStepIcon}><div><div>Step 3</div><div>Preferences</div></div></StepLabel>
                        </Step>
                        <Step key={"key"} className="customStepper">
                            <StepLabel StepIconComponent={QontoStepIcon}><div><div>Step 4</div><div>Proposer and Seconder</div></div></StepLabel>
                        </Step>
                        <Step key={"key"} className="customStepper">
                            <StepLabel StepIconComponent={QontoStepIcon}><div><div>Step 5</div><div>Confirm</div></div></StepLabel>
                        </Step>
                    </Stepper>
                </Box>

                <div
                    className="pb-10 pt-10 px-0 "
                    role="tabpanel"
                    hidden={this.state.tab !== 0}
                    id={`full-width-tabpanel-${this.state.tab}`}
                >

                    <Paper >
                        <Box p={{ xs: 2, sm: 4 }}>
                            <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                                <Grid container spacing={3} >
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Grid container spacing={3} >
                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextValidator
                                                    className="w-100"
                                                    variant="outlined"
                                                    label="First Name *"
                                                    onChange={this.handleChange}
                                                    size='small'
                                                    name="name"
                                                    value={this.state.formdata.name}
                                                    inputProps={{ maxLength: 100 }}
                                                    validators={["required", 'matchRegexp:^[a-zA-Z ]+$', 'maxStringLength:100']}
                                                    errorMessages={[
                                                        "This field is required",
                                                        "Only alphabetical characters allowed",
                                                        "Enter less than 100 characters"
                                                    ]}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.cssLabel,
                                                            focused: classes.cssFocused,
                                                        },
                                                    }}
                                                    color="inherit"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextValidator
                                                    className="w-100"
                                                    variant="outlined"
                                                    label="Last Name *"
                                                    onChange={this.handleChange}
                                                    size='small'
                                                    name="surname"
                                                    value={this.state.formdata.surname}
                                                    inputProps={{ maxLength: 100 }}
                                                    validators={["required", 'matchRegexp:^[a-zA-Z ]+$', 'maxStringLength:100']}
                                                    errorMessages={[
                                                        "This field is required",
                                                        "Only alphabetical characters allowed",
                                                        "Enter less than 100 characters",
                                                    ]}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.cssLabel,
                                                            focused: classes.cssFocused,
                                                        },
                                                    }}
                                                    color="inherit"

                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12}>
                                                <TextValidator
                                                    className="w-100"
                                                    variant="outlined"
                                                    label="Full Name"
                                                    onChange={this.handleChange}
                                                    size='small'
                                                    name="full_name"
                                                    value={this.state.formdata.full_name}
                                                    inputProps={{ maxLength: 160 }}
                                                    validators={['matchRegexp:^[a-zA-Z ]+$', 'maxStringLength:160']}
                                                    errorMessages={[
                                                        "Only alphabetical characters allowed",
                                                        "Enter less than 100 characters",
                                                    ]}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.cssLabel,
                                                            focused: classes.cssFocused,
                                                        },
                                                    }}
                                                    color="inherit"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <TextValidator
                                                    className="w-100"
                                                    variant="outlined"
                                                    label="Name to be Printed on Membership Card *"
                                                    onChange={this.handleChange}
                                                    size='small'
                                                    name="name_on_card"
                                                    value={this.state.formdata.name_on_card}
                                                    inputProps={{ maxLength: 40 }}
                                                    validators={["required", 'maxStringLength:40', 'matchRegexp:^[a-zA-Z ]+$']}
                                                    errorMessages={[
                                                        "This field is required",
                                                        "Enter less than 40 characters",
                                                        "Enter a valid value"
                                                    ]}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={12}>
                                                <TextField
                                                    className="w-100"
                                                    variant="outlined"
                                                    label="Upload a Clear Face Image for Identification"
                                                    onChange={this.handleUploadChange}
                                                    size='small'
                                                    name="photo"
                                                    type='file'
                                                    accept="image/*"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        accept: "image/*",
                                                    }}
                                                    value={this.state.uploadokprofile ? this.state.formdata.photo_name : this.state.photo_name}

                                                    style={{ paddingBottom: 0 }}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12}>
                                                <FormLabel component="legend">Nationality</FormLabel>
                                                <RadioGroup row aria-label="nationality" name="nationality" defaultValue="Sri Lankan" onChange={this.toggleNationality} >
                                                    <FormControlLabel value="Sri Lankan" control={<Radio color="primary" />} label="Sri Lankan" />
                                                    <FormControlLabel value="Foreign" control={<Radio color="primary" />} label="Foreign" />
                                                </RadioGroup>
                                            </Grid>

                                            {!this.state.slOrNonSl ?
                                                <Grid item xs={12} sm={6} md={6}>
                                                    <TextValidator
                                                        className="w-100"
                                                        variant="outlined"
                                                        label="NIC Number *"
                                                        onChange={this.handleChange}
                                                        size='small'
                                                        name="nic_no"
                                                        value={this.state.formdata.nic_no}
                                                        inputProps={{ maxLength: 12 }}
                                                        validators={["required", 'maxStringLength:12', 'matchRegexp:^([0-9]{9}[x|X|v|V]|[0-9]{12})$']}
                                                        errorMessages={[
                                                            "This field is required",
                                                            "Enter a valid NIC",
                                                            "Enter a valid NIC"
                                                        ]}
                                                    />
                                                </Grid>
                                                : ""}

                                            {this.state.slOrNonSl ?
                                                <Grid item xs={12} sm={6} md={6}>
                                                    <TextValidator
                                                        className="w-100"
                                                        variant="outlined"
                                                        label="Passport Number *"
                                                        onChange={this.handleChange}
                                                        size='small'
                                                        name="passport_no"
                                                        value={this.state.formdata.passport_no}
                                                        validators={["required", 'matchRegexp:(?!^[0-9]*$)(?!^[a-zA-Z]*$)^([a-zA-Z0-9]{6,15})$']}
                                                        errorMessages={[
                                                            "This field is required",
                                                            "Enter a valid Passport Number"
                                                        ]}
                                                    />
                                                </Grid>
                                                : ""}

                                            <Grid item xs={12} sm={6} md={6}>
                                                <MuiPickersUtilsProvider utils={MomentUtils}>

                                                    <KeyboardDatePicker
                                                        className=" w-100"
                                                        autoOk
                                                        emptyLabel='YYYY-MM-DD'
                                                        variant="outlined"
                                                        label="Date of Birth"
                                                        name='date_of_birth'
                                                        inputVariant="outlined"
                                                        value={this.state.formdata.date_of_birth ? moment(this.state.formdata.date_of_birth, 'YYYY-MM-DD').format("YYYY-MM-DD") : null}
                                                        size='small'
                                                        onChange={(value) => this.setdatechange("date_of_birth", value)}
                                                        disableFuture
                                                        views={["year", "month", "date"]}
                                                        openTo="year"
                                                        clearable
                                                        format="yyyy/MM/DD"
                                                        KeyboardButtonProps={{
                                                            disableFocusRipple: true,
                                                            disableRipple: true,
                                                            color: "primary"
                                                        }}
                                                        maxDateMessage="Invalid date"
                                                        minDateMessage="Invalid date"
                                                    />

                                                </MuiPickersUtilsProvider>
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={6}>
                                                <MuiPhoneNumber
                                                    defaultCountry='lk'
                                                    variant="outlined"
                                                    size='small'
                                                    name="contact_no"
                                                    label="Mobile Number *"
                                                    className="w-100"
                                                    disableAreaCodes
                                                    onChange={(value) => this.handleContactNoCountryCodeChange(value)}
                                                    value={this.state.formdata.contact_no}
                                                    validators={["required", 'minStringLength:9']}
                                                    errorMessages={[
                                                        "This field is required",
                                                        "Enter a valid mobile number",
                                                    ]}
                                                />
                                                {this.state.viewMobError ?
                                                    <p style={{ color: "red" }} class="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense">This field is required</p>
                                                    : ""
                                                }
                                                {this.state.viewMobErrorLength ?
                                                    <p style={{ color: "red" }} class="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense">Enter a valid mobile number</p>
                                                    : ""
                                                }
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <MuiPhoneNumber
                                                    defaultCountry='lk'
                                                    variant="outlined"
                                                    size='small'
                                                    name="other_contact_no"
                                                    label="Phone Number"
                                                    disableAreaCodes
                                                    className="w-100"
                                                    onChange={(value) => this.handleOtherContactNoCountryCodeChange(value)}
                                                    value={this.state.formdata.other_contact_no}

                                                    renderInput={(params) => <TextValidator {...params}
                                                        value={this.state.formdata.other_contact_no == null ? "" : this.state.formdata.other_contact_no}
                                                        label="Phone Number"
                                                        validators={['minStringLength:9']}
                                                        errorMessages={[
                                                            "Enter a valid phone number",
                                                        ]}
                                                        variant="outlined" />}
                                                />
                                                {this.state.viewMobErrorLength2 ?
                                                    <p style={{ color: "red" }} class="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense">Enter a valid phone number</p>
                                                    : ""
                                                }
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextValidator
                                                    className="w-100"
                                                    variant="outlined"
                                                    label="Email *"
                                                    onChange={this.handleChange}
                                                    size='small'
                                                    name="email"
                                                    type="email"
                                                    value={this.state.formdata.email}
                                                    validators={["isEmail", "required"]}
                                                    errorMessages={[
                                                        "Enter a valid email address ",
                                                        "This field is required"
                                                    ]}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextValidator
                                                    className="w-100"
                                                    variant="outlined"
                                                    label="Occupation"
                                                    onChange={this.handleChange}
                                                    size='small'
                                                    name="occupation"
                                                    value={this.state.formdata.occupation}
                                                    inputProps={{ maxLength: 100 }}
                                                    validators={this.state.formdata.occupation ? ['maxStringLength:100', 'matchRegexp:^[a-zA-Z ]+$'] : ""}
                                                    errorMessages={[
                                                        "Enter less than 100 characters",
                                                        "Enter a valid value"
                                                    ]}
                                                />
                                            </Grid>
                                            <Button
                                                startIcon={<InfoIcon />}
                                                style={{ color: "lightblue" }}
                                                onClick={() => { this.showInstructions(); }}

                                            >
                                                Instructions
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Grid container spacing={3} >
                                            <Grid item item xs={12} sm={12} md={12}>
                                                <FormLabel component="legend">Country of Residence</FormLabel>
                                                <RadioGroup row aria-label="country_of_resident" name="country_of_resident" defaultValue="srilanka" onChange={this.toggleCountry} >
                                                    <FormControlLabel value="srilanka" control={<Radio color="primary" />} label="Sri Lanka" />
                                                    <FormControlLabel value="out_of_srilanka" control={<Radio color="primary" />} label="Out of Sri Lanka" />
                                                </RadioGroup>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <FormLabel component="legend">Permanent Address</FormLabel>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextValidator
                                                    className="w-100"
                                                    variant="outlined"
                                                    label="Number & Street *"
                                                    onChange={this.handleChange}
                                                    size='small'
                                                    name="res_number_street"
                                                    value={this.state.formdata.res_number_street}
                                                    inputProps={{ maxLength: 100 }}
                                                    validators={["required", 'maxStringLength:100', 'minStringLength:2']}
                                                    errorMessages={[
                                                        "This field is required",
                                                        "Enter less than 100 characters",
                                                        "This field is required",
                                                    ]}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextValidator
                                                    className="w-100"
                                                    variant="outlined"
                                                    label="City *"
                                                    onChange={this.handleChange}
                                                    size='small'
                                                    name="res_city"
                                                    value={this.state.formdata.res_city}
                                                    inputProps={{ maxLength: 50 }}
                                                    validators={["required", 'maxStringLength:50', 'minStringLength:2']}
                                                    errorMessages={[
                                                        "This field is required",
                                                        "Enter less than 50 characters",
                                                        "This field is required",
                                                    ]}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                {this.state.hideDistrict ?
                                                    <TextValidator
                                                        className="w-100"
                                                        variant="outlined"
                                                        label="State"
                                                        onChange={this.handleChange}
                                                        size='small'
                                                        name="res_district"
                                                        value={this.state.formdata.res_district}
                                                        inputProps={{ maxLength: 50 }}
                                                        validators={['maxStringLength:50']}
                                                        errorMessages={[
                                                            "Enter less than 50 characters",
                                                        ]}
                                                    />
                                                    : ""}
                                                {!this.state.hideDistrict ?
                                                    <Autocomplete
                                                        ListboxProps={{ style: { border: "1px solid black" } }}
                                                        className="w-100"
                                                        options={this.state.district}
                                                        getOptionLabel={(option) => option.title}
                                                        size='small'
                                                        name="res_district"
                                                        onChange={(event, value) => this.setdropdowndata("res_district", value)}
                                                        renderInput={(params) => <TextValidator {...params}
                                                            value={this.state.formdata.res_district == null ? "" : this.state.formdata.res_district}
                                                            label="District *" variant="outlined"
                                                            validators={["required"]}
                                                            errorMessages={[
                                                                "This field is required"
                                                            ]}
                                                        />}

                                                    />
                                                    : ""}
                                            </Grid>

                                            {!this.state.hideDistrict ?
                                                <Grid item xs={12} sm={6} md={6}>
                                                    <TextValidator
                                                        className="w-100"
                                                        variant="outlined"
                                                        label="Country *"
                                                        onChange={this.handleChange}
                                                        size='small'
                                                        name="res_country"
                                                        value={this.state.formdata.res_country == null ? "" : this.state.formdata.res_country}
                                                        errorMessages={[
                                                            "This field is required"
                                                        ]}
                                                        disabled={true}
                                                    />
                                                </Grid>
                                                :
                                                <Grid item xs={12} sm={6} md={6}>
                                                    <Autocomplete
                                                        ListboxProps={{ style: { border: "1px solid black" } }}
                                                        className="w-100"
                                                        options={this.state.countries.filter(item => item.title !== "Sri Lanka")}
                                                        getOptionLabel={(option) => option.title}
                                                        size='small'
                                                        name="res_country"
                                                        disabled={!this.state.addressDisable}
                                                        onChange={(event, value) => this.setdropdowndata("res_country", value)}
                                                        renderInput={(params) => <TextValidator {...params}
                                                            value={this.state.formdata.res_country == null ? "" : this.state.formdata.res_country}
                                                            label="Country *" variant="outlined"
                                                            validators={["required"]}
                                                            errorMessages={[
                                                                "This field is required"
                                                            ]}
                                                        />}

                                                    />
                                                </Grid>
                                            }
                                            <Grid item xs={6} sm={12} md={12}>
                                                <FormLabel component="legend">Postal Address</FormLabel>

                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onChange={this.copyAddressData}
                                                            name="cors_address"
                                                            color="primary"
                                                            disabled={!this.state.resCountryDisable}
                                                            checked={this.state.postalDataCheck}
                                                        />
                                                    }
                                                    label="Same as Above"
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12}>
                                                <FormLabel component="legend">Address</FormLabel>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextValidator
                                                    className="w-100"
                                                    variant="outlined"
                                                    label="Number & Street"
                                                    onChange={this.handleChange}
                                                    size='small'
                                                    name="number_street"
                                                    inputProps={{ maxLength: 100 }}
                                                    value={this.state.formdata.number_street}
                                                    disabled={this.state.postalDataDisable}
                                                    validators={this.state.formdata.country == "Sri Lanka" && !this.state.postalDataCheck ? ["required"] : null}
                                                    errorMessages={[
                                                        "This field is required"
                                                    ]}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextValidator
                                                    className="w-100"
                                                    variant="outlined"
                                                    label="City"
                                                    onChange={this.handleChange}
                                                    size='small'
                                                    name="city"
                                                    value={this.state.formdata.city}
                                                    inputProps={{ maxLength: 50 }}
                                                    disabled={this.state.postalDataDisable}
                                                    validators={this.state.formdata.country == "Sri Lanka" && !this.state.postalDataCheck ? ["required"] : null}
                                                    errorMessages={[
                                                        "This field is required"
                                                    ]}
                                                />
                                            </Grid>


                                            <Grid item xs={12} sm={6} md={6}>
                                                {this.state.postalDataCheck || this.state.formdata.country !== "Sri Lanka" ?
                                                    <TextValidator
                                                        className="mb-10 w-100"
                                                        variant="outlined"
                                                        onChange={this.handleChange}
                                                        size='small'
                                                        name="district"
                                                        value={this.state.formdata.district}
                                                        label="District"
                                                        inputProps={{ maxLength: 50 }}
                                                        disabled={this.state.postalDataDisable}
                                                        validators={this.state.formdata.district ? ['maxStringLength:50'] : null}
                                                        errorMessages={[
                                                            "Enter less than 50 characters",
                                                        ]}
                                                    />
                                                    :
                                                    <Autocomplete
                                                        ListboxProps={{ style: { border: "1px solid black" } }}
                                                        className="w-100"
                                                        options={this.state.district}
                                                        getOptionLabel={(option) => option.title}
                                                        size='small'
                                                        name="district"
                                                        onChange={(event, value) => this.setdropdowndata("district", value)}
                                                        renderInput={(params) => <TextValidator {...params}
                                                            value={this.state.formdata.district == null ? "" : this.state.formdata.district}
                                                            label="District" variant="outlined"
                                                            validators={this.state.formdata.country == "Sri Lanka" && !this.state.postalDataCheck ? ["required"] : null}
                                                            errorMessages={[
                                                                "This field is required"
                                                            ]}
                                                        />}
                                                        disabled={this.state.postalDataDisable}

                                                    />
                                                }
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextValidator
                                                    className="w-100"
                                                    variant="outlined"
                                                    label="Country"
                                                    onChange={this.handleChange}
                                                    size='small'
                                                    name="country"
                                                    validators={this.state.formdata.country == "Sri Lanka" && !this.state.postalDataCheck ? ["required"] : null}
                                                    errorMessages={[
                                                        "This field is required"
                                                    ]}
                                                    value={this.state.formdata.country}
                                                    disabled={true}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} style={{ textAlign: "right" }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            endIcon={<ArrowRightIcon />}
                                            type="submit"
                                        >
                                            Next
                                        </Button>
                                    </Grid>
                                </Grid>
                            </ValidatorForm>
                        </Box>
                    </Paper>
                </div>

                <div
                    className="pb-10 pt-10 px-0 "
                    role="tabpanel"
                    hidden={this.state.tab !== 1}
                    id={`full-width-tabpanel-${this.state.tab}`}
                >
                    <Paper >
                        <Box p={{ xs: 2, sm: 4 }}>
                            <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                                <Grid container spacing={3} >
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Grid container spacing={3} >
                                            <Grid item xs={12} sm={12} md={12}>
                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                    <DatePicker
                                                        className="w-100"
                                                        autoOk
                                                        variant="outlined"
                                                        format="yyyy"
                                                        label="Year of Admission to School *"
                                                        name='admission_year'
                                                        variant="inline"
                                                        inputVariant="outlined"
                                                        clearable
                                                        openTo="year"
                                                        views={["year"]}
                                                        emptyLabel=''
                                                        size='small'
                                                        KeyboardButtonProps={{
                                                            disableFocusRipple: true,
                                                            disableRipple: true,
                                                            color: "primary"
                                                        }}
                                                        initialFocusedDate={moment().subtract(2, 'years').format("YYYY")}
                                                        maxDate={moment().subtract(2, 'years').format("YYYY")}
                                                        minDate="1917"
                                                        minDateMessage="Invalid date"
                                                        InputProps={{
                                                            required: true
                                                            , endAdornment: (
                                                                <InputAdornment position={'end'}>

                                                                    <EventIcon style={{ color: "#07367E" }} />

                                                                </InputAdornment>)
                                                        }}

                                                        value={this.state.formdata.admission_year !== null ? moment(this.state.formdata.admission_year, 'YYYY').format("YYYY") : null}
                                                        onChange={(value) => this.setdatechange("admission_year", value)}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <MuiPickersUtilsProvider utils={MomentUtils} className="mb-24">
                                                    <DatePicker
                                                        className="w-100"
                                                        autoOk
                                                        variant="outlined"
                                                        format="yyyy"
                                                        label="Year of Leaving the School *"
                                                        name='leaving_year'
                                                        variant="inline"
                                                        inputVariant="outlined"
                                                        clearable
                                                        views={["year"]}
                                                        emptyLabel=''
                                                        size='small'
                                                        KeyboardButtonProps={{
                                                            disableFocusRipple: true,
                                                            disableRipple: true,
                                                            color: "primary"
                                                        }}
                                                        maxDate={moment().format("YYYY")}
                                                        minDateMessage="Invalid date"
                                                        minDate={this.state.formdata.admission_year !== null ? moment(this.state.formdata.admission_year).add(2, 'years').format("YYYY") : moment(1980).format("YYYY")}
                                                        InputProps={{
                                                            required: true
                                                            , endAdornment: (
                                                                <InputAdornment position={'end'}>

                                                                    <EventIcon style={{ color: "#07367E" }} />

                                                                </InputAdornment>)
                                                        }}
                                                        value={this.state.formdata.leaving_year !== null ? moment(this.state.formdata.leaving_year, 'YYYY').format("YYYY") : null}
                                                        disabled={!this.state.checkAdmissionYear}
                                                        onChange={(value) => this.setdatechange("leaving_year", value)}
                                                        error={this.state.leavingYrReq}
                                                        helperText={this.state.leavingYrReq ? "This field is required" : null}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Autocomplete
                                                    ListboxProps={{ style: { border: "1px solid black" } }}
                                                    className="w-100"
                                                    options={this.state.grades}
                                                    getOptionLabel={(option) => option.title}
                                                    size='small'
                                                    name="admitted_grade"
                                                    onChange={(event, value) => this.setdropdowndata("admitted_grade", value)}
                                                    renderInput={(params) => <TextValidator {...params}
                                                        value={this.state.formdata.admitted_grade == null ? "" : this.state.formdata.admitted_grade}
                                                        label="Grade of Admission to School" variant="outlined" />}
                                                    disabled={!this.state.checkAdmissionYear}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Grid container spacing={3} >
                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextValidator
                                                    className="w-100"
                                                    variant="outlined"
                                                    label="Admission Number *"
                                                    onChange={this.handleChange}
                                                    size='small'
                                                    name="admission_no"
                                                    value={this.state.formdata.admission_no}
                                                    inputProps={{ maxLength: 10 }}
                                                    type="number"
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        "This field is required"
                                                    ]}
                                                    disabled={!this.state.checkAdmissionYear}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <MuiPickersUtilsProvider utils={MomentUtils} className="mb-24">
                                                    <DatePicker
                                                        className="w-100"
                                                        autoOk
                                                        variant="outlined"
                                                        format="yyyy"
                                                        label="A/L Batch"
                                                        name='al_batch'
                                                        variant="inline"
                                                        inputVariant="outlined"
                                                        views={["year"]}
                                                        emptyLabel=''
                                                        size='small'
                                                        KeyboardButtonProps={{
                                                            disableFocusRipple: true,
                                                            disableRipple: true,
                                                            color: "primary"
                                                        }}
                                                        maxDate={moment().format("YYYY")}
                                                        minDateMessage="Invalid date"
                                                        minDate={this.state.formdata.admission_year !== null ? this.state.formdata.admission_year : moment(1980).format("YYYY")}
                                                        value={this.state.formdata.al_batch !== null ? moment(this.state.formdata.al_batch, 'YYYY').format("YYYY") : null}
                                                        onChange={(value) => this.setdatechange("al_batch", value)}
                                                        disabled={!this.state.checkAdmissionYear}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onChange={this.handleChange}
                                                            name="left_school_before_al"
                                                            color="primary"
                                                            checked={this.state.formdata.left_school_before_al == "Yes" ? true : false}
                                                        />
                                                    }
                                                    label="Left School Before A/L"
                                                    disabled={!this.state.checkAdmissionYear}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <TextValidator
                                                    className="w-100"
                                                    variant="outlined"
                                                    label="Upload an Image of Original School Leaving Certificate"
                                                    onChange={this.handleUploadChange}
                                                    size='small'
                                                    name="school_leaving_certificate"
                                                    type='file'
                                                    accept="image/*"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        accept: "image/*"
                                                    }}
                                                    value={this.state.uploadokLeavecertificate ? this.state.formdata.school_leaving_certificate_name : this.state.formdataschool_leaving_certificate_name}
                                                    validators={this.state.uploadokLeavecertificate ? ["required"] : null}
                                                    errorMessages={[
                                                        "This field is required"
                                                    ]}
                                                    disabled={!this.state.checkAdmissionYear}
                                                    helperText="JPG or PNG only"
                                                />

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} style={{ textAlign: "right" }}>
                                        <ColorButton1
                                            variant="contained"
                                            className={classes.button}
                                            startIcon={<ArrowLeftIcon />}
                                            style={{ marginRight: 10 }}
                                            onClick={this.goPreviousTab}
                                        >
                                            Back
                                        </ColorButton1>

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            endIcon={<ArrowRightIcon />}
                                            type="submit"
                                        >
                                            Next
                                        </Button>
                                    </Grid>
                                </Grid>
                            </ValidatorForm>
                        </Box>
                    </Paper>
                </div>

                <div
                    className="pb-10 pt-10 px-0 "
                    role="tabpanel"
                    hidden={this.state.tab !== 2}
                    id={`full-width-tabpanel-${this.state.tab}`}
                >
                    <Paper >
                        <Box p={{ xs: 2, sm: 4 }}>
                            <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                                <Grid container spacing={3} >
                                    <Grid item xs={12} sm={12} md={6}>
                                        <FormControlLabel
                                            label="Newsletter Preference"
                                            control={
                                                <Switch
                                                    checked={this.state.formdata.newsletter_preference == "Yes" ? true : false}
                                                    onChange={this.handleChange}
                                                    name="newsletter_preference"
                                                    color="primary"
                                                />
                                            }
                                        />
                                        {this.state.hideNewsLetter ?
                                            <RadioGroup value={this.state.formdata.newsletter_type} aria-label="newsletter_type" name="newsletter_type" style={{ marginLeft: 20 }} onChange={this.handleChange} >
                                                <FormControlLabel value="By Email" control={<Radio color="primary" />} label="By Email" />
                                                <FormControlLabel disabled={this.state.formdata.country != "Sri Lanka"} value="By Post" control={<Radio color="primary" />} label="By Post" />
                                            </RadioGroup>
                                            : ""}
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>
                                        <FormControlLabel
                                            label="Receive Notifications via Email and SMS"
                                            control={
                                                <Switch
                                                    onChange={this.handleChange}
                                                    name="promotional_content"
                                                    color="primary"
                                                />
                                            }
                                            className="w-100"

                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} style={{ textAlign: "right" }}>
                                        <ColorButton1
                                            variant="contained"
                                            className={classes.button}
                                            startIcon={<ArrowLeftIcon />}
                                            style={{ marginRight: 10 }}
                                            onClick={this.goPreviousTab}
                                        >
                                            Back
                                        </ColorButton1>

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            endIcon={<ArrowRightIcon />}
                                            type="submit"
                                        >
                                            Next
                                        </Button>
                                    </Grid>
                                </Grid>
                            </ValidatorForm>
                        </Box>
                    </Paper>
                </div>

                <div
                    className="pb-10 pt-10 px-0 "
                    role="tabpanel"
                    hidden={this.state.tab !== 3}
                    id={`full-width-tabpanel-${this.state.tab}`}
                >
                    <Paper >
                        <Box p={{ xs: 2, sm: 4 }}>
                            <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                                <Grid container spacing={3} >
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Typography variant="h6" component="h2" gutterBottom>
                                            Add Proposer
                                        </Typography>
                                        <TextValidator
                                            className="w-100"
                                            variant="outlined"
                                            label="Search Proposer by OGA Number"
                                            onChange={this.handleChange}
                                            // onKeyPress={this.getPropser}
                                            // onKeyDown={this.getPropser}
                                            //onKeyUp={this.getPropser}
                                            // onBlur={this.getPropserMobile}
                                            validators={["required"]}
                                            errorMessages={[
                                                "This field is required",
                                            ]}
                                            size='small'
                                            name="search_propser"
                                            value={this.state.formdata.search_propser}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {/* <FormGroup row style={{ marginBottom: 20 }}>
                                                <FormLabel component="legend" style={{ marginRight: 20 }}>Proposer Name :</FormLabel>
                                                {this.state.proposerConfirm ?
                                                    <FormLabel component="legend">{this.state.proposerdata.name} {this.state.proposerdata.surname}</FormLabel>
                                                    : ""}
                                            </FormGroup> */}
                                        {this.state.proposerConfirm ?
                                            <FormLabel component="legend" style={{ color: "teal" }}>Your proposer is qualified to approve you!</FormLabel>
                                            : ""}
                                        {this.state.propserError ?
                                            <Grid item xs={12}>
                                                <Typography style={{ color: "red" }}>
                                                    {this.state.proposer_error_msg}
                                                </Typography>
                                            </Grid>
                                            : ""}
                                        {this.state.emptyProposer ?
                                            <Grid item xs={12}>
                                                <Typography style={{ color: "red" }}>
                                                    Please enter an OGA number
                                                </Typography>
                                            </Grid>
                                            : ""}
                                        {this.state.sameAsSeconder ?
                                            <Grid item xs={12}>
                                                <Typography style={{ color: "red" }}>
                                                    Proposer and Seconder cannot be the same person.
                                                </Typography>
                                            </Grid>
                                            : ""}
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Typography variant="h6" component="h2" gutterBottom>
                                            Add Seconder
                                        </Typography>
                                        <TextValidator
                                            className="w-100"
                                            variant="outlined"
                                            label="Search Seconder by OGA Number"
                                            onChange={this.handleChange}
                                            // onKeyPress={this.getSeconder}
                                            // onKeyDown={this.getSeconder}
                                            // onKeyUp={this.getSeconder}
                                            // onBlur={this.getSeconderMobile}

                                            size='small'
                                            name="search_seconder"
                                            value={this.state.formdata.search_seconder}
                                            // error
                                            // helperText="Required Field"
                                            validators={["required"]}
                                            errorMessages={[
                                                "This field is required",
                                                "Enter Valid No"
                                            ]}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {/* <FormGroup row style={{ marginBottom: 20 }}>
                                                <FormLabel component="legend" style={{ marginRight: 20 }}>Seconder Name :</FormLabel>
                                                {this.state.seconderConfirm ?
                                                    <FormLabel component="legend">{this.state.seconderdata.name} {this.state.seconderdata.surname}</FormLabel>
                                                    : ""}
                                            </FormGroup> */}
                                        {this.state.seconderConfirm ?
                                            <FormLabel component="legend" style={{ color: "teal" }}>Your seconder is qualified to approve you!</FormLabel>
                                            : ""}
                                        {this.state.seconderError ?
                                            <Grid item xs={12}>
                                                <Typography style={{ color: "red" }}>
                                                    {this.state.seconder_error_msg}
                                                </Typography>
                                            </Grid>
                                            : ""}
                                        {this.state.emptySeconder ?
                                            <Grid item xs={12}>
                                                <Typography style={{ color: "red" }}>
                                                    Please enter an OGA number
                                                </Typography>
                                            </Grid>
                                            : ""}
                                        {this.state.sameAsProposer ?
                                            <Grid item xs={12}>
                                                <Typography style={{ color: "red" }}>
                                                    Proposer and Seconder cannot be the same person.
                                                </Typography>
                                            </Grid>
                                            : ""}

                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} style={{ textAlign: "right" }}>
                                        <ColorButton1
                                            variant="contained"
                                            color="inherit"
                                            className={classes.button}
                                            startIcon={<ArrowLeftIcon />}
                                            style={{ marginRight: 10 }}
                                            onClick={this.goPreviousTab}
                                        >
                                            Back
                                        </ColorButton1>

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            endIcon={<ArrowRightIcon />}
                                            type="submit"
                                        //disabled={!this.state.proposerSeconderConfirm}
                                        >
                                            Next
                                        </Button>
                                    </Grid>
                                </Grid>
                            </ValidatorForm>
                        </Box>
                    </Paper>
                </div>

                <div
                    className="pb-10 pt-10 px-0 "
                    role="tabpanel"
                    hidden={this.state.tab !== 4}
                    id={`full-width-tabpanel-${this.state.tab}`}
                >
                    <Paper >
                        <Box p={{ xs: 2, sm: 4 }}>
                            <ValidatorForm ref="form" onSubmit={this.handleFormConfirmation}>
                                <Grid container spacing={3} >
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TableContainer>
                                            <Table className={classes.table} aria-label="caption table">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell align="left" style={{ paddingLeft: 20, fontWeight: 'bold' }}>First Name </TableCell>
                                                        <TableCell align="left">{this.state.formdata.name}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left" style={{ paddingLeft: 20, fontWeight: 'bold' }}>Last Name </TableCell>
                                                        <TableCell align="left">{this.state.formdata.surname}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left" style={{ paddingLeft: 20, fontWeight: 'bold' }}>Full Name </TableCell>
                                                        <TableCell align="left">{this.state.formdata.full_name}</TableCell>
                                                    </TableRow>


                                                    <TableRow>
                                                        <TableCell align="left" style={{ paddingLeft: 20, fontWeight: 'bold' }}>Name to be Printed on Membership Card </TableCell>
                                                        <TableCell align="left">{this.state.formdata.name_on_card}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left" style={{ paddingLeft: 20, fontWeight: 'bold' }}>Nationality </TableCell>
                                                        <TableCell align="left">{this.state.formdata.nationality}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left" style={{ paddingLeft: 20, fontWeight: 'bold' }}>Occupation </TableCell>
                                                        <TableCell align="left">{this.state.formdata.occupation}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left" style={{ paddingLeft: 20, fontWeight: 'bold' }}>Mobile No. </TableCell>
                                                        <TableCell align="left">{this.state.formdata.contact_no}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left" style={{ paddingLeft: 20, fontWeight: 'bold' }}>Phone No. </TableCell>
                                                        <TableCell align="left">{this.state.formdata.other_contact_no}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left" style={{ paddingLeft: 20, fontWeight: 'bold', textDecoration: 'underline' }}>Proposer </TableCell>
                                                        <TableCell align="left"></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left" style={{ paddingLeft: 30, fontWeight: 'bold' }}>OGA Number </TableCell>
                                                        <TableCell align="left">{this.state.proposerdata.oga_number}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left" style={{ paddingLeft: 30, fontWeight: 'bold' }}>Proposer Name </TableCell>
                                                        <TableCell align="left">{this.state.proposerdata.name} {this.state.proposerdata.surname}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left" style={{ paddingLeft: 20, fontWeight: 'bold', textDecoration: 'underline' }}>Seconder </TableCell>
                                                        <TableCell align="left"></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left" style={{ paddingLeft: 30, fontWeight: 'bold' }}>OGA Number </TableCell>
                                                        <TableCell align="left">{this.state.seconderdata.oga_number}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left" style={{ paddingLeft: 30, fontWeight: 'bold' }}>Seconder Name </TableCell>
                                                        <TableCell align="left">{this.state.seconderdata.name} {this.state.seconderdata.surname}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left" style={{ paddingLeft: 20, fontWeight: 'bold' }}>Picture </TableCell>
                                                        {this.state.formdata.photo ?
                                                            <TableCell align="left"><CheckCircleIcon style={{ color: indigo[800] }}></CheckCircleIcon></TableCell>
                                                            :
                                                            <TableCell align="left"><CancelIcon></CancelIcon></TableCell>
                                                        }
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TableContainer>
                                            <Table className={classes.table} aria-label="caption table">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell align="left" style={{ paddingLeft: 20, fontWeight: 'bold' }}>Permanent Residence</TableCell>
                                                        <TableCell align="left">{this.state.formdata.res_number_street + " " + this.state.formdata.res_city + " " + this.state.formdata.res_district + " " + this.state.formdata.res_country}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left" style={{ paddingLeft: 20, fontWeight: 'bold' }}>Postal Address</TableCell>
                                                        <TableCell align="left">{this.state.formdata.number_street + " " + this.state.formdata.city + " " + this.state.formdata.district + " " + this.state.formdata.country}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left" style={{ paddingLeft: 20, fontWeight: 'bold' }}>NIC/Passport No. </TableCell>
                                                        <TableCell align="left">{this.state.formdata.nic_no}{this.state.formdata.passport_no}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left" style={{ paddingLeft: 20, fontWeight: 'bold' }}>Email Address</TableCell>
                                                        <TableCell align="left">{this.state.formdata.email}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left" style={{ paddingLeft: 20, fontWeight: 'bold' }}>Date of Birth</TableCell>
                                                        <TableCell align="left">{this.state.formdata.date_of_birth}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left" style={{ paddingLeft: 20, fontWeight: 'bold' }}>Newsletter Preference</TableCell>
                                                        <TableCell align="left">{this.state.formdata.newsletter_preference == "Yes" ? "Yes - " + this.state.formdata.newsletter_type : "No"}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left" style={{ paddingLeft: 20, fontWeight: 'bold' }}>Receive Notifications via Email and SMS</TableCell>
                                                        <TableCell align="left">{this.state.formdata.promotional_content == "Yes" ? "Yes" : "No"}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left" style={{ paddingLeft: 20, fontWeight: 'bold' }}>Year of Admission to School</TableCell>
                                                        <TableCell align="left">{this.state.formdata.admission_year}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left" style={{ paddingLeft: 20, fontWeight: 'bold' }}>Admission No.</TableCell>
                                                        <TableCell align="left">{this.state.formdata.admission_no}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left" style={{ paddingLeft: 20, fontWeight: 'bold' }}>Left School Before A/L</TableCell>
                                                        <TableCell align="left">{this.state.formdata.left_school_before_al == "Yes" ? "Yes" : "No"}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left" style={{ paddingLeft: 20, fontWeight: 'bold' }}>A/L Batch</TableCell>
                                                        <TableCell align="left">{this.state.formdata.al_batch}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left" style={{ paddingLeft: 20, fontWeight: 'bold' }}>Year of Leaving the School</TableCell>
                                                        <TableCell align="left">{this.state.formdata.leaving_year}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left" style={{ paddingLeft: 20, fontWeight: 'bold' }}>Grade of Admission to School</TableCell>
                                                        <TableCell align="left">{this.state.formdata.admitted_grade}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left" style={{ paddingLeft: 20, fontWeight: 'bold' }}>Original School Leaving Certificate</TableCell>
                                                        {this.state.formdata.school_leaving_certificate ?
                                                            <TableCell align="left"><CheckCircleIcon style={{ color: indigo[800] }}></CheckCircleIcon></TableCell>
                                                            :
                                                            <TableCell align="left"><CancelIcon></CancelIcon></TableCell>
                                                        }
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                    {/* <CircularProgress variant="determinate" value={this.state.otheruploadprocess} /> */}
                                    {/* <LinearProgress variant="determinate" value={this.state.otheruploadprocess} className="mt-24 mb-24" /> */}

                                    <Grid item xs={12} sm={12} md={12}>
                                        <FormControlLabel
                                            style={{ color: "#615E80" }}
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={this.state.agree}
                                                    onClick={(e) => this.setState({
                                                        agree: !this.state.agree
                                                    })}

                                                />
                                            }
                                            label="I hereby agree to abide by the constitution of the VVOGA and also agree to accept any changes / amendments that are brought upon it."
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} style={{ textAlign: "right" }}>
                                        <ColorButton1
                                            variant="contained"
                                            color="inherit"
                                            className={classes.button}
                                            startIcon={<ArrowLeftIcon />}
                                            style={{ marginRight: 10 }}
                                            onClick={this.goPreviousTab}
                                        >
                                            Back
                                        </ColorButton1>

                                        <ColorButton2
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            startIcon={<HowToRegIcon />}
                                            type="submit"
                                            disabled={
                                                !this.state.agree ||
                                                this.state.disableConfirm
                                            }
                                        >
                                            Confirm
                                        </ColorButton2>
                                    </Grid>
                                </Grid>
                            </ValidatorForm>
                        </Box>
                    </Paper>
                </div>

                <Dialog fullWidth={true} onClose={() => { this.handleDialogClose(); }} aria-labelledby="customized-dialog-title" open={this.state.toConfirm}>
                    <div className="pb-30 px-30 w-90">
                        <DialogTitle id="customized-dialog-title" align="center">
                            Important
                        </DialogTitle>
                        <Grid item >
                            <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>

                                <Typography gutterBottom align="center" className="pb-30">
                                    Please inform your proposer and seconder to log in to the system and give their approval.
                                </Typography>
                                <Box textAlign='right'>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        endIcon={<ArrowRightIcon />}
                                        type="submit"
                                        align="right"
                                    >
                                        Next
                                    </Button>
                                </Box>
                            </ValidatorForm>
                        </Grid>
                    </div>
                </Dialog>

                <Dialog fullWidth={true} aria-labelledby="customized-dialog-title" open={this.state.successfullySubmitted} className="p-24">
                    <Alert severity="success" className="m-24" variant="filled">
                        <strong>Thank you for submitting the application to join VVOGA. Once the verification process is completed, you will get an email in order to proceed with the membership payment. This will take 1-2 weeks.</strong>
                    </Alert>
                    <Box textAlign='center' className="ml-24 mr-24 mb-24">
                        <Button
                            variant="contained"
                            color="default"
                            className={classes.button}
                            align="right"
                            onClick={() => this.props.history.push("/session/signin")}
                        >
                            OK
                        </Button>
                    </Box>
                </Dialog>

                <Dialog fullWidth={true} aria-labelledby="Please Wait" open={this.state.otheruploadprocess === 0 ? false : this.state.otheruploadprocess === Infinity ? false : this.state.otheruploadprocess === 100 ? false : true}>
                    {/* <Dialog fullWidth={true} aria-labelledby="Please Wait" open={true}> */}
                    <div className="pb-24 pt-24 px-48 w-90">

                        <Typography variant="h6" display="block" gutterBottom >PLEASE WAIT......</Typography>

                        <LinearProgress style={{ borderRadius: 5, height: 15 }} variant="determinate" value={this.state.otheruploadprocess} />

                        <div style={{ float: "right" }}>
                            {this.state.otheruploadprocess}%

                        </div>
                    </div>
                </Dialog>

                <Dialog fullWidth={true} maxWidth="md" open={this.state.instructions}>
                    {/* <Dialog fullWidth={true} maxWidth="md" open={false}> */}
                    <div className="pb-10 pt-10 px-10 w-90">
                        <Alert severity="info" >
                            <div className={classes.root}>
                                <Typography variant="h6" style={{ fontWeight: "bolder" }} gutterBottom>Please read the following instructions carefully.</Typography>
                                <ul>
                                    <li className="mb-8">To be eligible to apply for VVOGA membership, you must be a student of Visakha Vidyalaya for a period of not less than two academic years.</li>
                                </ul>
                                <Typography variant="button" display="block" style={{ fontWeight: "bolder" }} gutterBottom>WHAT DOCUMENTS WILL YOU NEED?</Typography>
                                <ul>
                                    <li className="mb-8">Clear image of the ORIGINAL leaving certificate (JPG or PNG only)</li>
                                    <li className="mb-8">Clear face image of yourself (JPG or PNG only)</li>
                                </ul>
                                <Typography variant="button" display="block" style={{ fontWeight: "bolder" }} gutterBottom>PROCESS</Typography>
                                <ul>
                                    <li className="mb-8">Please double check all your personal details before you confirm.</li>
                                    <li className="mb-8">After entering your personal details, you need to get the approval from the Proposer and Seconder. They should be TWO PAST VVOGA MEMBERS WHO HAVE FIVE OR MORE YEARS OF VVOGA MEMBERSHIP. (Please obtain the consent and the OGA membership numbers from the identified Proposer and Seconder before you begin the application process.)</li>
                                    <li className="mb-8">Once you enter the names of your Proposer and Seconder, the system will send them notifications via the VVOGA membership portal. Please ask your Proposer and Seconder to log in to the system and give their approvals.</li>
                                    <li className="mb-8">After the submission of the application, the school leaving certificate will be verified from the school office by the Convener/ Co-Convener of the new members subcommittee and you will receive an email requesting you to proceed with the payment of the membership fee.</li>
                                    <li className="mb-8">Payment can be made via the payment gateway. You will receive the link via the same email.</li>
                                    <li className="mb-8">After the payment, your application will be tabled for approval at the Ex-Co meeting and will be certified by the VVOGA Secretaries.</li>
                                    <li className="mb-8">You will receive your printed VVOGA card by post. (Please note that this process will take about 2 months)</li>
                                    <li className="mb-8">Members applying from abroad, please contact the VVOGA secretariat two months after applying to make sure your OGA membership card is available. You can send an authorization letter to someone known to you to collect your VVOGA membership card from the VVOGA secretariat.</li>
                                </ul>
                            </div>
                            <Box textAlign='right'>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    endIcon={<CheckIcon />}
                                    onClick={() => { this.handleInstuctionsClose(); }}
                                >
                                    OK
                                </Button>
                            </Box>
                        </Alert>
                    </div>
                </Dialog>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.snakebar} autoHideDuration={6000} onClose={this.handleClose}>
                    <Alert onClose={this.handleClose} severity={this.state.severity} variant="filled">
                        {this.state.message}
                    </Alert>
                </Snackbar>

                <Dialog fullWidth={true} aria-labelledby="Please Wait" open={this.state.waitingforcomplete}>
                    <div className="pb-30 pt-10 px-10" align="center">
                        <div class="loading" >
                            <p> Please Wait ...</p>
                            <span><i></i><i></i></span>
                        </div>
                    </div>
                </Dialog>
            </div >
        );
    };
}
AllMember.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles, { withTheme: true })(
    withRouter(AllMember)
);
