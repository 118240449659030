import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const AddProject = MatxLoadable({
  loader: () => import("./AddProject")
})
const ViewProject = MatxLoadable({
    loader: () => import("./ViewProject")
  })
  const SingleView = MatxLoadable({
    loader: () => import("./SingleView")
  })
  

const ProjectRoutes = [
  {
    path: "/createproject",
    component: AddProject,
    auth: authRoles.Projects,
  },
  {
    path: "/viewproject",
    component: ViewProject,
    auth: authRoles.Projects,
  },
  {
    path: "/viewprojectSingle/:id",
    component: SingleView,
    auth: authRoles.Projects,
  }
];

export default ProjectRoutes;
