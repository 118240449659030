import axios from 'axios';
import * as appconst from './../appconst';
import localStorageService from "./localStorageService";

class MemberRegistrationService {
    ls = window.localStorage

    createMember = async(data, config) => { //Create new member
        const a = new Promise((resolve, reject) => {
            axios.post(appconst.MEMBER, data, config).then(res => {
                    console.log("res", res);
                    return resolve(res);
                })
                .catch((error, res) => {
                    console.log("error", error);
                    return resolve(error, res);
                })
        });
        return await a;
    }



    updateOGA = async(data) => {
        const a = new Promise((resolve, reject) => {
            axios.put(appconst.MEMBER + "/oga_update", data).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error);
                })
        });
        return await a;
    }

    updateMember = async(data, memberid) => { //Create new member
        const a = new Promise((resolve, reject) => {
            axios.put(appconst.MEMBER + "/all/" + memberid, data).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error);
                })
        });
        return await a;
    }

    updateOGAMember = async(data, config) => { //Create new member
        const a = new Promise((resolve, reject) => {
            axios.post(appconst.MEMBERUPDATE, data, config).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }


    updateUserDetails = async(data, memberid) => { //Create new member
        const a = new Promise((resolve, reject) => {
            axios.put(appconst.MEMBER + "/reset/" + memberid, data).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error);
                })
        });
        return await a;
    }

    updateMemberStatus = async(data, memberid) => { //Create new member
        const a = new Promise((resolve, reject) => {
            axios.put(appconst.MEMBER + "/status/" + memberid, data).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    getMemberByOGA = async(ogaNo) => { //Get member by oga
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MEMBER + "/byEmail/email," + ogaNo)
                .then(res => {
                    return resolve(res.data.Member);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    getMemberByOGAForProposerSeconder = async(ogaNo) => { //Get member by oga
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MEMBER + "/byEmail/email," + ogaNo)
                .then(res => {
                    return resolve(res.data);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    getMemberById = async(id) => { //Get member by oga
        const token = await localStorageService.getItem("auth_user");

        let btoken = "null";
        if (token !== null) {
            btoken = token.token
        }
        const headers = {

            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${btoken}`
            }
        };

        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MEMBER + "/" + id, headers)
                .then(res => {
                    return resolve(res.data.Member);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }
    getUserMemberstoReset = async(id) => {
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MEMBER + "/reset/" + id)
                .then(res => {
                    return resolve(res.data.Member);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }
    getEmailSend = async(data, type) => {
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MEMBER + "/send_email/" + data + "/" + type)
                .then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    getMemberByOGANOOnly = async(ogaNo) => { //Get member by ogano not checking the joiningdate
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MEMBER + "/byOgaNumber/" + ogaNo)
                .then(res => {
                    return resolve(res.data.Member);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }


    getPendingMember = async(ogaNo) => { //Get member by oga
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MEMBER)
                .then(res => {
                    return resolve(res.data.Member);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    getCurrentMember = async(params) => {
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MEMBER + "/current/filtered", headers).then(res => {
                    return resolve(res.data.Member);

                })
                .catch((error) => {
                    return resolve(error);
                    // console.log("error", error);
                })
        });
        return await a;
    }
    getCurrentMemberwithpagination = async(params, pagenumber) => {
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },

            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MEMBER + "/current/filtered?page=" + pagenumber, headers).then(res => {
                    return resolve(res.data.Member);

                })
                .catch((error) => {
                    return resolve(error);
                    // console.log("error", error);
                })
        });
        return await a;
    }

    getPendingMembers = async(page, params) => {
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MEMBER + "/pending/filtered?page=" + page, headers).then(res => {
                    return resolve(res.data.Member);

                })
                .catch((error) => {
                    return resolve(error);
                    // console.log("error", error);
                })
        });
        return await a;
    }
    getPendingMemberswithpagination = async(params, pagenumber) => {
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },

            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MEMBER + "/pending/filtered?page=" + pagenumber, headers).then(res => {
                    return resolve(res.data.Member);

                })
                .catch((error) => {
                    return resolve(error);
                    // console.log("error", error);
                })
        });
        return await a;
    }

    getPendingApprovedMember = async(page, params) => {
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MEMBER + "/pending/approvedfiltered?page=" + page, headers).then(res => {
                    return resolve(res.data.Member);

                })
                .catch((error) => {
                    return resolve(error);
                    // console.log("error", error);
                })
        });
        return await a;
    }
    getPendingApprovedMemberwithpagination = async(params, pagenumber) => {
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MEMBER + "/pending/approvedfiltered?page=" + pagenumber, headers).then(res => {
                    return resolve(res.data.Member);

                })
                .catch((error) => {
                    return resolve(error);
                    // console.log("error", error);
                })
        });
        return await a;
    }

    getPendingRejectedMember = async(page, params) => {
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MEMBER + "/pending/rejectedfiltered?page=" + page, headers).then(res => {
                    return resolve(res.data.Member);

                })
                .catch((error) => {
                    return resolve(error);
                    // console.log("error", error);
                })
        });
        return await a;
    }

    getPendingRejectedMemberwithpagination = async(params, pagenumber) => {
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MEMBER + "/pending/rejectedfiltered?page=" + pagenumber, headers).then(res => {
                    return resolve(res.data.Member);

                })
                .catch((error) => {
                    return resolve(error);
                    // console.log("error", error);
                })
        });
        return await a;
    }
    getCurrentMemberSingle = async(params) => {
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MEMBER + "/search/filtered", headers).then(res => {
                    return resolve(res.data.Member);

                })
                .catch((error) => {
                    return resolve(error);
                    // console.log("error", error);
                })
        });
        return await a;
    }
    getCurrentMemberSinglewithpagination = async(params, pagenumber) => {
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MEMBER + "/search/filtered?page=" + pagenumber, headers).then(res => {
                    return resolve(res.data.Member);

                })
                .catch((error) => {
                    return resolve(error);
                    // console.log("error", error);
                })
        });
        return await a;
    }

    getCurrentMemberSingleRejected = async(params) => {
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MEMBER + "/search/rejectfiltered", headers).then(res => {
                    return resolve(res.data.Member);

                })
                .catch((error) => {
                    return resolve(error);
                    // console.log("error", error);
                })
        });
        return await a;
    }

    getCurrentMemberSingleRejectedwithpagination = async(params, pagenumber) => {
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MEMBER + "/search/rejectfiltered?page=" + pagenumber, headers).then(res => {
                    return resolve(res.data.Member);

                })
                .catch((error) => {
                    return resolve(error);
                    // console.log("error", error);
                })
        });
        return await a;
    }

    getCurrentMemberSingleApproved = async(params) => {
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MEMBER + "/pendingsearch/approvefiltered", headers).then(res => {
                    return resolve(res.data.Member);

                })
                .catch((error) => {
                    return resolve(error);
                    // console.log("error", error);
                })
        });
        return await a;
    }

    getCurrentMemberSingleApprovedwithpagination = async(params, pagenumber) => {
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MEMBER + "/pendingsearch/approvefiltered?page=" + pagenumber, headers).then(res => {
                    return resolve(res.data.Member);

                })
                .catch((error) => {
                    return resolve(error);
                    // console.log("error", error);
                })
        });
        return await a;
    }


    getPendingCurrentMemberSingle = async(params) => {
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MEMBER + "/pendingsearch/filtered", headers).then(res => {
                    return resolve(res.data.Member);

                })
                .catch((error) => {
                    return resolve(error);
                    // console.log("error", error);
                })
        });
        return await a;
    }

    getPendingCurrentMemberSinglewithpagination = async(params, pagenumber) => {
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MEMBER + "/pendingsearch/filtered?page=" + pagenumber, headers).then(res => {
                    return resolve(res.data.Member);

                })
                .catch((error) => {
                    return resolve(error);
                    // console.log("error", error);
                })
        });
        return await a;
    }

    getPendingCurrentMemberSingleRejected = async(params) => {
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MEMBER + "/pendingsearch/rejectfiltered", headers).then(res => {
                    return resolve(res.data.Member);

                })
                .catch((error) => {
                    return resolve(error);
                    // console.log("error", error);
                })
        });
        return await a;
    }

    getPendingCurrentMemberSingleRejectedwithpagination = async(params, pagenumber) => {
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MEMBER + "/pendingsearch/rejectfiltered?page=" + pagenumber, headers).then(res => {
                    return resolve(res.data.Member);

                })
                .catch((error) => {
                    return resolve(error);
                    // console.log("error", error);
                })
        });
        return await a;
    }


    getPendingCurrentMemberSingleReturned = async(params) => {
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MEMBER + "/search/returnfiltered", headers).then(res => {
                    return resolve(res.data.Member);

                })
                .catch((error) => {
                    return resolve(error);
                    // console.log("error", error);
                })
        });
        return await a;
    }

    getPendingCurrentMemberSingleReturnedwithpagination = async(params, pagenumber) => {
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MEMBER + "/search/returnfiltered?page=" + pagenumber, headers).then(res => {
                    return resolve(res.data.Member);

                })
                .catch((error) => {
                    return resolve(error);
                    // console.log("error", error);
                })
        });
        return await a;
    }

    getPendingMemberForPayment = async(params) => { //Get member for membership payment

        const headers = {

            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MEMBER + "/search/formembershippayment", headers)
                .then(res => {
                    return resolve(res.data.Member);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }


    changePicture = async(data, id) => {
        const a = new Promise((resolve, reject) => {
            axios.post(appconst.MEMBER + "/chnagePicture/" + id, data).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }


    getAllInformations = async(params, pagenumber) => {

        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                
                'Authorization': `Bearer ${token.token}`
            },
            timeout: 100000,
            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.INFROMATION_ALL + "?page=" + pagenumber, headers).then(res => {
                    return  resolve(res.data.Member);
                })
                .catch((error) => {
                    return resolve(error);
                })
        });
        return await a;
    }

    searchInformations = async(params, pagenumber) => {
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.INFORMATION_SEARCH + "?page=" + pagenumber, headers).then(res => {
                    return resolve(res.data.Member);
                })
                .catch((error) => {
                    return resolve(error);
                })
        });
        return await a;
    }

    getAlBatch = async() => {

        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            }
        };

        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MEMBER + "/al/batch", headers).then(res => {
                    return resolve(res.data.Member);
                })
                .catch((error) => {
                    return resolve(error);
                })
        });
        return await a;
    }


    getMemberDataCheck = async(params) => {

        const headers = {

            params: params
        };

        const a = new Promise((resolve, reject) => {
            axios.get(appconst.CHECKMEMBERDATA + "/emailnicpassport", headers).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error);
                })
        });
        return await a;
    }


    getMemberDataCheckAdmission = async(params) => {

        const headers = {

            params: params
        };

        const a = new Promise((resolve, reject) => {
            axios.get(appconst.CHECKMEMBERDATA + "/admission", headers).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error);
                })
        });
        return await a;
    }


    getMemberDataCheckForUpdate = async(params) => {

        const headers = {

            params: params
        };

        const a = new Promise((resolve, reject) => {
            axios.get(appconst.CHECKMEMBERDATA + "/emailnicpassportforupdate", headers).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error);
                })
        });
        return await a;
    }


    getMemberDataCheckAdmissionForUpdate = async(params) => {

        const headers = {

            params: params
        };

        const a = new Promise((resolve, reject) => {
            axios.get(appconst.CHECKMEMBERDATA + "/admissionforupdate", headers).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error);
                })
        });
        return await a;
    }



    checkMemberEligibiltiyForProposerandSeconder = async(params) => { //Get member by oga
        const headers = {

            params: params
        };

        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MEMBER + "checkelgibility", headers)
                .then(res => {
                    return resolve(res);
                })
                .catch((error, res) => {
                    return resolve(error, res);
                })
        });
        return await a;
    }
}
export default new MemberRegistrationService();