import RoleFunctionsService from "./../services/RoleFunctionsService";


export const authRoles2 = {
    sa: ['Super Admin', 'Secretary'], // Only Super Admin has access
    admin: ['Super Admin', 'Admin', 'Secretary'], // Only SA & Admin has access

    dashboard: ['Developer', 'Super Admin', 'Secretary', 'Member', 'New Members Subcommittee Convener and Co-Convener', 'Exco Member', 'Treasurer and Assistant Treasurer'],
    Email_Setting: ['Developer', 'Super Admin', 'Secretary'],
    Payment_Setting: ['Developer', 'Super Admin', 'Secretary'],
    Message_Setting: ['Developer', 'Super Admin', 'Secretary'],
    member: ['Developer', 'Super Admin', 'Secretary', 'Member', 'New Members Subcommittee Convener and Co-Convener', 'Exco Member', 'Treasurer and Assistant Treasurer'],
    all: ['Secretary', 'Developer', 'Super Admin', 'New Members Subcommittee Convener and Co-Convener', 'Exco Member', 'Member', 'Treasurer and Assistant Treasurer'],
    memberapproval: ['Developer', 'Super Admin', 'Secretary', 'New Members Subcommittee Convener and Co-Convener', 'Exco Member', 'Treasurer and Assistant Treasurer'],
    Manage_Event: ['Developer']
}



export function authRoles() {

    let roless = {
        sa: ['Super Admin', 'Admin', 'Secretary'], // Only Super Admin has access
        admin: ['Super Admin', 'Admin', 'Secretary'], // Only SA & Admin has access
        dashboard: [],
        OGA_Membership: [],
        Membership_Roles: [],
        Ex_Co_Members: [],
        Projects: [],
        Make_a_Payment: [],
        Payments_Reports: [],
        Newsletters: [],
        Memebership_Communication: [],
        Logs: [],
        datasetup: ['Developer', 'Super Admin', 'Secretary'],
        Secretary_Setting: ['Developer', 'Super Admin', 'Secretary', 'Treasurer and Assistant Treasurer'],
        Payment_Setting: ['Developer', 'Super Admin', 'Treasurer and Assistant Treasurer'],
        member: ['Developer', 'Super Admin', 'Secretary'],
        all: ['Secretary', 'Developer', 'Super Admin'],
        memberapproval: ['Developer', 'Super Admin'],
        adminchat: ['Developer', 'Super Admin', 'Secretary', 'Treasurer and Assistant Treasurer'],
        Manage_Event: ['Developer']
    }
    let themes = {};

    let roles;

    RoleFunctionsService.getAllfunctions().then((res) => {
        if (res === 500) {
            this.getAll()
        } else {
            res.forEach(element => {
                let name = element.name;
                let frole = [];
                element.role_functions.forEach(elements => {
                    frole.push(elements.roles.name)
                });
                element.allroles = frole;

            });

            if (res.find(dbr => dbr.name === "dashboard")) {
                roless.dashboard = res.filter(dbr => dbr.name === "dashboard")[0].allroles;
            }

            if (res.find(dbr => dbr.name === "OGA_Membership")) {
                roless.OGA_Membership = res.filter(dbr => dbr.name === "OGA_Membership")[0].allroles;
            }
            if (res.find(dbr => dbr.name === "Ex_Co_Members")) {
                roless.Ex_Co_Members = res.filter(dbr => dbr.name === "Ex_Co_Members")[0].allroles;
            }
            if (res.find(dbr => dbr.name === "Projects")) {
                roless.Projects = res.filter(dbr => dbr.name === "Projects")[0].allroles;
            }

            if (res.find(dbr => dbr.name === "Make_a_Payment")) {
                roless.Make_a_Payment = res.filter(dbr => dbr.name === "Make_a_Payment")[0].allroles;
            }

            if (res.find(dbr => dbr.name === "Payments_Reports")) {
                roless.Payments_Reports = res.filter(dbr => dbr.name === "Payments_Reports")[0].allroles;
            }

            if (res.find(dbr => dbr.name === "Newsletters")) {
                roless.Newsletters = res.filter(dbr => dbr.name === "Newsletters")[0].allroles;
            }

            if (res.find(dbr => dbr.name === "Memebership_Communication")) {
                roless.Memebership_Communication = res.filter(dbr => dbr.name === "Memebership_Communication")[0].allroles;
            }

            if (res.find(dbr => dbr.name === "Logs")) {
                roless.Logs = res.filter(dbr => dbr.name === "Logs")[0].allroles;
            }

            if (res.find(dbr => dbr.name === "datasetup")) {
                roless.datasetup = res.filter(dbr => dbr.name === "datasetup")[0].allroles;
            }

            if (res.find(dbr => dbr.name === "Email_Setting")) {
                roless.Secretary_Setting = res.filter(dbr => dbr.name === "Email_Setting")[0].allroles;
            }
            if (res.find(dbr => dbr.name === "Message_Setting")) {
                roless.Secretary_Setting = res.filter(dbr => dbr.name === "Message_Setting")[0].allroles;
            }
            if (res.find(dbr => dbr.name === "Payment_Setting")) {
                roless.Payment_Setting = res.filter(dbr => dbr.name === "Payment_Setting")[0].allroles;
            }
            if (res.find(dbr => dbr.name === "Manage_Event")) {
                roless.Manage_Event = res.filter(dbr => dbr.name === "Manage_Event")[0].allroles;
            }
            if (res.find(dbr => dbr.name === "all")) {
                roless.all = res.filter(dbr => dbr.name === "all")[0].allroles;
            }

            roles = res;


        }
    })
    return roless;
}



// Check out app/views/dashboard/DashboardRoutes.js
// Only SA & Admin has dashboard access

// const dashboardRoutes = [
//   {
//     path: "/dashboard/analytics",
//     component: Analytics,
//     auth: authRoles.admin <----------------
//   }
// ];