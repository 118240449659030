import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const settings = {
    activeLayout: "layout1",
    layout1Settings: {
        topbar: {
            show: false
        },
        leftSidebar: {
            show: false,
            mode: "close"
        }
    },
    layout2Settings: {
        mode: "full",
        topbar: {
            show: false
        },
        navbar: { show: false }
    },
    secondarySidebar: { show: false },
    footer: { show: false }
};

const InternalPayment = MatxLoadable({
    loader: () =>
        import ("./InternalPayment")
})

const Payment = MatxLoadable({
    loader: () =>
        import ("./Payment")
})

const PaymentRecept = MatxLoadable({
    loader: () =>
        import ("./PaymentRecept")
})

const AllMemberPayment = MatxLoadable({
    loader: () =>
        import ("./AllMemberPayment")
})
const PaymentReceptinternal = MatxLoadable({
    loader: () =>
        import ("./PaymentReceptinternal")
})
const AllPayementReport = MatxLoadable({
    loader: () =>
        import ("./AllPayementReport")
})
const MemberPayementReport = MatxLoadable({
    loader: () =>
        import ("./MemberPayementReport")
})
const DuplicatePayementReport = MatxLoadable({
    loader: () =>
        import ("./DuplicatePayementReport")
})
const DonationSponsorshipPaymentreport = MatxLoadable({
    loader: () =>
        import ("./DonationSponsorshipPaymentreport")
})
const FailedPayementReport = MatxLoadable({
    loader: () =>
        import ("./FailedPayementReport")
})

const PaymentRoutes = [{
        path: "/payment",
        component: InternalPayment,
        auth: authRoles.Make_a_Payment,
    },
    {
        path: "/external-payment",
        component: Payment,
        settings
    },
    {
        path: "/payment-recept",
        component: PaymentRecept,
        settings
    },
    {
        path: "/payment-recept2",
        component: PaymentReceptinternal,
        settings
    },
    {
        path: "/membership-payment",
        component: AllMemberPayment,
        settings
    },
    {
        path: "/all-payment-report",
        component: AllPayementReport,
        auth: authRoles.Payments_Reports,
    },
    {
        path: "/member-payment-report",
        component: MemberPayementReport,
        auth: authRoles.Payments_Reports,
    },
    {
        path: "/duplicatecard-payment-report",
        component: DuplicatePayementReport,
        auth: authRoles.Payments_Reports,
    },
    {
        path: "/donation-payment-report",
        component: DonationSponsorshipPaymentreport,
        auth: authRoles.Payments_Reports,
    },
    {
        path: "/failed-payment-report",
        component: FailedPayementReport,
        auth: authRoles.Payments_Reports,
    }

    
    

    

];

export default PaymentRoutes;