import React from "react";
import { Redirect } from "react-router-dom";

import dashboardRoutes from "./views/dashboard/DashboardRoutes";
import sessionRoutes from "./views/sessions/SessionRoutes";
import UserRoutes from "./views/DataSetup/Users/UserRoutes";
import NotificationRoutes from "./views/Notifications/NotificationRoutes";
import MemberRoutes from "./views/Member/MemberRoutes";
import PasswordResetRoutes from "./views/PasswordReset/PasswordResetRoute";
import InternalMemberRoutes from "./views/InternalMember/InternalMemberRoutes";
import PaymentRoutes from "./views/Payment/PaymentRoutes";
import ProjectRoutes from "./views/Projects/ProjectRoutes";
import MasterSettingsRoutes from "./views/MasterSettings/MasterSettingsRoutes";
import ExcoRoutes from "./views/EXCO/ExcoRoutes";
import MemberApprovalRoutes from "./views/MemberApproval/MemberApprovalRoutes";
import ProposerSeconderApprovalRoutes from "./views/ProposerSeconderApproval/ProposerSeconderApprovalRoutes";
import MembershipCard from "./views/Membership/Routes";
import Category from "./views/Category/Routes";
import Chat from "./views/Chat/Routes";
import Email from "./views/Email/Routes";
import SMS from "./views/Message/Routes";
import OtherPaymentTypes from "./views/OtherPaymentTypes/Routes";
import EmailType from "./views/EmailType/Routes";
import NewsLetter from "./views/NewsLetter/Routes";
import Logs from "./views/Logs/Routes";
import Role from "./views/RoleManage/Routes";
import DashboardEvent from "./views/DashboardEvent/Routes";

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/session/signin" />
  }
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />
  }
];

const routes = [
  ...sessionRoutes,
  ...dashboardRoutes,
  ...UserRoutes,
  ...redirectRoute,
  ...NotificationRoutes,
  ...MemberRoutes,
  ...PasswordResetRoutes,
  ...InternalMemberRoutes,
  ...PaymentRoutes,
  ...ProjectRoutes,
  ...MemberApprovalRoutes,
  ...ProposerSeconderApprovalRoutes,
  ...MasterSettingsRoutes,
  ...ExcoRoutes,
  ...MembershipCard,
  ...Category,
  ...Chat,
  ...Email,
  ...SMS,
  ...OtherPaymentTypes,
  ...EmailType,
  ...NewsLetter,
  ...Logs,
  ...Role,
  ...DashboardEvent,
  ...errorRoute,

];

export default routes;
