import axios from 'axios';
import * as appconst from '../appconst';
import * as appVariables from '../appVariables';
import localStorageService from "./localStorageService";

class PaymentGatewayService {
    ls = window.localStorage

    createPayment = async (data) => {//Create new member
        data = {    "apiOperation":"CREATE_CHECKOUT_SESSION",
 
        "interaction":{"operation":"PURCHASE"},
        "order":{"id":"1115",
                "amount":1000,
                 "currency":"LKR"
                 }
    }

   
        const a = new Promise((resolve, reject) => {
            axios.post(appconst.PAYEMENTGATEWAY,data, {
                
                headers: {
                    "Access-Control-Allow-Origin":"*",
                    'Access-Control-Allow-Methods':"GET,PUT,POST,DELETE,PATCH,OPTIONS",
                    'Access-Control-Allow-Credentials':false,                     
                    'Content-Type': 'application/json'               
                },
                            
                auth: {
                    username: 'merchant.700183980118',
                    password: '5c671e48c7eab1426b3fca2f7d0c5fa2'
                  },
               
            },
            
            
            ).then(res => {
                console.log("res", res);
                return resolve(res);
            })
                .catch((error) => {
                    console.log("payment error", error);
                })
        });
        return await a;
    }



    // createPayment = async (data) => {//Create new member

    //     const a = new Promise((resolve, reject) => {
    //         axios.post(appconst.PAYEMENTGATEWAYNVP,data, {
    //             headers: {
    //                 'Access-Control-Allow-Origin':'*',
    //                 'Content-Type': 'application/x-www-form-urlencoded',
    //                 'Access-Control-Allow-Credentials':true,
    //                 'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    //             }
    //         }).then(res => {
    //             console.log("res", res);
    //             return resolve(res);
    //         })
    //             .catch((error) => {
    //                 console.log("error", error);
    //             })
    //     });
    //     return await a;
    // }



    getGateWaySessionID = async (data, config) => {//Create new member
        const a = new Promise((resolve, reject) => {
          axios.post(appconst.GATEWAYSESSION , data, config).then(res => {
              return resolve(res);
            })
            .catch((error) => {
              console.log("error", error);
            })
        });
        return await a;
      }
      getGateWaySessionIDforiegn = async (data, config) => {//Create new member
        const a = new Promise((resolve, reject) => {
          axios.post(appconst.GATEWAYSESSION+'foriegn' , data, config).then(res => {
              return resolve(res);
            })
            .catch((error) => {
              console.log("error", error);
            })
        });
        return await a;
      }


      updatePaymentSuccess = async (indicatorid) => {//Create new member
        const a = new Promise((resolve, reject) => {
          axios.put(appconst.PAYMENTRECEPT + "/" + indicatorid ).then(res => {
              return resolve(res);
            })
            .catch((error) => {
              console.log("error", error);
            })
        });
        return await a;
      }






}




export default new PaymentGatewayService();