//for define endpoints 
// export const AUTHPOINT = "https://vvoga.loonssafe.com/api/auth/";
// export const ENDPOINT = "https://vvoga.loonssafe.com/api/";
// export const LEAVINGCERTIFICATES = "https://vvoga.loonssafe.com/Members/SchoolCertificate/";
// export const PROFILEPICTURE = "https://vvoga.loonssafe.com/Members/Photo/";
// export const NEWSLETTER_FILE = "https://vvoga.loonssafe.com/Uploads/NewsLetter/";
// export const NEWS_FILE = "https://vvoga.loonssafe.com/Uploads/News/";
// export const PROMOTIONAL_FILE = "https://vvoga.loonssafe.com/Uploads/PromotionalContent/";
// export const PAYMENT_RECEIPT_DOWNLOAD = "https://vvoga.loonssafe.com/Uploads/Payment/";


// for local )(Tharindu)
export const CLIENT_ID = "client1";
export const AUTHPOINT = "https://apibackend.vvoga.lk/api/auth/";
export const ENDPOINT = "https://apibackend.vvoga.lk/api/";
export const LEAVINGCERTIFICATES = "https://apibackend.vvoga.lk/Members/SchoolCertificate/";
export const PROFILEPICTURE = "https://apibackend.vvoga.lk/Members/Photo/";
export const NEWSLETTER_FILE = "https://apibackend.vvoga.lk/Uploads/NewsLetter/";
export const NEWS_FILE = "https://apibackend.vvoga.lk/Uploads/News/";
export const PROMOTIONAL_FILE = "https://apibackend.vvoga.lk/Uploads/PromotionalContent/";
export const PAYMENT_RECEIPT_DOWNLOAD = "https://apibackend.vvoga.lk/Uploads/Payment/";

export const PAYMENT_OUTING = "http://cloud22.erp.lk:8080/POS/Txn.aspx";
export const PAYEMENTGATEWAYNVP = "https://bankofceylon.gateway.mastercard.com/api/nvp/version/58";
export const PAYEMENTGATEWAY = "https://bankofceylon.gateway.mastercard.com/api/rest/version/58/merchant/700183980118/session"


// server

// export const AUTHPOINT = "http://localhost:8000/api/auth/";
// export const ENDPOINT = "http://localhost:8000/api/";
// export const LEAVINGCERTIFICATES = "http://localhost:8000/Members/SchoolCertificate/";
// export const PROFILEPICTURE = "http://localhost:8000/Members/Photo/";
// export const NEWSLETTER_FILE = "http://localhost:8000/Uploads/NewsLetter/";
// export const NEWS_FILE = "http://localhost:8000/Uploads/News/";
// export const PROMOTIONAL_FILE = "http://localhost:8000/Uploads/PromotionalContent/";
// export const PAYMENT_RECEIPT_DOWNLOAD = "http://localhost:8000/Uploads/Payment/";

//------------------------------------------------------------------
//Auth routes
export const LOGIN_URL = AUTHPOINT + "login";
export const REFRESH = AUTHPOINT + "refresh";
export const USERINFOR_URL = AUTHPOINT + "user-profile";

//Users Route
export const USERS = ENDPOINT + "users";
export const CHANGE_USER_STATUS = ENDPOINT + "users/status/";
export const UPDATE_USER = ENDPOINT + "users/";
export const UPDATE_PROFILEPICTURE = ENDPOINT + "users/profilepicture/";
export const ALL_USERS = ENDPOINT + "users/all";
export const GET_ALL_USERS_BY_OFFICE = ENDPOINT + "user/office/";
export const GET_ALL_USERS_FROM_OFFICE = ENDPOINT + "user/from/office";
export const GET_USER_LOG = ENDPOINT + "logactivities/user/";
export const GET_LOGIN_USER = AUTHPOINT + "loginuser";

//notification
export const GET_NOTIFICATIONS = ENDPOINT + "notification";
export const READ_NOTIFICATION = ENDPOINT + "notification";
export const GET_NOTIFICATIONS_COUNT = ENDPOINT + "notification/count";
//-------------------------------------------------------
//member registration
export const MEMBER = ENDPOINT + "members";

//member update
export const MEMBERUPDATE = ENDPOINT + "updaterejectedmembers";

//promotional content
export const PROMOTIONALCONTENT = ENDPOINT + "promotionalcontent";

//Projects
export const PROJECT = ENDPOINT + "projects";

//payment
export const PAYMENT = ENDPOINT + "payments";

//MasterSettings
export const MASTERSETTINGS = ENDPOINT + "mastersettings";
//EXCO Routes
export const SUBCOMMITTEE = ENDPOINT + "subcomittees";
export const POSITION = ENDPOINT + "positions";
export const EXCOMEMBERS = ENDPOINT + "excomembers";

//MemberApprovalService
export const MEMBERAPPROVAL = ENDPOINT + "memberapproval";
//Geetiing paymentgayeway sessionid
export const GATEWAYSESSION = ENDPOINT + "gateways";
export const PAYMENTRECEPT = ENDPOINT + "paymentsucceed";
export const PROJECTPAYMENTS = ENDPOINT + "projectpayments";

export const INFROMATION_ALL = ENDPOINT + "information";
export const INFORMATION_SEARCH = ENDPOINT + "information_search";
//userrolemanagement
export const USERROLE = ENDPOINT + "userroles";

export const MEBERSHIPCARD_ALL = ENDPOINT + "mebershipcard"
export const MEBERSHIPCARD_MARK = ENDPOINT + "mebershipcard/mark"
export const MEBERSHIPCARD_DUPLICATE = ENDPOINT + "mebershipcard/duplicate"
export const MEBERSHIPCARD_DUPLICATE_LIST = ENDPOINT + "mebershipcard/duplicateList"
export const MEBERSHIPCARD_SEARCH = ENDPOINT + "mebershipcard/search"

//ChatRoutes
export const CATEGORY = ENDPOINT + "category"
export const MESSAGE = ENDPOINT + "message"


export const NOTIFICATION = ENDPOINT + "notifications"

export const SMS = ENDPOINT + "sms"

export const OTHER_PAYMENT_TYPE = ENDPOINT + "otherpaymenttype"


export const EMAIL_TYPE = ENDPOINT + "emailtype"

export const NEWSLETTER = ENDPOINT + "newletter"
export const NEWS = ENDPOINT + "news"

export const LOGS = ENDPOINT + "logs"

export const ROLES = ENDPOINT + "roles"
export const FUNCTIONS = ENDPOINT + "functions"

//check member data
export const CHECKMEMBERDATA = ENDPOINT + "checkMembers"