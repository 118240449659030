import axios from 'axios';
import * as appconst from '../appconst';
import localStorageService from "./localStorageService";

class OtherPaymentTypeService {
    ls = window.localStorage

    create = async(data) => {
        const a = new Promise((resolve, reject) => {
            axios.post(appconst.OTHER_PAYMENT_TYPE, data).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    getAll = async(page) => {
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            }
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.OTHER_PAYMENT_TYPE + "?page=" + page, headers)
                .then(res => {
                    return resolve(res.data.OtherPaymentTypes);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    getAllForDropDown = async(params) => {
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            params: params
        };

        const a = new Promise((resolve, reject) => {
            axios.get(appconst.OTHER_PAYMENT_TYPE + "/dropdown", headers)
                .then(res => {
                    return resolve(res.data.OtherPaymentTypes);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    update = async(data, id) => {
        const a = new Promise((resolve, reject) => {
            axios.put(appconst.OTHER_PAYMENT_TYPE + "/" + id, data).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    changeStatus = async(data, id) => {
        const a = new Promise((resolve, reject) => {
            axios.put(appconst.OTHER_PAYMENT_TYPE + "/chnageStatus/" + id, data).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }
}

export default new OtherPaymentTypeService();