import axios from 'axios';
import * as appconst from '../appconst';
import localStorageService from "./localStorageService";

class UserService {

    getUserswithRoles = async(data) => {
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params: data
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.USERROLE, headers)
                .then(res => {
                    return resolve(res.data.User);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }
    getUserswithRoleswithPagination = async(pagenumber, data) => {
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            },
            params: data
        };

        const a = new Promise((resolve, reject) => {
            axios.get(appconst.USERROLE + "?page=" + pagenumber, headers)
                .then(res => {
                    return resolve(res.data.User);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }
    getUserstoReset = async(email) => {
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.USERROLE + "/reset_password/" + email)
                .then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error);
                    console.log("error", error);
                })
        });
        return await a;
    }

    getUserstowelcome = async(email) => {
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.USERROLE + "/reset_password_welcome/" + email)
                .then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error);
                    console.log("error", error);
                })
        });
        return await a;
    }

    
    updateUserRole = async(data) => {
        const a = new Promise((resolve, reject) => {
            axios.put(appconst.USERROLE, data).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }
    removeUserRole = async(data) => {
        const a = new Promise((resolve, reject) => {
            axios.put(appconst.USERROLE + "/remove", data).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }





}




export default new UserService();