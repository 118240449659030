export const district = [
    { title: "Ampara" },
    { title: "Anuradhapura" },
    { title: "Badulla" },
    { title: "Batticaloa" },
    { title: "Colombo" },
    { title: "Galle" },
    { title: "Gampaha" },
    { title: "Hambantota" },
    { title: "Jaffna" },
    { title: "Kalutara" },
    { title: "Kandy" },
    { title: "Kegalle" },
    { title: "Kilinochchi" },
    { title: "Kurunegala" },
    { title: "Mannar" },
    { title: "Matale" },
    { title: "Matara" },
    { title: "Monaragala" },
    { title: "Mullaitivu" },
    { title: "Nuwara Eliya" },
    { title: "Polonnaruwa" },
    { title: "Puttalam" },
    { title: "Ratnapura" },
    { title: "Trincomalee" },
    { title: "Vavuniya" }
];
export const province = [
    { title: 'Northern' },
    { title: 'North Central' },
    { title: 'Eastern' },
    { title: 'North Western' },
    { title: 'Central' },
    { title: 'Uva' },
    { title: 'Western' },
    { title: 'Sabaragamuwa' },
    { title: 'Southern' }
];

export const organizations = [

];
export const designation = [];

export const unit = [

];
export const type = [
    { title: 'All' },
    { title: 'walkin' },
    { title: 'post' },
    { title: 'hotline' },
    { title: 'fax' },
    { title: 'mail' },
    { title: 'web' },
    { title: 'Su Moto' }
];

export const sumotto_type = [
    { title: 'Television' },
    { title: 'Radio' },
    { title: 'Newspaper' },
    { title: 'Social Media' },
    { title: 'Other' },

];

export const can_status = [
    { title: 'All' },
    { title: 'Pending' },
    { title: 'Accept' },
    { title: 'Reject' }
];

export const letter_status = [
    { title: 'All' },
    { title: 'Sent' },
    { title: 'Pending' }

];

export const ma_cancasefilter = [
    { title: 'Case Number' },
    { title: 'Petitioner Name' },
    { title: 'Victim Name' },
    { title: 'Respondent Name ' },
];

export const organization_type = [
    { title: 'Government' },
    { title: 'Semi' },
    { title: 'Private' },
];

export const language = [
    { title: 'English' },
    { title: 'Sinhala' },
    { title: 'Tamil' }
];

export const letter_format = [
    { title: 'Advise To Refer' },
    { title: 'Advise To Refer - Ombudsman' },
    { title: 'Not within Mandate' },
    { title: 'No Fundamental Rights' },
    { title: 'Time Bar' },

];

export const referralpoint_letter = [

    { title: 'Referral Point - Sinhala' },
    { title: 'Referral Point - Tamil' }
];
export const letter_formatall = [
    { title: 'Advise To Refer' },
    { title: 'Advise To Refer - Ombudsman' },
    { title: 'Not within Mandate' },
    { title: 'No Fundamental Rights' },
    { title: 'Time Bar' },
    { title: 'Referral Point - Sinhala' },
    { title: 'Referral Point - Tamil' }

];

export const calling_letters = [
    { title: 'Respondent Calling' },
    { title: 'Petitioner Calling' },
    { title: 'MLEF Calling' },
    { title: 'Medical Report Calling' }
]


export const report_types = [
    { title: 'Complainer Written Submission' },
    { title: 'Respondent Written Submission' },
    { title: 'Observation Report' },
    { title: 'Eye Witness Report' },
    { title: 'Petitioner Calling' },
    { title: 'Respondent Calling' }
]

export const report_calling_status = [
    { title: 'Pending' },
    { title: 'Received' },
    { title: 'Delayed' },
]


export const calling_for = [
    { title: 'Complainer' },
    { title: 'Victim' },
    { title: 'Respondent' },
    { title: 'Medical Officer' },
    { title: 'Prison Superintendent' }
]

export const callingResponsetype = [
    { title: "Response" },
    { title: "Statement" }
]
export const oldcase = [
    { title: 'Can' },
    { title: 'Cannot' }
];


export const timeSlots = [
    { title: "Morning Slot 1" },
    { title: "Morning Slot 2" },
    { title: "Evening Slot 1" },
    { title: "Evening Slot 2" },
]

export const summon_send_type = [
    { title: 'Fax' },
    { title: 'Post' },
    { title: 'Email' },
    { title: 'Hand' }
];

export const summon_cancle_reasons = [
    { title: 'Respondent Request' },
    { title: 'Petitioner Request' },
    { title: 'Commissions decision to Cancel' },
    { title: 'Officers Request' },
    { title: 'Case been settled' },
    { title: 'Relief granted' },
    { title: 'Other' }
];


export const summon_results = [
    { title: "Absent" },
    { title: "Re-summon" },
    { title: "Relief Granted" },
    { title: "Settlement" },
    { title: "Other" },
]
export const rec_types = [
    { title: 'Draft Recommendation' },
    { title: 'Draft No Violation' },
    { title: 'Draft Inquiry Report' },
    { title: 'Recommendation- Non Imp Unit' }
];

export const report_type = [
    { title: 'Inquiry Report' },
    { title: 'No Violation Report' },
]

export const send_to = [
    { title: 'Non Implementation Unit' },
    { title: 'Record Room' },
    // { title: 'Other' }
]

export const reason = [
    { title: 'Withdrawal on Complainer Request' },
    { title: 'Withdrawal on Victim Request' },
    { title: 'Respondent Report Calling' },
    { title: 'No FR Violation' },
    { title: 'Not within Mandate' },
    { title: 'Court Case' },
    { title: 'Relief Granted' },
    { title: 'Not Interest' },
    { title: 'Court Case' },
    { title: 'SCRF' },
    { title: 'Settlement from Inquiry' },
    { title: 'After Recommendation' },
    { title: 'After Implementation' },
    { title: 'Other' }
]

export const summon_status = [
    { title: 'Accept' },
    { title: 'Pending' },
    { title: 'Rejected' },
]

export const summon_response = [
    { title: 'No Change' },
    { title: 'Inquiry Cancellation ' }
]


export const case_types = [
    { title: "Can" },
    { title: "Can Not" },
    { title: "Focal Point" },
    { title: "Relief Granted" },
    { title: "Closed" },
    { title: "Settled" },
    { title: "Advice to Reffer" },
    { title: "Not Accept" },
]

export const case_sourse = [
    { title: 'walkin' },
    { title: 'post' },
    { title: 'hotline' },
    { title: 'fax' },
    { title: 'mail' },
    { title: 'web' },
    { title: "Su Moto" },
]

export const case_status = [
    { title: "Closed" },
    { title: "Pending" }
]

export const genders = [
    { title: "Female" },
    { title: "Male" },
    { title: "Others" }
]

export const recommendtations_types = [
    { title: "Final" },
    { title: "Draft" },
    { title: "Issue" }
]

export const all_case_report_column = [
    { title: "Case Number", selected: true, value: "case_number", type: 'table' },
    { title: "Reported Date", selected: true, value: "rep_date", type: 'table' },
    { title: "Type", selected: true, value: "type", type: 'table' },
    { title: "Cannot Reason", selected: true, value: "cannot_reason", type: 'table' },
    { title: "Focal Point", selected: true, value: "focal_point", type: 'table' },
    { title: "Received Source", selected: true, value: "sourse", type: 'table' },
    { title: "Language", selected: true, value: "langauge", type: 'table' },
    { title: "Category ", selected: true, value: "category", type: 'table' },
    { title: "Subcategory", selected: true, value: "sub_category", type: 'table' },
    { title: 'HO/Regional Office', selected: true, value: "office", type: 'table' },
    { title: "Status", selected: true, value: "status", type: 'table' },
    // { title: 'Last Action Taken On', selected: true, value: "last_action", type: 'table' },
    { title: "Complainer Name", selected: false, value: "c_name", type: 'complainer' },
    { title: "Complainer's Organization", selected: false, value: "c_organization", type: 'complainer' },
    { title: "Complainer's Gender", selected: false, value: "c_gender", type: 'complainer' },
    { title: "Complainer's Age", selected: false, value: "c_age", type: 'complainer' },
    { title: "Complainer's Address", selected: false, value: "c_address", type: 'complainer' },
    { title: "Complainer's Contact Number", selected: false, value: "c_contac", type: 'complainer' },
    { title: "Complainer's Email", selected: false, value: "c_email", type: 'complainer' },
    { title: "Complainer's NIC Number", selected: false, value: "c_nic", type: 'complainer' },
    { title: "Complainer's District", selected: false, value: "c_district", type: 'complainer' },
    { title: "Victim Name", selected: false, value: "v_name", type: 'victim' },
    { title: "Victim's Organization", selected: false, value: "v_organization", type: 'victim' },
    { title: "Victim's Gender", selected: false, value: "v_gender", type: 'victim' },
    { title: "Victim's Age", selected: false, value: "v_age", type: 'victim' },
    { title: "Victim's Address", selected: false, value: "v_address", type: 'victim' },
    { title: "Victim's Contact Number", selected: false, value: "v_contact", type: 'victim' },
    { title: "Victim's Email", selected: false, value: "v_email", type: 'victim' },
    { title: "Victim's NIC Number", selected: false, value: "v_nic", type: 'victim' },
    { title: "Victim's District", selected: false, value: "v_sistrict", type: 'victim' },
    { title: "Respondent Name", selected: false, value: "r_name", type: 'respondent' },
    { title: "Respondent's Organization", selected: false, value: "r_organization", type: 'respondent' },
    { title: "Respondent's Organization Type", selected: false, value: "r_organization_type", type: 'respondent' },
    { title: "Respondent's Designation", selected: false, value: "r_designation", type: 'respondent' },
    { title: "Respondent's Gender", selected: false, value: "r_gender", type: 'respondent' },
    { title: "Respondent's Address", selected: false, value: "r_address", type: 'respondent' },
    { title: "Respondent's Contact Number", selected: false, value: "r_contact", type: 'respondent' },
    { title: "Respondent's Email", selected: false, value: "r_email", type: 'respondent' },
    { title: "Respondent's NIC Number", selected: false, value: "r_nic", type: 'respondent' },
    { title: "Respondent's District", selected: false, value: "r_district", type: 'respondent' },
    { title: "Respondent's Province", selected: false, value: "r_-province", type: 'respondent' },
    { title: "Other Name", selected: false, value: "o_name", type: 'other' },
    { title: "Other's Organization", selected: false, value: "o_organization_name", type: 'other' },
    { title: "Other's Organization Type", selected: false, value: "o_organization_type", type: 'other' },
    { title: "Other's Designation", selected: false, value: "o_designation", type: 'other' },
    { title: "Other's Gender", selected: false, value: "o_gender", type: 'other' },
    { title: "Other's Address", selected: false, value: "o_address", type: 'other' },
    { title: "Other's Contact Number", selected: false, value: "o_contact", type: 'other' },
    { title: "Other's Email", selected: false, value: "o_email", type: 'other' },
    { title: "Other's NIC Number", selected: false, value: "o_nic", type: 'other' },
    { title: "Other's District", selected: false, value: "o_district", type: 'other' },
    { title: "Other's Province", selected: false, value: "o_province", type: 'other' },
    { title: 'Action', selected: true, value: "action", type: 'table' },
]


export const schedule_types = [
    { value: "HO", title: "Hotline" },
    { value: "PH", title: "Public Hearing Day" },
    { value: "VI", title: "Visit" },
    { value: "OL", title: "Officer's Leave Day" },
    { value: "RR", title: "Rapid Response" },
]

export const route_stop_types = [
    { title: "Police Station" },
    { title: "Prison" },
    { title: "Children's Home" },
    { title: "Elder's Home" },
    { title: "Hospital" },
    { title: "Detention Center" },
    { title: "Mental Hospital" },
]

export const visit_form_types = [
    { title: "Check List" },
    { title: "Instructions" },
    { title: "Questionnaire" }
]

export const visit_status = [
    { title: "Completed" },
    { title: "Canceled" },
]

export const schedule_status = [
    { title: "New" },
    { title: "Approved" },
]


export const appeal_status = [
    { title: "Closed" },
    { title: "Pending" }
]


export const record_room_appeal_status = [
    { title: "New" },
    { title: "Pending" },
    { title: "Decided" }
]


export const record_room_appeal_next_steps = [
    { title: "Closing the Case" },
    { title: "Re-Open with Record Room" },
    { title: "Re-Open and Assign an Officer" },
    { title: "Other" }
]

export const none_imp_person_types = [
    { title: 'Complainer' },
    { title: 'Victim' },
    { title: 'Respondent' },
    { title: "Others" },
]


export const none_imp_backreport_status = [
    { title: "New" },
    { title: "Received" },
    { title: "Not Received" },
    { title: "Date extension Request" },
    { title: "Issue to another party" },
    { title: "Objection" },
]

export const none_imp_backreport_change_status = [
    { title: "Received" },
    { title: "Not Received" },
    { title: "Date extension Request" },
    { title: "Issue to another party" },
    { title: "Objection" },
]



export const general_appeal_status = [
    { title: 'Mark Decision' },
    { title: 'Forward to Another Party' },
];

export const years = [
    { title: "1930" },
    { title: "1931" },
    { title: "1932" },
    { title: "1933" },
    { title: "1934" },
    { title: "1935" },
    { title: "1936" },
    { title: "1937" },
    { title: "1938" },
    { title: "1939" },
    { title: "1940" },
    { title: "1941" },
    { title: "1942" },
    { title: "1943" },
    { title: "1944" },
    { title: "1945" },
    { title: "1946" },
    { title: "1947" },
    { title: "1948" },
    { title: "1949" },
    { title: "1950" },
    { title: "1951" },
    { title: "1952" },
    { title: "1953" },
    { title: "1954" },
    { title: "1955" },
    { title: "1956" },
    { title: "1957" },
    { title: "1958" },
    { title: "1959" },
    { title: "1960" },
    { title: "1961" },
    { title: "1962" },
    { title: "1963" },
    { title: "1964" },
    { title: "1965" },
    { title: "1966" },
    { title: "1967" },
    { title: "1968" },
    { title: "1969" },
    { title: "1970" },
    { title: "1971" },
    { title: "1972" },
    { title: "1973" },
    { title: "1974" },
    { title: "1975" },
    { title: "1976" },
    { title: "1977" },
    { title: "1978" },
    { title: "1979" },
    { title: "1980" },
    { title: "1981" },
    { title: "1982" },
    { title: "1983" },
    { title: "1984" },
    { title: "1985" },
    { title: "1986" },
    { title: "1987" },
    { title: "1988" },
    { title: "1989" },
    { title: "1990" },
    { title: "1991" },
    { title: "1992" },
    { title: "1993" },
    { title: "1994" },
    { title: "1995" },
    { title: "1996" },
    { title: "1997" },
    { title: "1998" },
    { title: "1999" },
    { title: "2000" },
    { title: "2001" },
    { title: "2002" },
    { title: "2003" },
    { title: "2004" },
    { title: "2005" },
    { title: "2006" },
    { title: "2007" },
    { title: "2008" },
    { title: "2009" },
    { title: "2010" },
    { title: "2011" },
    { title: "2012" },
    { title: "2013" },
    { title: "2014" },
    { title: "2015" },
    { title: "2016" },
    { title: "2017" },
    { title: "2018" },
    { title: "2019" },
    { title: "2020" },
    { title: "2021" },
    { title: "2022" },
    { title: "2023" },
    { title: "2024" },
    { title: "2025" },
    { title: "2026" },
    { title: "2027" },
    { title: "2028" },
    { title: "2029" },
    { title: "2030" },
]

export const grades = [
    { title: "Grade 1" },
    { title: "Grade 2" },
    { title: "Grade 3" },
    { title: "Grade 4" },
    { title: "Grade 5" },
    { title: "Grade 6" },
    { title: "Grade 7" },
    { title: "Grade 8" },
    { title: "Grade 9" },
    { title: "Grade 10" },
    { title: "Grade 11" },
    { title: "Grade 12" },
]

export const countries = [
    { title: "Afghanistan" },
    { title: "Albania" },
    { title: "Algeria" },
    { title: "Andorra" },
    { title: "Angola" },
    { title: "Antigua and Barbuda" },
    { title: "Argentina" },
    { title: "Armenia" },
    { title: "Australia" },
    { title: "Austria" },
    { title: "Azerbaijan" },
    { title: "The Bahamas" },
    { title: "Bahrain" },
    { title: "Bangladesh" },
    { title: "Barbados" },
    { title: "Belarus" },
    { title: "Belgium" },
    { title: "Belize" },
    { title: "Benin" },
    { title: "Bhutan" },
    { title: "Bolivia" },
    { title: "Bosnia and Herzegovina" },
    { title: "Botswana" },
    { title: "Brazil" },
    { title: "Brunei" },
    { title: "Bulgaria" },
    { title: "Burkina Faso" },
    { title: "Burundi" },
    { title: "Cambodia" },
    { title: "Cameroon" },
    { title: "Canada" },
    { title: "Cape Verde" },
    { title: "Central African Republic" },
    { title: "Chad" },
    { title: "Chile" },
    { title: "China" },
    { title: "Colombia" },
    { title: "Comoros" },
    { title: "Congo, Republic of the" },
    { title: "Congo, Democratic Republic of the" },
    { title: "Costa Rica" },
    { title: "Cote d'Ivoire" },
    { title: "Croatia" },
    { title: "Cuba" },
    { title: "Cyprus" },
    { title: "Czech Republic" },
    { title: "Denmark" },
    { title: "Djibouti" },
    { title: "Dominica" },
    { title: "Dominican Republic" },
    { title: "East Timor (Timor-Leste)" },
    { title: "Ecuador" },
    { title: "Egypt" },
    { title: "El Salvador" },
    { title: "Equatorial Guinea" },
    { title: "Eritrea" },
    { title: "Estonia" },
    { title: "Ethiopia" },
    { title: "Fiji" },
    { title: "Finland" },
    { title: "France" },
    { title: "Gabon" },
    { title: "The Gambia" },
    { title: "Georgia" },
    { title: "Germany" },
    { title: "Ghana" },
    { title: "Greece" },
    { title: "Grenada" },
    { title: "Guatemala" },
    { title: "Guinea" },
    { title: "Guinea-Bissau" },
    { title: "Guyana" },
    { title: "Haiti" },
    { title: "Honduras" },
    { title: "Hungary" },
    { title: "Iceland" },
    { title: "India" },
    { title: "Indonesia" },
    { title: "Iran" },
    { title: "Iraq" },
    { title: "Ireland" },
    { title: "Israel" },
    { title: "Italy" },
    { title: "Jamaica" },
    { title: "Japan" },
    { title: "Jordan" },
    { title: "Kazakhstan" },
    { title: "Kenya" },
    { title: "Kiribati" },
    { title: "Korea, North" },
    { title: "Korea, South" },
    { title: "Kosovo" },
    { title: "Kuwait" },
    { title: "Kyrgyzstan" },
    { title: "Laos" },
    { title: "Latvia" },
    { title: "Lebanon" },
    { title: "Lesotho" },
    { title: "Liberia" },
    { title: "Libya" },
    { title: "Liechtenstein" },
    { title: "Lithuania" },
    { title: "Luxembourg" },
    { title: "Macedonia" },
    { title: "Madagascar" },
    { title: "Malawi" },
    { title: "Malaysia" },
    { title: "Maldives" },
    { title: "Mali" },
    { title: "Malta" },
    { title: "Marshall Islands" },
    { title: "Mauritania" },
    { title: "Mauritius" },
    { title: "Mexico" },
    { title: "Micronesia, Federated States of" },
    { title: "Moldova" },
    { title: "Monaco" },
    { title: "Mongolia" },
    { title: "Montenegro" },
    { title: "Morocco" },
    { title: "Mozambique" },
    { title: "Myanmar (Burma)" },
    { title: "Namibia" },
    { title: "Nauru" },
    { title: "Nepal" },
    { title: "Netherlands" },
    { title: "New Zealand" },
    { title: "Nicaragua" },
    { title: "Niger" },
    { title: "Nigeria" },
    { title: "Norway" },
    { title: "Oman" },
    { title: "Pakistan" },
    { title: "Palau" },
    { title: "Panama" },
    { title: "Papua New Guinea" },
    { title: "Paraguay" },
    { title: "Peru" },
    { title: "Philippines" },
    { title: "Poland" },
    { title: "Portugal" },
    { title: "Qatar" },
    { title: "Romania" },
    { title: "Russia" },
    { title: "Rwanda" },
    { title: "Saint Kitts and Nevis" },
    { title: "Saint Lucia" },
    { title: "Saint Vincent and the Grenadines" },
    { title: "Samoa" },
    { title: "San Marino" },
    { title: "Sao Tome and Principe" },
    { title: "Saudi Arabia" },
    { title: "Senegal" },
    { title: "Serbia" },
    { title: "Seychelles" },
    { title: "Sierra Leone" },
    { title: "Singapore" },
    { title: "Slovakia" },
    { title: "Slovenia" },
    { title: "Solomon Islands" },
    { title: "Somalia" },
    { title: "South Africa" },
    { title: "South Sudan" },
    { title: "Spain" },
    { title: "Sri Lanka" },
    { title: "Sudan" },
    { title: "Suriname" },
    { title: "Swaziland" },
    { title: "Sweden" },
    { title: "Switzerland" },
    { title: "Syria" },
    { title: "Taiwan" },
    { title: "Tajikistan" },
    { title: "Tanzania" },
    { title: "Thailand" },
    { title: "Togo" },
    { title: "Tonga" },
    { title: "Trinidad and Tobago" },
    { title: "Tunisia" },
    { title: "Turkey" },
    { title: "Turkmenistan" },
    { title: "Tuvalu" },
    { title: "Uganda" },
    { title: "Ukraine" },
    { title: "United Arab Emirates" },
    { title: "United Kingdom" },
    { title: "United States of America" },
    { title: "Uruguay" },
    { title: "Uzbekistan" },
    { title: "Vanuatu" },
    { title: "Vatican City (Holy See)" },
    { title: "Venezuela" },
    { title: "Vietnam" },
    { title: "Yemen" },
    { title: "Zambia" },
    { title: "Zimbabwe" },

]

export const payment_type = [
    { title: "Donation" },
    { title: "Sponsorship" },
    { title: "Duplicate Membership Card Fee" },
    // { title: "Other" },
]

export const non_oga_payment_type = [
    { title: "Donation" },
    { title: "Membership Fee" },
    { title: "Sponsorship" },
]
export const project_payment_type = [
    { title: "Donation" },
    { title: "Sponsorship" },

]

export const payment_type_report = [
    { title: "Donation" },
    { title: "Sponsorship" },
    { title: "Membership Fee" },
    { title: "Duplicate Membership Card Fee" }
]

export const relationship_to_school = [
    { title: "Membership Applicant" },
    { title: "Well Wisher" },
    { title: "OGA Member" },
]

export const memberCount = [
    "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"
]

export const exco_member_category = ["Office Bearer", "Ex-Co Member", "Subcommittee"]
export const subcommitee_positions = ["Convener", "Co-Convener"]
export const membership_approval_status = [
    { title: "New Applications to be Downloaded" },
    { title: "Pending Leaving Certificate Approval" },
    { title: "Pending Payment" },
    { title: "Pending Ex-Co Approval" },
    { title: "Pending Secretary Approval" },
]

export const paymentSession = {

    "apiOperation": "CREATE_CHECKOUT_SESSION",
    "apiPassword": "5c671e48c7eab1426b3fca2f7d0c5fa2",
    "apiUsername": "merchant.700183980118",
    "merchant": "700183980118",
    "interaction.operation": "PURCHASE",
    "order.id": "111",
    "order.amount": 1000,
    "order.currency": "LKR"
}

export const paymentSessionRest = {
    "apiOperation": "CREATE_CHECKOUT_SESSION",

    "interaction": { "operation": "PURCHASE" },
    "order": {
        "id": "111",
        "amount": 1000,
        "currency": "LKR"
    }
}
export const payment_type_for_reports = ["Membership Fee", "Donation", "Sponsorship", "Duplicate Membership Card Fee", "Other"]
export const roles = ["Secretary", "Treasurer and Assistant Treasurer", "New Members Subcommittee Convener and Co-Convener"]

export const mebership_status = [
    { title: "New Members" },
    { title: "Existing Members" },
]

export const mebership_status_information = [
    { title: "Active" },
    { title: "Inactive" },
    { title: "Deceased" },
    { title: "Cancelled" },
    { title: "Not Issued" },
    { title: "Unidentified" },
]


export const application_type = [
    { title: "Applied via Physical Form" },
    { title: "Applied via the System" },
]

export const member_information_columns = [
    { title: "Name To Be Printed", selected: true, value: "name", type: 'table' },
    { title: "OGA No", selected: true, value: "membership_no", type: 'table' },
    { title: "Country of Residence", selected: true, value: "country", type: 'table' },
    { title: "Date Of Birth", selected: false, value: "dob", type: 'table' },
    { title: "NIC / Passport No", selected: false, value: "nic", type: 'table' },
    { title: "Mobile Number", selected: true, value: "contact", type: 'table' },
    { title: "Email", selected: true, value: "email", type: 'table' },
    { title: "Permanent Address", selected: false, value: "address", type: 'table' },
    { title: "Postal Address", selected: false, value: "postal_address", type: 'table' },
    { title: "Correspondance District", selected: false, value: "district", type: 'table' },

    { title: "Occupations", selected: false, value: "occupations", type: 'table' },

    { title: "Joined Date to VVOGA ", selected: false, value: "membership_year", type: 'table' },
    { title: "A/L Batch", selected: false, value: "batch", type: 'table' },
    { title: "Year of Admission to School", selected: false, value: "addmision_year", type: 'table' },
    { title: "Admission Number", selected: false, value: "addmision_no", type: 'table' },
    { title: "Newsletter Preference", selected: false, value: "letter", type: 'table' },
    { title: "Newsletter Method", selected: false, value: "letter_method", type: 'table' },
    { title: "Membership Status", selected: true, value: "membership", type: 'table' },
    { title: "Promotional Content", selected: false, value: "promotional_content", type: 'table' }, ,
]

export const news_letter_preference = [
    { title: "Yes" },
    { title: "No" },
]

export const news_letter_method = [
    { title: "By Post" },
    { title: "By Email" },
]


export const promotional_content = [
    { title: "Yes" },
    { title: "No" },
]

export const message_roles = [
    { title: "Secretary" },
    { title: "Treasurer and Assistant Treasurer" },
]


export const message_countries = [
    { title: "Sri Lanka" },
    { title: "Outside Sri Lanka" }
]

export const MAX_UPLOD_SIZE = 5;
export const MAX_UPLOD_SIZE_NEWSLETTER = 30;


export const notifications = [
    { heading: "Test hedding 01", "title": "Test title 01", "subtitle": "Test subtitle 01" },
    { heading: "Test hedding 02", "title": "Test title 02", "subtitle": "Test subtitle 02" },
    { heading: "Test hedding 03", "title": "Test title 03", "subtitle": "Test subtitle 03" },
]


export const email_types = [
    { title: "Secretary" },
    { title: "Treasurer and Assistant Treasurer" },
    { title: "New Members Subcommittee Convener and Co-Convener" },
    { title: "Member process BCC Email" }
]