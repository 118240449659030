import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";


const ViewMasterSettings = MatxLoadable({
    loader: () =>
        import ("./ViewMasterSettings")
})


const MasterSettingsRoutes = [{
    path: "/viewmastersettings",
    component: ViewMasterSettings,
    auth: authRoles.Payment_Setting,
}];

export default MasterSettingsRoutes;