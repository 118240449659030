const Layout1Settings = {
    leftSidebar: {
        show: true,
        mode: 'close', // full, close, compact, mobile,
        theme: 'vvoga', // View all valid theme colors inside MatxTheme/themeColors.js
        //bgOpacity: .96, // 0 ~ 1
        bgImgURL: '/assets/images/sidebar/sidebar-bg-dark.jpg'
    },
    topbar: {
        show: true,
        fixed: true,
        //theme: 'purpleDark1', // View all valid theme colors inside MatxTheme/themeColors.js
        theme: 'white',
    },

}

export default Layout1Settings;