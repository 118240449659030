import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { SimpleCard } from "matx";
import moment from 'moment';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';
import Grid from '@material-ui/core/Grid';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import SaveIcon from '@material-ui/icons/Save';
import MemberRegistrationsService from '../../services/MemberRegistrationsService';
import PaymentService from '../../services/PaymentService';
import { resetIdCounter } from "downshift";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { indigo, teal } from '@material-ui/core/colors';
import * as appVariables from '../../appVariables';
import Box from '@material-ui/core/Box';
import 'date-fns';
import MuiPhoneInput from 'material-ui-phone-number';
import MasterSettingsService from '../../services/MasterSettingsService';
import MuiPhoneNumber from 'material-ui-phone-number';
import ProjectService from './../../services/ProjectService';
import PaymentGatewayService from './../../services/PaymentGatewayService';
import LinearProgress from '@material-ui/core/LinearProgress';
import MemberService from './../../services/MemberRegistrationsService';
import OtherPaymentTypeService from './../../services/OtherPaymentTypeService';

const styles = theme => ({

    cssLabel: {
        '&::before': {
            content: '*',
            color: 'red',
            position: 'absolute'
        },
    },

    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: `${theme.palette.primary.main} !important`,
        }
    },

    palette: {
        primary: {
            main: teal[500],
        },
        secondary: {
            main: indigo[500],
        },
    },

    cssFocused: {},

    notchedOutline: {
        borderWidth: '1px',
        borderColor: 'blue !important'
    },

});

const ColorButton1 = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(indigo[700]),
        backgroundColor: indigo[700],
        '&:hover': {
            backgroundColor: indigo[900],
        },
    },
}))(Button);

//   React.render(
//     <MuiPhoneNumber defaultCountry={'us'}/>,
//     document.getElementById('root')
//   );

class AllPayment extends Component {
    state = {
        toConfirm: false,
        disablebutton: false,
        paymentpageopen: false,
        payment_type: [],
        non_oga_payment_type: [],
        relationship_to_school: [],
        ogaOrNonOga: false,
        ogaOrNonOgaidcheck: false,
        donationOrsponsership: false,
        duplicateCardFee: false,
        membershipFee: false,
        checkogamember: false,
        project: [],
        selected_project: {},
        snakebar: false,
        memberdata: {},
        paymentloadingprocess: 0,
        membership_fee: null,
        duplicate_card_fee: null,
        project_remark: false,
        payment_remark: false,
        min_amount: 0,
        min_message: "",
        max_amount: 10000000,
        message: "",
        paymentSessionid: "",
        severity: "success",
        formdata: { type_of_card: 'SRL', ogaOrNonOga: false, ref_no: "", indicator_id: "", name: null, members_id: null, oga_number: null, contact_no: '', email: '', relationship_to_school: "", payment_type: "", donated_project: "", amount: "", remarks: "", passport_no: "", nic_no: "" },
        paymentgatewaydata: {
            session: { id: 'SESSION0002668752208J1191711G84' }, interaction: {

                merchant: {

                    name: 'Visaka College',

                    address: {

                        line1: '200 Sample St',

                        line2: '1234 Example Town'

                    }

                }

            }

        }
    };


    async componentDidMount() {

        this.loadProjects();
        this.loadMasterSettings()
        let relationship_to_school = appVariables.relationship_to_school;
        this.resetFields();

        this.setState({
            relationship_to_school: relationship_to_school
        })

        await this.getAllOtherPaymentTypes()

    }


    async getAllOtherPaymentTypes() {
        let data = {
            type: null
        }

        await OtherPaymentTypeService.getAllForDropDown(data).then((res) => {
            if (res === 500) {
                this.getAllOtherPaymentTypes()
            } else {
                let temp = appVariables.non_oga_payment_type;
                temp = temp.concat(res)

                let payment_type = appVariables.payment_type;
                payment_type = payment_type.concat(res)

                payment_type.sort()
                this.setState({
                    payment_type: payment_type,
                    non_oga_payment_type: temp
                })
            }
        })
    }


    resetFields() {
        this.setState({ formdata: { type_of_card: 'SRL', ogaOrNonOga: false, name: null, members_id: null, oga_number: null, contact_no: '', email: '', relationship_to_school: "", payment_type: "", donated_project: "", amount: "", remarks: "", passport_no: "", nic_no: "" } });
    }

    handleClose = (event, reason) => {//For handle alert visibility
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snakebar: false })
    };

    setdropdowndata(key, value) {// Set value of dropdown
        var data = this.state.formdata;
        data['amount'] = ""


        var donationOrsponsership = false;
        var duplicateCardFee = false;
        var membershipFee = false;
        var selected_project = this.state.selected_project;
        var project_remark = this.state.project_remark;
        var payment_remark = this.state.payment_remark;
        var min_amount = 0;
        var max_amount = 10000000;
        var min_message = ""

        if (key == "payment_type" || key == "donated_project") {
            data['remarks'] = null
        }

        if (value == null) {
            data[key] = "";
            project_remark = false
            payment_remark = false

            if (data["payment_type"] == "Donation" || data["payment_type"] == "Sponsorship") {
                donationOrsponsership = true;
                this.loadProjects();
            } else if (data["payment_type"] == "Duplicate Membership Card Fee") {
                duplicateCardFee = true;
                data["amount"] = this.state.duplicate_card_fee;
                min_amount = this.state.duplicate_card_fee;
                min_message = "Minimum payment for a duplicate card fee is Rs. " + min_amount;
            } else if (data["payment_type"] == "Membership Fee") {
                duplicateCardFee = true;
                membershipFee = true;
                data["amount"] = this.state.membership_fee;
                min_amount = this.state.membership_fee;
                min_message = "Minimum payment for a membership payment is Rs. " + min_amount;
            }
        } else {
            payment_remark = false;
            project_remark = false

            if (key == "payment_type") {
                data[key] = value.title;
                if (data[key] == "Donation" || data[key] == "Sponsorship") {

                    data["amount"] = "";
                    setTimeout(() => {
                        this.setState({
                            donationOrsponsership: true,
                        });
                    }, 500);
                    this.loadProjects();
                } else if (data[key] == "Duplicate Membership Card Fee") {
                    duplicateCardFee = true;
                    data["amount"] = this.state.duplicate_card_fee;
                    min_amount = this.state.duplicate_card_fee;
                    min_message = "Minimum payment for a duplicate card fee is Rs. " + min_amount;
                } else if (data[key] == "Membership Fee") {
                    duplicateCardFee = true;
                    membershipFee = true;
                    data["amount"] = this.state.membership_fee;
                    min_amount = this.state.membership_fee;
                    min_message = "Minimum payment for a membership payment is Rs. " + min_amount;
                }

                if (value.remark_needed) {
                    payment_remark = true;
                }

            } else if (key == 'donated_project') {
                data[key] = value.id;
                donationOrsponsership = true;
                selected_project = value;
                if (value.max_collection !== null)
                    max_amount = (value.max_collection - value.collected_amount);
                if (data["payment_type"] == "Donation") {
                    if (value.min_donation !== null && !data.ogaOrNonOga)
                        min_amount = value.min_donation;
                    min_message = "Minimum donation amount for this project is Rs. " + min_amount;
                } else if (data["payment_type"] == "Sponsorship") {
                    if (value.min_sponsership !== null && !data.ogaOrNonOga)
                        min_amount = value.min_sponsership;
                    min_message = "Minimum sponsorship amount for this project is Rs. " + min_amount;
                } else {
                    min_amount = 0;
                    min_message = "Minimum payment is " + min_amount;
                }

                if (value.remark_check) {
                    project_remark = true
                }
            } else if (key == 'relationship_to_school') {
                data[key] = value.title;

                if (data["payment_type"] == "Donation" || data["payment_type"] == "Sponsorship") {
                    donationOrsponsership = true;
                } else if (data["payment_type"] == "Duplicate Membership Card Fee") {
                    duplicateCardFee = true;
                    data["amount"] = this.state.duplicate_card_fee;
                } else if (data["payment_type"] == "Membership Fee") {
                    duplicateCardFee = true;
                    membershipFee = true;
                    data["amount"] = this.state.membership_fee;
                }

            }
        }
        this.setState({ formdata: data, selected_project: selected_project, payment_remark: payment_remark, project_remark: project_remark, min_amount: min_amount, min_message: min_message, max_amount: max_amount, donationOrsponsership: donationOrsponsership, duplicateCardFee: duplicateCardFee, membershipFee: membershipFee });

    }

    getMember = event => {

        // if (event.key === 'Enter' || event.key === "Tab") {
        var getMemberData = this.state.formdata;
        if (event.key === 'Enter') {
            event.preventDefault();
        }
        let params = this.state.formdata.oga_number
        MemberRegistrationsService.getMemberByOGANOOnly(params).then((data) => {
            if (data.length >= 1) {

                getMemberData['members_id'] = data[0].id;
                this.setState({ formdata: getMemberData, ogaOrNonOgaidcheck: true, checkogamember: true })
            } else {

                getMemberData['members_id'] = null;
                this.setState({ formdata: getMemberData, ogaOrNonOgaidcheck: true, checkogamember: false, message: "Can Not find OGA Member", snakebar: false, severity: "error" })
            }

        })

        // }
    }
    getMemberMobile = event => {


        var getMemberData = this.state.formdata;
        if (event.key === 'Enter') {
            event.preventDefault();
        }
        let params = this.state.formdata.oga_number
        MemberRegistrationsService.getMemberByOGANOOnly(params).then((data) => {
            if (data.length >= 1) {
                getMemberData['members_id'] = data[0].id;

                this.setState({ formdata: getMemberData, ogaOrNonOgaidcheck: true, checkogamember: true })
            } else {

                getMemberData['members_id'] = null;
                this.setState({ formdata: getMemberData, ogaOrNonOgaidcheck: true, checkogamember: false, message: "Can Not find OGA Member", snakebar: true, severity: "error" })
            }

        })


    }


    loadProjects() {
        let para = {}
        ProjectService.getallProjectForPayments(para).then((data) => {
            if (data == 500) {
                this.loadProjects();
            } else {
                var now = moment();
                this.setState({ project: data.filter(projects => projects.status === "Active") })
            }
        })
    }
    loadMasterSettings() {
        let para = this.state.formdata
        MasterSettingsService.getAllMasterSettings(para).then((data) => {
            if (data === 500) {
                this.loadMasterSettings();
            } else {

                this.setState({ membership_fee: data.data.filter(settings => settings.type === "Membership Fee")[0].amount, duplicate_card_fee: data.data.filter(settings => settings.type === "Duplicate Membership Card Fee")[0].amount })
            }
        })
    }

    toggleOGAMember = (event) => {
        var data = this.state.formdata;
        var ogaOrNonOga = true;

        data['oga_number'] = ""
        data['nic_no'] = ""
        data['email'] = ""
        data['contact_no'] = ""
        data['amount'] = ""
        data['name'] = ""
        data['members_id'] = null
        data['relationship_to_school'] = ""
        data['payment_type'] = ""
        data['donated_project'] = ""
        data['remarks'] = null
        data['ogaOrNonOga'] = true;

        if (event.target.value == "oga_member") {
            ogaOrNonOga = false;
            data['ogaOrNonOga'] = false;
        } else {
            data['relationship_to_school'] = 'Well wisher';
        }

        this.setState({
            ogaOrNonOga: ogaOrNonOga,
            formdata: data,
            donationOrsponsership: false,
            project_remark: false,
            payment_remark: false,
        });
    }

    handleContactNoCountryCodeChange(value) {
        var data = this.state.formdata;
        data['contact_no'] = value;
        let moberror = false;
        let moberrorlength2 = false;
        let moberrorlength = false;
        if (data.contact_no.length !== 12 && data.contact_no.startsWith("+94")) {

            moberrorlength = true
            this.setState({ viewMobError: moberror, viewMobErrorLength: moberrorlength, viewMobErrorLength2: moberrorlength2 });
        } else if (data.contact_no.length === 12 && data.contact_no.startsWith("+94")) {
            moberrorlength = false
            this.setState({ viewMobError: moberror, viewMobErrorLength: moberrorlength, viewMobErrorLength2: moberrorlength2 });

        }


        this.setState({
            formdata: data
        });
    }

    handleChange = event => {//For Set edited form data
        event.persist();
        var data = this.state.formdata;
        data[event.target.name] = event.target.value;
        this.setState({
            formdata: data
        });
    };




    handleFormSubmit = () => {
        var data = this.state.formdata;
        var disablebutton = true;
        this.setState({ disablebutton: true, paymentpageopen: true })
        var paymentgateway = appVariables.paymentSession;
        var proceed = false;
        const min = 1;
        const max = 100;
        const rand = Math.floor(1000 + Math.random() * 9000);
        const orderid = moment().unix() + "" + rand;
        const ptype = "external";
        let moberror = false;
        let moberrorlength2 = false;
        let moberrorlength = false;
        if (data.contact_no.length !== 12 && data.contact_no.startsWith("+94")) {

            moberrorlength = true
            this.setState({ viewMobError: moberror, viewMobErrorLength: moberrorlength, viewMobErrorLength2: moberrorlength2 });
        } else if (data.contact_no.length === 12 && data.contact_no.startsWith("+94")) {
            moberrorlength = false
            this.setState({ viewMobError: moberror, viewMobErrorLength: moberrorlength, viewMobErrorLength2: moberrorlength2 });

        }
        if (moberror == false && moberrorlength == true) {
            this.setState({ disablebutton: false, paymentpageopen: false })
            return;
        }


        let orderdetails = "Payment Details :- ";

        if (data.payment_type == "Donation" || data.payment_type == "Sponsorship") {
            orderdetails = orderdetails + data.payment_type + ' for ' + this.state.selected_project.name
            //orderdetails =  orderdetails+'\n'+'Name:- '+data.name+'\n'+'Contact No:- '+data.contact_no
        } else {
            orderdetails = orderdetails + data.payment_type
        }

        if (data.payment_type == "Membership Fee") {
            MemberService.getPendingMemberForPayment(data).then((res) => {
                if (res == 500) {
                    proceed = false;
                    disablebutton = false;
                    this.setState({ paymentpageopen: false, disablebutton: disablebutton, message: "Can Not find a member please retry", snakebar: true, severity: "error" })
                } else {
                    if (res == null) {
                        proceed = false;
                        disablebutton = false;
                        this.setState({ paymentpageopen: false, disablebutton: disablebutton, message: "Can Not find a member please check the details and retry", snakebar: true, severity: "error" })
                    } else {
                        proceed = true;
                        data['members_id'] = res.id;
                        if (proceed) {

                            var paymentgatewaydata = this.state.paymentgatewaydata
                            var params = {
                                "order_id": orderid,
                                "amount": data.amount,
                                'ptype': ptype
                            }
                            let config = {
                                onUploadProgress: (progressEvent) => {
                                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                    this.setState({ paymentloadingprocess: percentCompleted })

                                }
                            }
                            console.log("cjeejs", data.type_of_card)

                            if (data.type_of_card == 'SRL') {
                                PaymentGatewayService.getGateWaySessionID(params, config).then(res => {
                                    if (res.status == 200) {
                                        data['indicator_id'] = res.data.Sample.successIndicator
                                        data['ref_no'] = orderid

                                        disablebutton = true;
                                        paymentgatewaydata['session'] = { id: res.data.Sample.session.id }
                                        const sessonid = res.data.Sample.session.id;
                                        PaymentService.createPayment(data).then(res => {
                                            if (res.status == 200) {

                                                window["callcheckout"](sessonid, orderdetails);
                                                this.setState({ paymentpageopen: true, disablebutton: disablebutton, paymentSessionid: sessonid })
                                            } else {
                                                this.setState({ paymentpageopen: false, disablebutton: false, message: "Can Not Create Payment.Please Re try ", snakebar: true, severity: "error" })
                                            }
                                        })
                                            .catch((error) => {
                                                console.log("error", error);
                                            })

                                    } else {
                                        this.setState({ paymentpageopen: false, disablebutton: false, message: "Can Not Create Payment Session.Please Re try ", snakebar: true, severity: "error" })
                                    }
                                })
                            } else {
                                PaymentGatewayService.getGateWaySessionIDforiegn(params, config).then(res => {
                                    if (res.status == 200) {
                                        console.log("cjeejs", res.data.Sample)

                                        data['indicator_id'] = res.data.Sample.successIndicator
                                        data['ref_no'] = orderid

                                        disablebutton = true;
                                        paymentgatewaydata['session'] = { id: res.data.Sample.session.id }
                                        const sessonid = res.data.Sample.session.id;
                                        PaymentService.createPayment(data).then(res => {
                                            if (res.status == 200) {

                                                window["callcheckout"](sessonid, orderdetails);
                                                this.setState({ paymentpageopen: true, disablebutton: disablebutton, paymentSessionid: sessonid })
                                            } else {
                                                this.setState({ paymentpageopen: false, disablebutton: false, message: "Can Not Create Payment.Please Re try ", snakebar: true, severity: "error" })
                                            }
                                        })
                                            .catch((error) => {
                                                console.log("error", error);
                                            })

                                    } else {
                                        this.setState({ paymentpageopen: false, disablebutton: false, message: "Can Not Create Payment Session.Please Re try ", snakebar: true, severity: "error" })
                                    }
                                })
                            }
                        } else {
                            this.setState({ paymentpageopen: false, disablebutton: false, message: "Can Not proceed please retry", snakebar: true, severity: "error" })
                        }

                    }

                }
            })

        } else {

            var paymentgatewaydata = this.state.paymentgatewaydata
            var params = {
                "order_id": orderid,
                "amount": data.amount,
                'ptype': ptype
            }
            let config = {
                onUploadProgress: (progressEvent) => {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    this.setState({ paymentloadingprocess: percentCompleted })

                }
            }
            if (data.type_of_card == 'SRL') {
                PaymentGatewayService.getGateWaySessionID(params, config).then(res => {
                    if (res.status == 200) {
                        data['indicator_id'] = res.data.Sample.successIndicator
                        data['ref_no'] = orderid

                        paymentgatewaydata['session'] = { id: res.data.Sample.session.id }
                        const sessonid = res.data.Sample.session.id;
                        PaymentService.createPayment(data).then(res => {
                            if (res.status == 200) {
                                console.log('pp', res.data)
                                window["callcheckout"](sessonid, orderdetails);
                                this.setState({ paymentpageopen: true, disablebutton: true, paymentSessionid: sessonid })
                            } else if (res.response.status === 500) {
                                this.setState({ paymentpageopen: false, disablebutton: false, message: res.response.data.message, snakebar: true, severity: "error" })
                            } else {
                                this.setState({ paymentpageopen: false, disablebutton: false, message: "Can Not Create Payment.Please Re try ", snakebar: true, severity: "error" })
                            }
                        })
                            .catch((error) => {
                                console.log("error", error);
                            })

                    } else {
                        this.setState({ paymentpageopen: false, disablebutton: false, message: "Can Not Create Payment Session.Please Re try ", snakebar: true, severity: "error" })
                    }
                })

            } else {
                PaymentGatewayService.getGateWaySessionIDforiegn(params, config).then(res => {
                    if (res.status == 200) {
                        data['indicator_id'] = res.data.Sample.successIndicator
                        data['ref_no'] = orderid

                        paymentgatewaydata['session'] = { id: res.data.Sample.session.id }
                        const sessonid = res.data.Sample.session.id;
                        PaymentService.createPayment(data).then(res => {
                            if (res.status == 200) {
                                console.log('pp', res.data)
                                window["callcheckout"](sessonid, orderdetails);
                                this.setState({ paymentpageopen: true, disablebutton: true, paymentSessionid: sessonid })
                            } else if (res.response.status === 500) {
                                this.setState({ paymentpageopen: false, disablebutton: false, message: res.response.data.message, snakebar: true, severity: "error" })
                            } else {
                                this.setState({ paymentpageopen: false, disablebutton: false, message: "Can Not Create Payment.Please Re try ", snakebar: true, severity: "error" })
                            }
                        })
                            .catch((error) => {
                                console.log("error", error);
                            })

                    } else {
                        this.setState({ paymentpageopen: false, disablebutton: false, message: "Can Not Create Payment Session.Please Re try ", snakebar: true, severity: "error" })
                    }
                })
            }
        }




        // PaymentGatewayService.createPayment(params).then(res => {
        //     if (res.status == 200) {
        //         this.setState({ message: res.data.message, snakebar: true, severity: "success" })
        //         setTimeout(() => {
        //             this.componentDidMount();
        //             //window.location.reload(false);
        //         }, 3000);
        //     } else {
        //         this.setState({ message: "Can Not Create Payment.Please Re try ", snakebar: true, severity: "error" })
        //     }
        // })

        //data['ref_no']=orderid;
        // PaymentService.createPayment(data).then(res => {
        //     if (res.status == 200) {
        //         this.setState({ message: res.data.message, snakebar: true, severity: "success" })
        //         setTimeout(() => {
        //             this.componentDidMount();
        //             //window.location.reload(false);
        //         }, 3000);
        //     } else {
        //         this.setState({ message: "Can Not Create Payment.Please Re try ", snakebar: true, severity: "error" })
        //     }
        // })
        //     .catch((error) => {
        //         console.log("error", error);
        //     })

    }



    handleDialogClose() {//For handle edit dialog
        this.setState({ toConfirm: false })
    }

    handleChangeRadio = event => {

        event.persist();
        var data = this.state.formdata;

        data[event.target.name] = event.target.value;

        this.setState({
            formdata: data,
        });



    }


    render() {
        const { classes } = this.props;
        return (
            <div style={{ marginBottom: 50 }}>
                <div className="mb-20">
                    <img
                        className="circle-44 mr-10"
                        src="/assets/images/logonew.png"
                        alt="user"
                        style={{ borderRadius: "50%", float: "left" }}
                    />
                    <div style={{ color: "white" }}>
                        <div>
                            Visakha Vidyalaya Old Girls' Association
                        </div>
                        <div>
                            Membership Portal
                        </div>
                    </div>
                    <Typography variant="h5" component="h5" gutterBottom>
                        Payments
                    </Typography>
                </div>
                <div >
                    <SimpleCard>
                        <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>

                            <Grid container spacing={5}
                                direction="row"
                                justify="center"
                                alignItems="center"
                                style={{ marginBottom: 20 }}
                            >
                                <FormLabel component="legend" style={{ marginRight: 10 }}>I am : </FormLabel>
                                <RadioGroup row aria-label="member_type" name="member_type" defaultValue="oga_member" onChange={this.toggleOGAMember} >
                                    <FormControlLabel value="oga_member" control={<Radio color="primary" />} label="an OGA member" />
                                    <FormControlLabel value="non_oga_member" control={<Radio color="primary" />} label="a well wisher" />
                                </RadioGroup>
                            </Grid>

                            <Grid container spacing={3} >
                                <Grid item xs={12} sm={6} md={6}>
                                    <Grid container spacing={3} >
                                        {!this.state.ogaOrNonOga ?
                                            <Grid item xs={12} sm={12} md={12}>
                                                <TextValidator
                                                    className="w-100"
                                                    variant="outlined"
                                                    label="OGA Number *"
                                                    onChange={this.handleChange}
                                                    // onKeyPress={this.getMember}
                                                    // onKeyDown={this.getMember}
                                                    // onBlur={this.getMemberMobile}
                                                    onKeyUp={this.getMember}
                                                    size='small'
                                                    name="oga_number"
                                                    value={this.state.formdata.oga_number}
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        "This field is required"
                                                    ]}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.cssLabel,
                                                            focused: classes.cssFocused,
                                                        },
                                                    }}
                                                    inputProps={{
                                                        maxlength: 50
                                                    }}
                                                    color="inherit"
                                                />
                                            </Grid>
                                            : ""}

                                        {this.state.ogaOrNonOga ?
                                            <Grid item xs={12} sm={12} md={12}>
                                                <TextValidator
                                                    className="w-100"
                                                    variant="outlined"
                                                    label="Name *"
                                                    onChange={this.handleChange}
                                                    size='small'
                                                    name="name"
                                                    value={this.state.formdata.name}
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        "This field is required"
                                                    ]}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.cssLabel,
                                                            focused: classes.cssFocused,
                                                        },
                                                    }}
                                                    color="inherit"
                                                />
                                                <p style={{paddingBottom:0, marginBottom: 0, paddingLeft:3}}>Entering the NIC number or the passport number is mandatory</p>
                                            </Grid>
                                            
                                            : ""}
                                             
                                        {this.state.ogaOrNonOga ?
                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextValidator
                                                    className="w-100"
                                                    variant="outlined"
                                                    label="NIC Number"
                                                    onChange={this.handleChange}
                                                    size='small'
                                                    name="nic_no"
                                                    validators={this.state.formdata.nic_no !== undefined && this.state.formdata.nic_no !== "" && this.state.formdata.nic_no !== null ? ['maxStringLength:12', 'matchRegexp:^([0-9]{9}[x|X|v|V]|[0-9]{12})$'] : this.state.formdata.passport_no !== undefined && this.state.formdata.passport_no !== "" && this.state.formdata.passport_no !== null ? "" : ['required']}
                                                    errorMessages={[
                                                        "Enter a valid NIC",
                                                        "Enter a valid NIC"
                                                    ]}
                                                    value={this.state.formdata.nic_no}
                                                />
                                            </Grid>
                                            : ""}
                                        {this.state.ogaOrNonOga ?
                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextValidator
                                                    className="w-100"
                                                    variant="outlined"
                                                    label="Passport Number"
                                                    onChange={this.handleChange}
                                                    size='small'
                                                    name="passport_no"
                                                    value={this.state.formdata.passport_no}
                                                    validators={this.state.formdata.passport_no ? ['matchRegexp:(?!^[0-9]*$)(?!^[a-zA-Z]*$)^([a-zA-Z0-9]{6,15})$'] : []}
                                                    errorMessages={[
                                                        "Enter a valid passport number"
                                                    ]}
                                                    color="inherit"
                                                />
                                            </Grid>
                                            : ""}

                                        {!this.state.ogaOrNonOga && this.state.ogaOrNonOgaidcheck ?
                                            <Grid item xs={12} sm={12} md={12}>
                                                {this.state.formdata.members_id !== null ?
                                                    <Typography gutterBottom style={{ color: "green" }} >
                                                        OGA member is valid for payment
                                                    </Typography>
                                                    : ""}
                                                {this.state.formdata.members_id == null && this.state.formdata.oga_number ?
                                                    <Typography gutterBottom style={{ color: "red" }}>
                                                        OGA member is not found
                                                    </Typography>
                                                    : ""}
                                            </Grid>
                                            : ""}

                                        <Grid item xs={12} sm={6} md={6}>
                                            <MuiPhoneNumber
                                                defaultCountry='lk'
                                                variant="outlined"
                                                size='small'
                                                name="contact_no"
                                                label="Contact Number *"
                                                className="w-100"
                                                disableAreaCodes
                                                onChange={(value) => this.handleContactNoCountryCodeChange(value)}
                                                value={this.state.formdata.contact_no}
                                                validators={["required"]}
                                                errorMessages={[
                                                    "This field is required"
                                                ]}
                                            />
                                            {this.state.viewMobError ?
                                                <p style={{ color: "red" }} class="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense">This field is required</p>
                                                : ""
                                            }
                                            {this.state.viewMobErrorLength ?
                                                <p style={{ color: "red" }} class="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense">Enter valid mobile no</p>
                                                : ""
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <TextValidator
                                                className="w-100"
                                                variant="outlined"
                                                label="Email *"
                                                onChange={this.handleChange}
                                                size='small'
                                                name="email"
                                                type="email"
                                                value={this.state.formdata.email}
                                                validators={["required", "isEmail"]}
                                                errorMessages={[
                                                    "This field is required", "Enter a valid email address "
                                                ]}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Autocomplete
                                                ListboxProps={{ style: { border: "1px solid black" } }}
                                                className="w-100"
                                                options={this.state.ogaOrNonOga ? this.state.non_oga_payment_type.filter(item => item.title !== 'Membership Fee') : this.state.payment_type}
                                                getOptionLabel={(option) => option.title}
                                                size='small'
                                                defaultValue={
                                                    this.state.ogaOrNonOga ? this.state.non_oga_payment_type.find((item) =>
                                                        item.title == this.state.formdata.payment_type ? item.title
                                                            : null
                                                    ) : this.state.payment_type.find((item) =>
                                                        item.title == this.state.formdata.payment_type ? item.title
                                                            : null
                                                    )
                                                }
                                                name="payment_type"
                                                onChange={(event, value) => this.setdropdowndata("payment_type", value)}

                                                renderInput={(params) => <TextValidator {...params}
                                                    value={this.state.formdata.payment_type == null ? "" : this.state.formdata.payment_type}
                                                    label="Payment Type *"
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        "This field is required"
                                                    ]}
                                                    variant="outlined" />}
                                            />
                                        </Grid>
                                    </Grid>


                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Grid container spacing={3} >

                                        {this.state.donationOrsponsership ?
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Autocomplete
                                                    ListboxProps={{ style: { border: "1px solid black" } }}
                                                    className="w-100"
                                                    options={this.state.project}
                                                    getOptionLabel={(option) => option.name}
                                                    size='small'
                                                    name="donated_project"
                                                    onChange={(event, value) => this.setdropdowndata("donated_project", value)}
                                                    renderInput={(params) => <TextValidator {...params}
                                                        value={this.state.formdata.donated_project == null ? "" : this.state.formdata.donated_project}
                                                        label={this.state.formdata.payment_type == "Donation" ? "Project Donated for *" : "Project Sponsored for *"}
                                                        validators={["required"]}
                                                        errorMessages={[
                                                            "This field is required"
                                                        ]}
                                                        variant="outlined" />}
                                                />
                                            </Grid>
                                            : ""}
                                        {!this.state.ogaOrNonOga && this.state.duplicateCardFee ?

                                            <Grid item xs={12} sm={12} md={12}>
                                                <Typography gutterBottom >
                                                    * Duplicate Card Fee Rs. {this.state.duplicate_card_fee}
                                                </Typography>
                                            </Grid>

                                            : ""}
                                        {this.state.ogaOrNonOga && this.state.duplicateCardFee ?
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Typography gutterBottom>
                                                    * The Membership Fee Rs. {this.state.membership_fee}
                                                </Typography>
                                            </Grid>

                                            : ""}
                                        <Grid item xs={12} sm={12} md={12}>
                                            <TextValidator
                                                className="w-100"
                                                variant="outlined"
                                                label="Amount (LKR) *"
                                                onChange={this.handleChange}
                                                size='small'
                                                name="amount"
                                                type='number'
                                                pattern="^-?\\d*(\\.\\d+)?$"
                                                inputProps={{ pattern: "^-?\\d*(\\.\\d+)?$" }}
                                                validators={["required", 'minNumber:' + this.state.min_amount, 'maxNumber:' + this.state.max_amount, 'matchRegexp:^\\$?(([1-9](\\d*|\\d{0,2}(,\\d{3})*))|0)(\\.\\d{1,2})?$']}
                                                errorMessages={[
                                                    "This field is required",
                                                    this.state.min_message,
                                                    "Maximum available payment is Rs. " + this.state.max_amount,
                                                    "Please enter a valid value",
                                                ]}
                                                value={this.state.formdata.amount}
                                                disabled={this.state.duplicateCardFee}
                                            />
                                        </Grid>

                                       {/*  <Grid item xs={12} sm={12} md={12}>
                                            <Typography gutterBottom>
                                                Please note that a convenience fee of 3.5% of the transaction amount will be charged for payments between USD 0-299 in respect of foreign transactions.
                                            </Typography>
                                        </Grid> */}

                                        {!this.state.membershipFee && this.state.project_remark ?
                                            <Grid item xs={12} sm={12} md={12}>
                                                <TextValidator
                                                    className="w-100"
                                                    variant="outlined"
                                                    label="Project Remarks"
                                                    onChange={this.handleChange}
                                                    size='small'
                                                    name="remarks"

                                                    value={this.state.formdata.remarks}
                                                />
                                            </Grid>
                                            : ""}


                                        {this.state.payment_remark ?
                                            <Grid item xs={12} sm={12} md={12}>
                                                <TextValidator
                                                    className="w-100"
                                                    variant="outlined"
                                                    label="Payment Remarks"
                                                    onChange={this.handleChange}
                                                    size='small'
                                                    name="remarks"
                                                    value={this.state.formdata.remarks}
                                                />
                                            </Grid>
                                            : ""}
                                        <Grid item xs={12} sm={12}  >

                                        <label>The card you are using for the payment</label>
                                            <RadioGroup name="type_of_card" value={this.state.formdata.type_of_card} onChange={this.handleChangeRadio}>
                                                <FormControlLabel value="SRL" control={<Radio color="primary" />} label="Is issued in Sri Lanka" />
                                                <FormControlLabel value="other" control={<Radio color="primary" />} label="Is issued outside of Sri Lanka" />
                                            </RadioGroup >

                                        </Grid>
                                    </Grid>

                                </Grid>

                                <Grid item xs={12} sm={12} md={12} style={{ textAlign: "right" }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        endIcon={<ArrowRightIcon />}
                                        type="submit"
                                        disabled={!this.state.disablebutton ? this.state.ogaOrNonOga ? false : this.state.checkogamember ? false : true : true}
                                    >
                                        Next
                                    </Button>
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                    </SimpleCard>
                </div>
                <Dialog fullWidth={true} aria-labelledby="Please Wait" open={this.state.paymentloadingprocess === 0 ? false : this.state.paymentloadingprocess === Infinity ? false : this.state.paymentloadingprocess === 100 ? false : true}>
                    <div className="pb-10 pt-10 px-30 w-90">
                        Loading Payment Page......
                        <LinearProgress style={{ borderRadius: 5, height: 15 }} variant="determinate" value={this.state.paymentloadingprocess} />
                    </div>
                </Dialog>

                <Dialog fullWidth={true} aria-labelledby="Please Wait" open={this.state.paymentpageopen}>
                    <div className="pb-30 pt-10 px-10" align="center">
                        <div class="loading" >
                            <p>Loading payment page. Please Wait ...</p>
                            <span><i></i><i></i></span>
                        </div>
                    </div>
                </Dialog>


                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.snakebar} autoHideDuration={2500} onClose={this.handleClose}>
                    <Alert onClose={this.handleClose} severity={this.state.severity} variant="filled">
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </div >
        );
    };
}
AllPayment.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(AllPayment);