import axios from "axios";
import localStorageService from "./localStorageService";
import navigations from "./../navigations";
import * as appconst from './../appconst';
import moment from "moment";



export const LOGIN_ERROR = "LOGIN_ERROR";


class JwtAuthService {

    // Dummy user object just for the demo
    /* user = {
       userId: "1",
       role: 'ADMIN',
       displayName: "Ishara Roshan",
       email: "roshan@loonslab.com",
       photoURL: "/assets/images/face-6.png",
       age: 25,
       token: "faslkhfh423oiu4h4kj432rkj23h432u49ufjaklj423h4jkhkjh"
     };*/
    user = {};
    token = "";


    // You need to send http request with email and passsword to your server in this method
    // Your server will return user object & a Token
    // User should have role property
    // You can define roles in app/auth/authRoles.js


    loginWithEmailAndPassword = async(email, password) => {

        // axios.defaults.port = 8080;


        /*
         fetch("http://localhost:8080/auth/realms/HRCRealm/protocol/openid-connect/token", {
           method: 'POST',
           //mode: 'no-cors',
           headers: {
               'Accept': 'application/json',
               'Content-Type': 'application/x-www-form-urlencoded',
           },
           body: 'grant_type=password&client_id=client2&username=admin&password=admin'
         }) .then(response => console.log(response))
         .catch(error => console.log(error))
         */

        return new Promise((resolve, reject) => {


            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            }

            // var user = {
            //   userId: "admin",
            //   role: "Developer",
            //   roles: ["Developer"],
            //   //role:"Super Admin",
            //   displayName: "admin",
            //   email: "admin@admin.com",
            //   photoURL: "/assets/images/logonew.png",
            //   age: 25,
            //   token: "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ4TFpzNzRZOGdXanRSMnhtYTQ4MDNJc3JRdkxpVDB5TDFHRWFyUGhNVHpJIn0.eyJleHAiOjE2MTQ4MzI4OTEsImlhdCI6MTYxNDc0NjQ5MSwianRpIjoiMmViMmJiMzktNTg2Yy00YTFkLTk0YjUtYzZkYjU2NThkOTU3IiwiaXNzIjoiaHR0cHM6Ly9odXJpbi5ocmNzbC5say9hdXRoL3JlYWxtcy9IUkNSZWFsbSIsImF1ZCI6WyJyZWFsbS1tYW5hZ2VtZW50IiwiYWNjb3VudCJdLCJzdWIiOiJkNTU2MDBiMy0wNDJmLTRmOTYtOTU4OC0xZWE3YjYxYjc0OGYiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJjbGllbnQyIiwic2Vzc2lvbl9zdGF0ZSI6ImM3ODcwYjc1LTRlMzUtNDAwZi1hZGQ3LTUwMWQ1OWEzOTkxOCIsImFjciI6IjEiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsiRGV2ZWxvcGVyIl19LCJyZXNvdXJjZV9hY2Nlc3MiOnsicmVhbG0tbWFuYWdlbWVudCI6eyJyb2xlcyI6WyJ2aWV3LXJlYWxtIiwidmlldy1pZGVudGl0eS1wcm92aWRlcnMiLCJtYW5hZ2UtaWRlbnRpdHktcHJvdmlkZXJzIiwiaW1wZXJzb25hdGlvbiIsInJlYWxtLWFkbWluIiwiY3JlYXRlLWNsaWVudCIsIm1hbmFnZS11c2VycyIsInF1ZXJ5LXJlYWxtcyIsInZpZXctYXV0aG9yaXphdGlvbiIsInF1ZXJ5LWNsaWVudHMiLCJxdWVyeS11c2VycyIsIm1hbmFnZS1ldmVudHMiLCJtYW5hZ2UtcmVhbG0iLCJ2aWV3LWV2ZW50cyIsInZpZXctdXNlcnMiLCJ2aWV3LWNsaWVudHMiLCJtYW5hZ2UtYXV0aG9yaXphdGlvbiIsIm1hbmFnZS1jbGllbnRzIiwicXVlcnktZ3JvdXBzIl19LCJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50Iiwidmlldy1hcHBsaWNhdGlvbnMiLCJ2aWV3LWNvbnNlbnQiLCJtYW5hZ2UtYWNjb3VudC1saW5rcyIsIm1hbmFnZS1jb25zZW50Iiwidmlldy1wcm9maWxlIl19fSwic2NvcGUiOiJwcm9maWxlIGVtYWlsIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiRGV2ZWxvcGVyIExvb25zIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiZGV2ZWxvcGVyQGRldi5jb20iLCJnaXZlbl9uYW1lIjoiRGV2ZWxvcGVyIiwiZmFtaWx5X25hbWUiOiJMb29ucyIsImVtYWlsIjoiZGV2ZWxvcGVyQGRldi5jb20ifQ.iOQKTAE5Xsu6_USrfe6T8xo1k93uE7ScZJc6oHI2ykBJa42gcr1YFL5YJvzHoZbTOED6dib8z7mDuzueBE_AkBpXM5XReY5W4yNy0DHxbjBKrteoNJsPJLQQacQ2VRWlQqXPoxTHo2qxTTf1PQhRavdccphbfYEnekalrftoEkKii1mQJwl_g3jYr4iH7xOyJ269U-kMzyK64hUJI5NyiNjIdD-Py78Y-yBxlPK9QTI30jbW8LqveM0Il6Bdz5eaZ09psU1YsJEfvpvGWHYRksJyma8sYYHGSLKixrnwmgxHeX1YcCbjEKJ1S-FIwrUo_mZ4PboFkbPue6RrZ-pGwg"
            // };

            axios.post(appconst.LOGIN_URL, `name=${email}&password=${password}`, {
                    headers: headers
                })
                .then((response) => {
                    console.log("RESPOSNE", response);
                    var roles = [];
                    roles.push(response.data.user.roles);
                    var jwtDecode = require('jwt-decode');
                    var userdata = jwtDecode(response.data.access_token);
                    var user = {
                        userId: response.data.user.id,
                        memberId: response.data.user.members_id,
                        role: response.data.user.roles,
                        roles: roles,
                        //role:"Super Admin",
                        displayName: response.data.user.name,
                        email: response.data.user.email,
                        photoURL: "/assets/images/logonew.png",
                        age: 25,
                        token: response.data.access_token,
                        permisions: response.data.permisions
                    };
                    resolve(user);

                })
                .catch((error) => {
                    // console.log("error", error)
                    var data = {
                        Error: "Invalid user credentials",
                    }
                    resolve(data);
                })

            // resolve(user);

        }).then(data => {
            // Login successful
            // Save token
            this.setSession(data.token);
            // Set user
            this.setUser(data);
            this.setPermisions(data.permisions);

            return data;
        });
    };

    // You need to send http requst with existing token to your server to check token is valid
    // This method is being used when user logged in & app is reloaded
    loginWithToken = () => {

        return new Promise((resolve, reject) => {
            this.user = localStorageService.getItem("auth_user");
            this.token = localStorageService.getItem("token");

            const headers = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.user.token}`
                },
            };
            fetch(appconst.REFRESH, headers)
                .then(response => response.json())
                .then(data => {

                    if (data.hasOwnProperty('error')) {
                        console.log("invalid")
                        resolve(data);
                    } else {
                        console.log('Refrech token user', data)
                        resolve(data);
                    }
                }).catch(function() {
                    var err = { error: "server error" }
                    resolve(err);
                });;


        }).then(data => {
            // Token is valid

            this.setSession(data.access_token);
            this.user['token'] = data.access_token;
            localStorageService.setItem("auth_user", this.user);

            //this.setUser(data);
            return data;
        });
    };

    logout = () => {
        this.setSession(null);
        this.removeUser();
    }

    // Set token to all http request header, so you don't need to attach everytime
    setSession = token => {
        if (token) {

            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            localStorage.setItem("jwt_token", token);

        } else {
            localStorage.removeItem("jwt_token");
            delete axios.defaults.headers.common["Authorization"];
        }
    };

    // Save user to localstorage
    setUser = (user) => {
        localStorageService.setItem("auth_user", user);
    }
    setPermisions = (data) => {
            localStorageService.setItem("auth_user_permisions", data);
        }
        // Remove user from localstorage
    removeUser = () => {
        localStorage.removeItem("auth_user");
        localStorage.removeItem("auth_user_permisions");
    }

    checkTokenValidation = async() => {

        const token = await localStorageService.getItem("auth_user")

        let jwtDecode = require('jwt-decode');

        console.log(token)

        if (token != null && token.token) {
            let tokendata = jwtDecode(token.token);

            if (tokendata != null) {

                let formatdate = new Date(tokendata.exp * 1000)

                var expire = moment(formatdate);
                var current = moment(new Date());

                let diffrent = expire.diff(current, 'minutes')

                console.log("Diffrent", diffrent)

                if (diffrent <= 10) {
                    this.loginWithToken()
                }
            }
        }
    }
}

export default new JwtAuthService();