import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const Chat = MatxLoadable({
    loader: () =>
        import ("./New")
})
const ChatAdmin = MatxLoadable({
    loader: () =>
        import ("./Admin/Create")
})

const ChatAdminView = MatxLoadable({
    loader: () =>
        import ("./Admin/View")
})

const ChatAdminReply = MatxLoadable({
    loader: () =>
        import ("./Admin/New")
})

const Routes = [{
        path: "/Chat",
        component: Chat,
        auth: authRoles.all
    },
    {
        path: "/admin_chat_new",
        component: ChatAdmin,
        auth: authRoles.adminchat
    },
    {
        path: "/admin_chat_view",
        component: ChatAdminView,
        auth: authRoles.adminchat
    },
    {
        path: "/admin_chat_reply/:id",
        component: ChatAdminReply,
        auth: authRoles.adminchat
    }
];

export default Routes;