import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const EmailNew = MatxLoadable({
    loader: () =>
        import ("./Create")
})

const EmailView = MatxLoadable({
    loader: () =>
        import ("./View")
})


const EmailUpdate = MatxLoadable({
    loader: () =>
        import ("./Update")
})

const Routes = [{
        path: "/new_sms",
        component: EmailNew,
        auth: authRoles.Memebership_Communication
    },
    {
        path: "/view_sms",
        component: EmailView,
        auth: authRoles.Memebership_Communication
    },
    {
        path: "/update_sms/:id",
        component: EmailUpdate,
        auth: authRoles.Memebership_Communication
    }
];

export default Routes;